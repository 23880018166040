import React from 'react';
import { Icon } from 'antd';
import { EllipsedText } from '../../Utils/TextUtils';
import styles from './NotesTab.module.scss';

const NotesTabHeader = props => {
  const { candidate, toggleMinimize, toggleMaximize, handleClose, smallBox } = props;
  return (
    <div className={styles.header}>
      <div className={styles.noteTitle}>
        <EllipsedText
          text={`Notes for ${candidate.Name ? candidate.Name : ''} ${candidate.Title ? `, ${candidate.Title}` : ''}  ${
            candidate.Company ? `- ${candidate.Company}` : ''
          }`}
          maxTextLength={smallBox ? 40 : 68}
        />
      </div>
      <div className={styles.actionIcons}>
        <Icon type="line" onClick={toggleMinimize} />
        <Icon type="arrows-alt" onClick={toggleMaximize} />
        <Icon type="close" onClick={handleClose} />
      </div>
    </div>
  );
};

export default NotesTabHeader;

import React from 'react';
import { Icon } from 'antd';

function ExclamationSVG(props) {
  const { color } = props;
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 1.2C3.3534 1.2 1.2 3.3534 1.2 6C1.2 8.6466 3.3534 10.8 6 10.8C8.6466 10.8 10.8 8.6466 10.8 6C10.8 3.3534 8.6466 1.2 6 1.2ZM6 12C2.6916 12 0 9.309 0 6C0 2.6916 2.6916 0 6 0C9.3084 0 12 2.6916 12 6C12 9.309 9.3084 12 6 12ZM5.4 6.6V2.4H6.6V6.6H5.4ZM6 9.15C6.414 9.15 6.75 8.814 6.75 8.4C6.75 7.986 6.414 7.65 6 7.65C5.586 7.65 5.25 7.986 5.25 8.4C5.25 8.814 5.586 9.15 6 9.15Z"
        fill={color}
      />
      <mask
        id="mask0_49_49403"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="12"
        height="12"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 1.2C3.3534 1.2 1.2 3.3534 1.2 6C1.2 8.6466 3.3534 10.8 6 10.8C8.6466 10.8 10.8 8.6466 10.8 6C10.8 3.3534 8.6466 1.2 6 1.2ZM6 12C2.6916 12 0 9.309 0 6C0 2.6916 2.6916 0 6 0C9.3084 0 12 2.6916 12 6C12 9.309 9.3084 12 6 12ZM5.4 6.6V2.4H6.6V6.6H5.4ZM6 9.15C6.414 9.15 6.75 8.814 6.75 8.4C6.75 7.986 6.414 7.65 6 7.65C5.586 7.65 5.25 7.986 5.25 8.4C5.25 8.814 5.586 9.15 6 9.15Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_49_49403)">
        <circle cx="5.40002" cy="6" r="36" fill={color} />
      </g>
    </svg>
  );
}

const ExclamationIcon = props => <Icon component={ExclamationSVG} {...props} />;

export default ExclamationIcon;

import React from 'react';
import { connect } from 'react-redux';
import { Button, Icon, Tooltip, Progress } from 'antd';
import { FormattedMessage } from 'react-intl';
import { JobUsageBudgetDataByJobId } from '../../Reducers/JobUsageBudgetReducer';
import { getApiStatus } from '../../Reducers/ApiStatusReducer';
import { getUsersById } from '../../Reducers/UserReducer';
import messages from '../ManualSearchV2/ManualSearchMessages';
import * as JobLevelLimitActions from '../../Actions/JobUsageBudgetActions';
import styles from './BudgetUsage.module.scss';

const mapStateToProps = (state, props) => ({
  jobLevelCreditsData: JobUsageBudgetDataByJobId(state, props.jobId),
  postCreditsRequestApiStatus: getApiStatus(state, 'jobLevelPostCreditsRequestApiStatus'),
  getUsersById: getUsersById(state),
});

const mapDispatchToProps = {
  postJobUsageBudgetRequest: JobLevelLimitActions.postJobUsageBudgetRequest,
};

function BudgetUsage(props) {
  const { jobLevelCreditsData, postCreditsRequestApiStatus, postJobUsageBudgetRequest, jobId, getUsersById } = props;

  const [jobCreditRequestStatus, setJobCreditRequestStatus] = React.useState(jobLevelCreditsData.RequestStatus);

  const {
    AvailableBudget: availableBudget,
    TotalBudget: totalBudget,
    RequestedByUserId: requestedByUserId,
  } = jobLevelCreditsData;

  React.useEffect(() => {
    setJobCreditRequestStatus(jobLevelCreditsData.RequestStatus);
  }, [jobLevelCreditsData.RequestStatus]);

  if (totalBudget === null) return null;

  const budgetUsage = totalBudget - availableBudget;
  const percentageBudgetUsage = (budgetUsage / totalBudget) * 100;
  const budgetUsageIcon = () => {
    let progressBarStrokeColor;
    if (percentageBudgetUsage < 50) {
      progressBarStrokeColor = '#13C26B';
    } else if (percentageBudgetUsage < 75) {
      progressBarStrokeColor = '#FFAB1F';
    } else {
      progressBarStrokeColor = '#F75656';
    }
    return (
      <Progress
        percent={(availableBudget * 100) / totalBudget}
        strokeColor={progressBarStrokeColor}
        showInfo={false}
        size="small"
      />
    );
  };
  const handleRequestCredits = () => {
    postJobUsageBudgetRequest(jobId, totalBudget);
  };
  const { FullName: fullName, Email: email } = getUsersById[requestedByUserId] || {};
  const creditRequestMessage =
    jobLevelCreditsData.RequestStatus === 'Pending' ? (
      <FormattedMessage {...messages.requestedLabel} />
    ) : (
      <FormattedMessage {...messages.requestCreditsLabel} />
    );

  return (
    <div className={styles.creditsUsedWrapper}>
      <div className={styles.creditsUsedContainer}>
        <div className={styles.creditsUsed}>
          <div className={styles.creditsUsedText}>
            <FormattedMessage {...messages.creditsUsedLabel} />
          </div>
          <div className={styles.creditsUsedCount}>
            {budgetUsage}/{totalBudget}
          </div>
        </div>
        {budgetUsageIcon()}
      </div>
      {percentageBudgetUsage >= 75 ? (
        <div className={styles.creditsRequest}>
          {postCreditsRequestApiStatus === 'INPROGRESS' ? (
            <div key="loader">
              <Icon type="loading" spin />
            </div>
          ) : (
            <Tooltip
              title={
                jobLevelCreditsData.RequestStatus === 'Pending' && email ? (
                  <FormattedMessage {...messages.budgetForThisJobLabel} values={{ fullName, email }} />
                ) : (
                  ''
                )
              }
            >
              <Button
                type="link"
                style={{ textDecoration: 'underline', padding: '0px' }}
                onClick={() => handleRequestCredits()}
                disabled={jobCreditRequestStatus === 'Pending'}
              >
                <p className={styles.creditRequestText}>{creditRequestMessage}</p>
              </Button>
            </Tooltip>
          )}
        </div>
      ) : null}
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(BudgetUsage);
export { BudgetUsage as BudgetUsageWithoutStore };

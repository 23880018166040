/* eslint-disable react/no-did-update-set-state */
/* eslint-disable class-methods-use-this */
import React from 'react';
import _ from 'lodash';
import { FormattedMessage, injectIntl } from 'react-intl';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { Tabs, Radio, Button, Input, Select, Switch, Form, Skeleton, Modal, Alert } from 'antd';
import RichTextEditor from '../../Editor/RichTextEditor';
import ScreeningQuestionItem from '../ScreeningQuestionItem/ScreeningQuestionItem';
import InfoGatheringQuestionItem from '../InfoGatheringQuestionItem/InfoGatheringQuestionItem';
import './BotConfig.scss';
import eventTypes from '../../../Analytics/EventTypes';
import LinkButton from '../../Common/LinkButton/LinkButton';
import message from './ChatbotMessages';
import placeholder from '../../Placeholders/PlaceholdersMessages';

const { TabPane } = Tabs;
const { Button: RadioButton, Group: RadioGroup } = Radio;
const { Option } = Select;
const { TextArea } = Input;

class BotConfig extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRadioButton: 'screening',
      showSelectTemplate: false,
      selectedTemplateId: undefined,
      infoGatheringQuestions: [],
      screeningQuestions: [],
      propQuestions: [],
      botResponses: {},
      isGenerateQuestionsClicked: false,
      showModal: false,
    };

    this.screeningLastQuestionRef = React.createRef();
    this.infoGatheringLastQuestionRef = React.createRef();
    this.onRadioButtonChange = this.onRadioButtonChange.bind(this);
    this.getBotResponseItem = this.getBotResponseItem.bind(this);
    this.getButtonGroup = this.getButtonGroup.bind(this);
    this.getBotQuestionsTab = this.getBotQuestionsTab.bind(this);
    this.getScreeningQuestions = this.getScreeningQuestions.bind(this);
    this.getInfoGatheringQuestions = this.getInfoGatheringQuestions.bind(this);
    this.addQuestion = this.addQuestion.bind(this);
    this.removeQuestion = this.removeQuestion.bind(this);
    this.removeInfoGatheringQuestion = this.removeInfoGatheringQuestion.bind(this);
    this.changeQuestion = this.changeQuestion.bind(this);
    this.changeExpectedAnswer = this.changeExpectedAnswer.bind(this);
    this.onResponseChange = this.onResponseChange.bind(this);
    this.prepareQuestions = this.prepareQuestions.bind(this);
    this.onTemplateSelect = this.onTemplateSelect.bind(this);
    this.onCategoryChange = this.onCategoryChange.bind(this);
    this.onInfoQuestionChange = this.onInfoQuestionChange.bind(this);
    this.onCategoryBlur = this.onCategoryBlur.bind(this);
    this.onSaveForm = this.onSaveForm.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.onChangeBotQuestion = this.onChangeBotQuestion.bind(this);
    this.onUpdateJobDescription = this.onUpdateJobDescription.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    const newState = _.clone(state);

    if (!_.isEqual(_.get(props, ['questions'], false), state.propQuestions)) {
      _.set(
        newState,
        ['screeningQuestions'],
        props.questions.filter(question => question.CategoryName === 'Filtering')
      );
      _.set(
        newState,
        ['infoGatheringQuestions'],
        props.questions.filter(question => question.CategoryName !== 'Filtering')
      );
      newState.propQuestions = _.cloneDeep(props.questions);
    }

    if (Object.keys(state.botResponses).length === 0 && Object.keys(_.get(props, ['answers'], {})).length !== 0) {
      _.set(newState, ['botResponses'], props.answers);
    }

    if (state.isBotEnabled === undefined && props.isBotEnabled !== undefined) {
      _.set(newState, ['isBotEnabled'], props.isBotEnabled);
    }

    return newState;
  }

  componentDidUpdate(prevProps) {
    const { answers } = this.props;
    if (_.get(prevProps, ['answers']) && answers && prevProps.answers !== answers) {
      this.setState({
        botResponses: answers,
      });
    }
  }

  onRadioButtonChange(event) {
    const { form } = this.props;
    const { screeningQuestions, selectedRadioButton, isGenerateQuestionsClicked } = this.state;
    if (selectedRadioButton === 'screening' && !isGenerateQuestionsClicked) {
      const updatedScreeningQuestions = screeningQuestions.map((question, index) => ({
        Question: form?.getFieldValue(`bot-question_${index}`),
        ExpectedAnswer: form?.getFieldValue(`bot-answer_${index}`),
        CategoryName: question.CategoryName || 'Filtering',
      }));
      this.setState({
        screeningQuestions: updatedScreeningQuestions,
      });
    }
    this.setState({
      selectedRadioButton: event.target.value,
      isGenerateQuestionsClicked: false,
    });
  }

  onResponseChange(id, response) {
    const { botResponses } = this.state;
    this.setState({
      botResponses: {
        ...botResponses,
        [id]: response,
      },
    });
  }

  onUpdateJobDescription({ htmlContent }) {
    this.onResponseChange('JobDescription', htmlContent);
  }

  onTemplateSelect(value) {
    const { getTemplateQuestions, aryabotTemplatesById } = this.props;
    this.setState({ selectedTemplateId: value });
    if (value) {
      if (_.get(aryabotTemplatesById, [value, 'Questions'], false)) {
        this.addTemplateQuestions(aryabotTemplatesById[value].Questions);
      } else {
        getTemplateQuestions(value, questions => this.addTemplateQuestions(questions));
      }
    }
  }

  onCategoryChange(index, value) {
    const { infoGatheringQuestions } = this.state;
    infoGatheringQuestions[index].CategoryName = value;
    this.setState(
      {
        infoGatheringQuestions,
      },
      this.validateForm
    );
  }

  onCategoryBlur(index, e) {
    const { categoryErrors } = this.state;
    if (e.target.value === '' || !e.target.value) {
      this.setState({
        categoryErrors: { ...categoryErrors, [index]: true },
      });
    } else {
      this.setState({
        categoryErrors: { ...categoryErrors, [index]: false },
      });
    }
  }

  onInfoQuestionChange(index, value) {
    const { infoGatheringQuestions } = this.state;
    infoGatheringQuestions[index].Question = value;
    this.setState(
      {
        infoGatheringQuestions,
      },
      this.validateForm
    );
  }

  removeInfoGatheringQuestion(index) {
    const { infoGatheringQuestions } = this.state;
    const clonedInfoGatheringQuestions = _.cloneDeep(infoGatheringQuestions);
    clonedInfoGatheringQuestions.splice(index, 1);
    this.setState(
      {
        infoGatheringQuestions: clonedInfoGatheringQuestions,
      },
      this.validateForm
    );
  }

  onSaveForm() {
    const { onSave, form } = this.props;
    const { botResponses, isBotEnabled } = this.state;
    if (!this.validateForm()) {
      form.validateFields(err => {
        if (!err) {
          onSave(this.prepareQuestions(), botResponses, isBotEnabled);
        }
      });
    }
  }

  onChangeBotQuestion(type, index, value) {
    const { screeningQuestions } = this.state;
    if (!screeningQuestions[index]) {
      screeningQuestions[index] = {};
    }
    if (type === 'question') {
      screeningQuestions[index].Question = value;
    } else if (type === 'expectedAnswer') {
      screeningQuestions[index].ExpectedAnswer = value;
    }

    this.setState({
      screeningQuestions,
    });
  }

  getBotResponseItem(id, question, isDisabled) {
    const { botResponses } = this.state;
    const { intl } = this.props;

    return (
      <div className="response-item" key={id}>
        <div className="question">{question}</div>
        {id === 'JobDescription' ? (
          <RichTextEditor
            editorContent={{ htmlContent: botResponses[id] }}
            onChange={this.onUpdateJobDescription}
            showToolbar
          />
        ) : (
          <TextArea
            rows={1}
            size="small"
            placeholder={intl.formatMessage({ ...placeholder.writeYourAnswerHereLabel })}
            value={botResponses[id]}
            onChange={event => {
              this.onResponseChange(id, event.target.value);
            }}
            className="answer"
            autosize={{ minRows: 1, maxRows: 6 }}
            disabled={isDisabled}
          />
        )}
      </div>
    );
  }

  getBotResponsesTab() {
    const { frequentQuestions, frequentQuestionGroups } = this.props;
    return (
      <div className="bot-responses">
        {Object.keys(frequentQuestionGroups).map(questionGroup => {
          return (
            <div className="question-group" key={questionGroup}>
              {questionGroup}
              <div className="questions-list">
                {frequentQuestionGroups[questionGroup].map(questionCode => {
                  return this.getBotResponseItem(
                    questionCode,
                    _.get(frequentQuestions, [questionCode, 'Question'], ''),
                    _.get(frequentQuestions, [questionCode, 'IsDisabled'], false)
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  getButtonGroup() {
    return (
      <div className="button-group">
        <RadioGroup defaultValue="screening" onChange={this.onRadioButtonChange}>
          <RadioButton value="screening">
            1. <FormattedMessage {...message.screeningLabel} />
          </RadioButton>
          <RadioButton value="infoGathering">
            2. <FormattedMessage {...message.infoGatheringLabel} />
          </RadioButton>
        </RadioGroup>
      </div>
    );
  }

  getBotQuestionsTab = () => {
    const { selectedRadioButton } = this.state;
    return (
      <div>
        {this.getButtonGroup()}
        {selectedRadioButton === 'screening' ? this.getScreeningQuestions() : this.getInfoGatheringQuestions()}
      </div>
    );
  };

  getInfoGatheringQuestions() {
    const { readOnly, isPaidJobServiceEnabled } = this.props;
    const { infoGatheringQuestions, categoryErrors, questionErrors } = this.state;
    const isAddMoreQuestionButtonHidden = readOnly || isPaidJobServiceEnabled;
    return (
      <div className="info-gathering-section">
        {infoGatheringQuestions.map((question, index) => {
          return (
            <InfoGatheringQuestionItem
              question={question}
              categoryErrors={categoryErrors}
              infoGatheringLastQuestionRef={this.infoGatheringLastQuestionRef}
              onCategoryChange={this.onCategoryChange}
              removeQuestion={() => this.removeInfoGatheringQuestion(index)}
              onInfoQuestionChange={this.onInfoQuestionChange}
              questionErrors={questionErrors}
              questionIndex={index}
              readOnly={readOnly}
              onDragQuestion={this.moveInfoGatheringQuestions}
              dragType="info_gathering_question_drag"
              key={question.Id}
            />
          );
        })}
        {!isAddMoreQuestionButtonHidden ? (
          <LinkButton
            buttonName={<FormattedMessage {...message.addQuestionsButton} />}
            onClickButton={this.onClickAddMoreQuestionInfoGathering}
          />
        ) : null}
      </div>
    );
  }

  swapScreeningQuestionFormValue = ({ dragIndex, hoverIndex }) => {
    const { form } = this.props;
    const dragQuestionFormValue = form.getFieldValue(`bot-question_${dragIndex}`);
    const dragAnswerFormValue = form.getFieldValue(`bot-answer_${dragIndex}`);

    form.setFieldsValue({
      [`bot-question_${dragIndex}`]: form.getFieldValue(`bot-question_${hoverIndex}`),
      [`bot-answer_${dragIndex}`]: form.getFieldValue(`bot-answer_${hoverIndex}`),
      [`bot-question_${hoverIndex}`]: dragQuestionFormValue,
      [`bot-answer_${hoverIndex}`]: dragAnswerFormValue,
    });
  };

  moveQuestions = ({ questions, dragIndex, hoverIndex }) => {
    const clonedQuestions = _.cloneDeep(questions);
    const dragQuestion = clonedQuestions[dragIndex];
    clonedQuestions.splice(dragIndex, 1);
    clonedQuestions.splice(hoverIndex, 0, dragQuestion);

    return clonedQuestions;
  };

  moveScreeningQuestions = (dragIndex, hoverIndex) => {
    const { screeningQuestions } = this.state;
    const clonedSreeningQuestions = this.moveQuestions({
      questions: screeningQuestions,
      dragIndex,
      hoverIndex,
    });
    this.swapScreeningQuestionFormValue({ dragIndex, hoverIndex });
    this.setState({ screeningQuestions: clonedSreeningQuestions });
  };

  moveInfoGatheringQuestions = (dragIndex, hoverIndex) => {
    const { infoGatheringQuestions } = this.state;
    const clonedInfoGatheringQuestions = this.moveQuestions({
      questions: infoGatheringQuestions,
      dragIndex,
      hoverIndex,
    });
    this.setState({ infoGatheringQuestions: clonedInfoGatheringQuestions });
  };

  handleGenerateQuestions = () => {
    const { contextById, jobDescription, generateQuestions, BotContextId } = this.props;
    generateQuestions(BotContextId, jobDescription);
    const questions = _.get(contextById, [BotContextId, 'Questions'], []);
    const filteredQuestions = questions.filter(question => question.CategoryName === 'Filtering');
    this.setState({
      screeningQuestions: filteredQuestions,
      isGenerateQuestionsClicked: true,
      showSelectTemplate: false,
      selectedTemplateId: undefined,
      showModal: false,
    });
  };

  getScreeningQuestions() {
    const {
      currentAryabotTemplateIds,
      aryabotTemplatesById,
      form,
      hideSelectTemplate,
      readOnly,
      subTitle,
      isPaidJobServiceEnabled,
      generateQuestionsApiStatus,
      getJobQuestionApiStatus,
      intl,
    } = this.props;
    const { screeningQuestions, showSelectTemplate, selectedTemplateId, showModal } = this.state;
    const isAddMoreQuestionButtonHidden = readOnly || isPaidJobServiceEnabled;
    const disableGenerateQuestionButton =
      getJobQuestionApiStatus === 'INPROGRESS' || generateQuestionsApiStatus === 'INPROGRESS';
    const showScreeningQuestionItem = screeningQuestions.map((question, index) => {
      return (
        <Skeleton active loading={generateQuestionsApiStatus === 'INPROGRESS'} paragraph={{ rows: 3 }}>
          <ScreeningQuestionItem
            question={question.Question}
            expectedAnswer={question.ExpectedAnswer}
            form={form}
            index={index}
            removeQuestion={() => this.removeQuestion(index)}
            key={question.Id}
            readOnly={readOnly}
            onDragQuestion={this.moveScreeningQuestions}
            dragType="screening_question_drag"
            questionId={question.Id}
          />
        </Skeleton>
      );
    });
    return (
      <div className="bot-screening-questions">
        {subTitle || <FormattedMessage {...message.aryabotQuestionPrompt} />}&nbsp;&nbsp;
        {!hideSelectTemplate ? (
          <Button
            onClick={() => {
              this.setState({ showSelectTemplate: !showSelectTemplate });
            }}
          >
            <FormattedMessage {...message.selectTemplateButton} />
          </Button>
        ) : null}
        {/* <Button
            type="default"
            className="generate-questions-btn"
            onClick={() => this.setState({ showModal: true })}
            disabled={disableGenerateQuestionButton}
          >
            {screeningQuestions.length > 0 ? 'Regenerate Questions' : 'Generate Questions'}
          </Button> */}
        {showModal && (
          <Modal
            title="Generate/Regenerate Questions"
            visible={showModal}
            onOk={this.handleGenerateQuestions}
            onCancel={() => this.setState({ showModal: false })}
            okText="Yes"
            zIndex={2001}
          >
            Manually added questions will be removed. Do you want to proceed?
          </Modal>
        )}
        {showSelectTemplate ? (
          <div>
            <Select
              allowClear
              dropdownClassName="select-aryabot-template"
              className="select-template"
              value={selectedTemplateId}
              placeholder={intl.formatMessage({ ...placeholder.pleaseSelectATemplateLabel })}
              onChange={value => this.onTemplateSelect(value)}
              showSearch
              optionFilterProp="title"
            >
              {currentAryabotTemplateIds.map(templateId => {
                const aryabotTemplate = aryabotTemplatesById[templateId];
                return (
                  <Option key={aryabotTemplate.Id} title={aryabotTemplate.Name}>
                    {aryabotTemplate.Name}
                  </Option>
                );
              })}
            </Select>
          </div>
        ) : null}
        <div className="questions" ref={this.screeningLastQuestionRef}>
          {showScreeningQuestionItem}
        </div>
        {!isAddMoreQuestionButtonHidden ? (
          <LinkButton
            buttonName={<FormattedMessage {...message.addQuestionsButton} />}
            onClickButton={this.addQuestion}
          />
        ) : null}
      </div>
    );
  }

  onClickAddMoreQuestionInfoGathering = () => {
    const { infoGatheringQuestions } = this.state;
    const { isPaidJobServiceEnabled } = this.props;
    this.setState({
      infoGatheringQuestions: [...infoGatheringQuestions, { ExpectedAnswer: 'FREETEXT' }],
    });
    if (isPaidJobServiceEnabled) {
      setTimeout(
        () => this.infoGatheringLastQuestionRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' }),
        0
      );
    }
  };

  addTemplateQuestions(questions) {
    const { screeningQuestions, infoGatheringQuestions } = this.state;
    const partitionedQuestions = _.partition(questions, question => question.CategoryName === 'Filtering');
    const screeningQuestionsFromTemplate = partitionedQuestions[0];
    const infoGatheringQuestionsFromTemplate = partitionedQuestions[1];
    this.setState({
      screeningQuestions: [...screeningQuestions, ...screeningQuestionsFromTemplate],
      infoGatheringQuestions: [...infoGatheringQuestions, ...infoGatheringQuestionsFromTemplate],
    });
  }

  addQuestion() {
    const { screeningQuestions } = this.state;
    const { isPaidJobServiceEnabled } = this.props;
    const cloneScreeningQuestions = _.cloneDeep(screeningQuestions);
    cloneScreeningQuestions.push({ Question: '', ExpectedAnswer: 'Yes' });

    this.setState({
      screeningQuestions: cloneScreeningQuestions,
    });

    if (isPaidJobServiceEnabled) {
      setTimeout(() => this.screeningLastQuestionRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' }), 0);
    }
  }

  removeQuestion(index) {
    const { screeningQuestions } = this.state;
    const cloneScreeningQuestions = _.cloneDeep(screeningQuestions);
    cloneScreeningQuestions.splice(index, 1);

    this.setState({
      screeningQuestions: cloneScreeningQuestions,
    });
  }

  changeQuestion(index, value) {
    const { screeningQuestions } = this.state;
    const cloneScreeningQuestions = _.cloneDeep(screeningQuestions);
    cloneScreeningQuestions[index].Question = value;

    this.setState({
      screeningQuestions: cloneScreeningQuestions,
    });
  }

  changeExpectedAnswer(index, value) {
    const { screeningQuestions } = this.state;
    const cloneScreeningQuestions = _.cloneDeep(screeningQuestions);
    if (_.get(cloneScreeningQuestions, [index], false)) {
      cloneScreeningQuestions[index].ExpectedAnswer = value;
    }

    this.setState({
      screeningQuestions: cloneScreeningQuestions,
    });
  }

  prepareQuestions() {
    const { screeningQuestions, infoGatheringQuestions } = this.state;
    const { form } = this.props;
    const requestBodyScreeningQuestions = screeningQuestions.map((question, index) => {
      return {
        Question: form.getFieldValue(`bot-question_${index}`) || question.Question,
        ExpectedAnswer: form.getFieldValue(`bot-answer_${index}`) || question.ExpectedAnswer,
        CategoryName: question.CategoryName || 'Filtering',
        Order: index + 1,
      };
    });
    const infoGatheringRequestQuestions = infoGatheringQuestions
      .filter(question => question.Question && question.CategoryName)
      .map((question, index) => {
        return {
          Question: question.Question,
          ExpectedAnswer: question.ExpectedAnswer,
          CategoryName: question.CategoryName,
          Order: index + 1 + screeningQuestions.length,
          CreatedBy: question.CreatedBy,
          CreatedTime: question.CreatedTime,
          Id: question.Id,
        };
      });
    return [...requestBodyScreeningQuestions, ...infoGatheringRequestQuestions];
  }

  validateForm() {
    const { infoGatheringQuestions } = this.state;
    const categoryErrors = {};
    const questionErrors = {};
    let hasErrors = false;
    Object.keys(infoGatheringQuestions).forEach(key => {
      const question = infoGatheringQuestions[key];
      if (question && !question.CategoryName?.trim()) {
        categoryErrors[key] = true;
      }
      if (question && !question.Question?.trim()) {
        questionErrors[key] = true;
      }
      if (question && !question.CategoryName && !question.Question) {
        categoryErrors[key] = false;
        questionErrors[key] = false;
      }
      if (!hasErrors && (categoryErrors[key] || questionErrors[key])) hasErrors = true;
    });
    this.setState({
      categoryErrors,
      questionErrors,
      hasErrors,
    });
  }

  render() {
    const { saveLoading, showBotQuestionsReviewMessage, updateReviewMessageVisibility } = this.props;
    const { isBotEnabled, hasErrors } = this.state;
    return (
      <div>
        <Tabs defaultActiveKey="botQuestions">
          <TabPane tab={<FormattedMessage {...message.aryabotQuestionsTab} />} key="botQuestions">
            {showBotQuestionsReviewMessage ? (
              <Alert
                closable
                showIcon
                type="info"
                className="review-questions-alert"
                message={<FormattedMessage {...message.reviewQuestionsMessage} />}
                onClose={() => updateReviewMessageVisibility(false)}
              />
            ) : null}
            {this.getBotQuestionsTab()}
          </TabPane>
          <TabPane tab={<FormattedMessage {...message.aryabotResponses} />} key="botResponses">
            {this.getBotResponsesTab()}
          </TabPane>
        </Tabs>
        <div className="bot-config-footer">
          <div>
            <Switch checked={isBotEnabled} onChange={checked => this.setState({ isBotEnabled: checked })} /> &nbsp;
            <FormattedMessage {...message.aryabotLabel} />
          </div>
          <Button
            type="primary"
            onClick={this.onSaveForm}
            loading={saveLoading}
            disabled={hasErrors}
            sk-event-name={eventTypes.job.aryaBotConfig.saveAryaBotConfig}
          >
            <FormattedMessage {...message.saveButton} />
          </Button>
        </div>
      </div>
    );
  }
}

export default injectIntl(DragDropContext(HTML5Backend)(Form.create()(BotConfig)));
export { BotConfig };

import React from 'react';
import moment from 'moment';
import { Skeleton, Icon, Select, Button, Form, Popover, Tooltip } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import InfiniteScroll from 'react-infinite-scroller';
import _ from 'lodash';
import classNames from 'classnames';
import AryaSendIconV2 from '../../../Icons/AryaSendIconV2';
import ExclamationCircleIcon from '../../../Icons/ExclamationCircleIcon';
import config from '../../../Config/Config';
import './ConnectMessageWindow.scss';
import ChatTextHistory from '../ChatTextHistory/ChatTextHistory';
import RichTextEditor from '../../Editor/RichTextEditor';
import UserAlerts from '../../UserAlerts/UserAlerts';
import { getSortedPhonesByTypes, validateStatus } from '../../../Utils/ContactUtils';
import { validateTemplate } from '../../../Utils/Validators';
import { getChatHistoryWithDatesOnTop } from '../../../Utils/ChatHistoryUtils';
import Candidate360MessageStack from './Connect360MessageStack';
import Candidate360MailAndMessageComposeWindow from '../Candidate360MailAndMessageComposeWindow/Candidate360MailAndMessageComposeWindow';
import DemoCandidate360MailAndMessageComposeWindow from '../Candidate360MailAndMessageComposeWindow/DemoCandidate360MailAndMessageComposeWindow';
import { validatePhone } from '../../../Utils/FormValidators';
import { formatTextMessage, getPlainTextFromHTML, EllipsedText } from '../../../Utils/TextUtils';
import { messageCount, emojiFinder } from '../../Utils/SmsUtils';
import message from '../../../Containers/CandidateDrawer/messages';
import jobMessage from '../../JobForm/JobMessages';
import placeholder from '../../Placeholders/PlaceholdersMessages';
import SpamAlertText from '../../Common/SpamTextAlert/SpamTextAlert';
import { getEmailTemplateIdentifier } from '../../Common/EmailTemplateIdentifier/EmailTemplateIdentifier';
import ReportChatModal from './ReportChatModal';
import ExclamationIcon from '../../../Icons/ExclamationIcon';

const { Option } = Select;
const { Item } = Form;
const { MessageBubble: MessageBubbleFromTextHistory } = ChatTextHistory;

function getPhoneDropdownOptions(phones, phoneOptionValue, isConnectContentPane) {
  if (!phones && !phoneOptionValue) {
    return null;
  }
  const sortedPhones = getSortedPhonesByTypes({ phones });
  const phoneDropdown = sortedPhones.map(phone => {
    return _.get(phone, ['IsRefunded'], false) ? null : (
      <Option value={phone.Number} key={phone.Number}>
        <span className={validateStatus(phone.ValidityStatus) ? 'invalid-spam-phone' : null}> {phone.Number}</span>
      </Option>
    );
  });
  const allowPhoneOptionAdd = phoneOptionValue && !isConnectContentPane;
  if (allowPhoneOptionAdd)
    phoneDropdown.push(
      <Option value={phoneOptionValue} key={phoneOptionValue} className="phone-option-container">
        <span className={validateStatus(phoneOptionValue?.ValidityStatus) ? 'invalid-spam-phone' : null}>
          {' '}
          {phoneOptionValue}
        </span>
        <span className="add-phone">
          + <FormattedMessage {...message.addPhoneLabel} />
        </span>{' '}
      </Option>
    );
  return phoneDropdown;
}

class ConnectMessageWindow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneOptionValue: undefined,
      mergeTagPopover: false,
      currentPage: 1,
      isReportChatVisible: false,
    };
    this.myRef = React.createRef();
    this.formRef = React.createRef();
    this.addTags = this.addTags.bind(this);
    this.onReportChatClick = this.onReportChatClick.bind(this);
    this.onReportChatClose = this.onReportChatClose.bind(this);
    this.onReportChatSubmit = this.onReportChatSubmit.bind(this);
  }

  addTags(tag) {
    this.myRef.current.addTags(tag, false);
  }

  componentDidUpdate(prevProps) {
    const {
      currentValues: { isConsent },
      form,
      templateName,
    } = this.props;
    if (templateName !== prevProps.templateName) {
      const newFieldsValue = {};
      if (isConsent) {
        newFieldsValue.consentTemplate = templateName;
      } else {
        newFieldsValue.template = templateName;
      }
      form.setFieldsValue(newFieldsValue);
    }
  }

  onChangePhone = (phoneValue, eventCallbacks) => {
    eventCallbacks.onChangePhone(phoneValue);
    this.setState({ phoneOptionValue: undefined });
  };

  validateTextTemplate = ({ text }) => {
    const { mergeTags } = this.props;
    const mergeTagsForMessage = mergeTags.filter(mergeTag => mergeTag.Scopes.includes('Sms'));
    return validateTemplate({ body: text, bodyMergeTags: mergeTagsForMessage });
  };

  handleSubmit = event => {
    const {
      form,
      eventCallbacks,
      messages,
      currentValues,
      setInvalidTemplateNotification,
      candidateContext,
      getMessageConsentStatus,
      setTemplateName,
    } = this.props;
    event.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        const text = _.trimEnd(this.myRef.current.quillRef.getText().trim(), '\n');
        if (!this.validateTextTemplate({ text })) {
          setInvalidTemplateNotification(candidateContext);
          return;
        }
        const { selectedPhone } = currentValues;
        let messageConsentStatus = getMessageConsentStatus();
        if (!messageConsentStatus && messages && messages.find(m => m.To === selectedPhone.Number)) {
          messageConsentStatus = 'Pending';
        }
        const message = { ...values, IsConsentMessage: !messageConsentStatus || messageConsentStatus === 'Failed' };
        eventCallbacks.onSend(message, text, this.onReadPhoneNumberSMS);
        setTemplateName(undefined);
        form.setFieldsValue({
          template: undefined,
          consentTemplate: undefined,
        });
      }
    });
  };

  addPhone = value => {
    const { jobGuid, candidate, addContact } = this.props;
    const contactObject = {
      Phones: [
        {
          Number: value,
          IsAddedByUser: true,
          ValidityStatus: 'Valid',
        },
      ],
    };
    addContact(jobGuid, candidate.PersonId, contactObject);
  };

  onReportChatClick = () => {
    this.setState({
      isReportChatVisible: true,
    });
  };

  onReportChatClose = () => {
    this.setState({
      isReportChatVisible: false,
    });
  };

  onReportChatSubmit = async payload => {
    const { reportConversation, conversationId } = this.props;
    await reportConversation(conversationId, payload);
    this.setState({
      isReportChatVisible: false,
    });
  };

  onAddPhoneClick = value => {
    const {
      eventCallbacks,
      form,
      contact: { Phones: phones },
      setPhoneNumber,
      phoneNumber,
    } = this.props;
    const { phoneOptionValue } = this.state;
    const { validateFields } = form;
    const isPhoneExist = !!phones.find(entry => entry.Number === value);
    if (setPhoneNumber) setPhoneNumber(value);
    if (!isPhoneExist)
      validateFields(err => {
        if (!err) {
          this.addPhone(phoneOptionValue);
          eventCallbacks.onChangePhone(phoneNumber);
          this.setState({
            phoneOptionValue: undefined,
          });
        }
      });
  };

  setPhoneOptionValue = value => {
    this.setState({
      phoneOptionValue: value,
    });
  };

  getSelectPhoneNumberDropdown = () => {
    const {
      form,
      currentValues,
      eventCallbacks,
      contact: { Phones: phones },
      phoneNumber,
      isConnectContentPane,
      intl,
      isReportChatEnabled,
      fetchReportConversationApiStatus,
      reportDetails,
      conversationId,
      messages,
    } = this.props;
    const { phoneOptionValue } = this.state;
    const { getFieldDecorator } = form;
    const { selectedPhone } = currentValues;
    const number = phoneNumber || undefined;
    const initalValue = selectedPhone?.Number || number;

    return (
      <div className="connect-message-window-flex-items phone-dropdown">
        <Item>
          {getFieldDecorator('phoneNumber', {
            initialValue: initalValue,
            rules: [
              {
                required: true,
                message: 'Invalid number. Please start with country code.',
                validator: validatePhone,
              },
            ],
          })(
            <Select
              dropdownClassName="connect-drawer"
              placeholder={intl.formatMessage({ ...placeholder.selectPhoneNumberLabel })}
              dropdownMenuStyle={{ maxHeight: 120 }}
              style={{ width: 200 }}
              onChange={value => {
                this.onChangePhone(value, eventCallbacks);
                form.setFieldsValue({
                  template: undefined,
                  consentTemplate: undefined,
                });
              }}
              disabled={!phones || !phones.length}
              getPopupContainer={() => this.formRef.current}
              className="select-to-dropdown-360"
              showSearch
              onSearch={this.setPhoneOptionValue}
              onSelect={this.onAddPhoneClick}
              notFoundContent=""
              dropdownStyle={{ minWidth: 'fit-content' }}
            >
              {getPhoneDropdownOptions(phones, phoneOptionValue, isConnectContentPane)}
            </Select>
          )}
        </Item>
        {isReportChatEnabled && conversationId ? (
          <Item>
            <Button
              type="primary"
              shape="round"
              ghost
              onClick={this.onReportChatClick}
              className="report-chat-button"
              loading={fetchReportConversationApiStatus === 'INPROGRESS'}
              disabled={messages?.length === 0}
            >
              {reportDetails?.Comment ? (
                <FormattedMessage {...message.reportedChatLable} />
              ) : (
                <>
                  <ExclamationIcon color={messages?.length === 0 ? '#13c26b' : '#d9d9d9'} />
                  <FormattedMessage {...message.reportChatLabel} />
                </>
              )}
            </Button>
          </Item>
        ) : null}
      </div>
    );
  };

  getNonConsentTemplateDropdown = ({ isSendMessageAllowed, isPlanExistOrCreditZero }) => {
    const { form, eventCallbacks, templateName, nonConsentMessageTemplates, intl } = this.props;
    const { getFieldDecorator } = form;
    const templateSelectClassname = !templateName ? 'empty-select-template' : '';
    return (
      <div>
        {getFieldDecorator('template', { initialValue: templateName })(
          <Select
            dropdownClassName="template-dropdown"
            dropdownMenuStyle={{ maxHeight: 120 }}
            dropdownStyle={{ width: 300 }}
            placeholder={intl.formatMessage({ ...placeholder.selectTemplateLabel })}
            allowClear
            placement="topLeft"
            style={{ width: 300 }}
            onChange={templateId => eventCallbacks.onTemplateSelect(templateId, false)}
            disabled={!isSendMessageAllowed || isPlanExistOrCreditZero}
            getPopupContainer={() => this.formRef.current}
            className={`tempalete-candidate-360 ${templateSelectClassname}`}
          >
            {nonConsentMessageTemplates.map(template => (
              <Option key={template.Id} value={template.Id} title={template.Name}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <span className="connect-template-name">{template.Name}</span>
                  {getEmailTemplateIdentifier(template.IsDrip, template.IsSystemTemplate, template.IsDefault)}
                </div>
              </Option>
            ))}
          </Select>
        )}
      </div>
    );
  };

  getConsentTemplateDropdown = ({ isSendMessageAllowed, isValidConsentStatus, isPlanExistOrCreditZero }) => {
    const { form, eventCallbacks, templateName, consentMessageTemplates, intl } = this.props;
    const { getFieldDecorator } = form;
    const templateSelectClassname = !templateName ? 'empty-select-template' : '';
    return (
      <div>
        {getFieldDecorator('consentTemplate', { initialValue: templateName })(
          <Select
            dropdownClassName="template-dropdown"
            dropdownMenuStyle={{ maxHeight: 120 }}
            placeholder={intl.formatMessage({ ...placeholder.selectTemplateLabel })}
            dropdownStyle={{ width: 400 }}
            allowClear
            placement="topLeft"
            onChange={templateId => eventCallbacks.onTemplateSelect(templateId, true)}
            disabled={!isSendMessageAllowed || isPlanExistOrCreditZero}
            getPopupContainer={() => this.formRef.current}
            className={`tempalete-candidate-360 ${templateSelectClassname}`}
          >
            {isValidConsentStatus
              ? consentMessageTemplates.map(template => (
                  <Option key={template.Id} value={template.Id} title={template.Name}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <span className="connect-template-name">
                        <EllipsedText text={template.Name} maxTextLength={30} />
                      </span>
                      {getEmailTemplateIdentifier(template.IsDrip, template.IsSystemTemplate, template.IsDefault)}
                    </div>
                  </Option>
                ))
              : null}
          </Select>
        )}
      </div>
    );
  };

  getTextEditorWithCharacterCount = args => {
    const { isSendMessageAllowed, showCharacterCount = true, shouldSentNonConsentMessage } = args;
    const {
      currentValues,
      eventCallbacks,
      mergeTags,
      featureToggleList,
      availableCreditInfo,
      isJunkWordFound,
      junkWords,
      setIsJunkWordFound,
      intl,
    } = this.props;
    const { messageInputValue } = currentValues;
    const { charactersPerMessage } = config;
    const messageLength = messageInputValue ? getPlainTextFromHTML(messageInputValue).length : 0;
    const isPlanExistOrCreditZero = availableCreditInfo === 'N/A' || availableCreditInfo === 0;
    const placeholderText = intl.formatMessage({ ...message.typeANewMessageLabel });
    const {
      MessageConsentTemplateCreation: { IsEnabled: messageConsentTemplateCreationEnabled },
    } = featureToggleList;
    const isTextEditorDisable =
      !isSendMessageAllowed ||
      (!shouldSentNonConsentMessage && !messageConsentTemplateCreationEnabled) ||
      isPlanExistOrCreditZero;

    return (
      <div
        className={classNames('message-textarea', {
          'message-textarea-with-spamalert': isJunkWordFound,
        })}
      >
        <RichTextEditor
          className="message-editor"
          editorContent={{
            htmlContent: messageInputValue,
          }}
          placeholder={placeholderText}
          onChange={eventCallbacks.onUpdateMessage}
          mergeTags={mergeTags}
          ref={this.myRef}
          showToolbar={false}
          disabled={isTextEditorDisable}
          editorContext="Message"
        />
        {isJunkWordFound ? (
          <SpamAlertText
            style={{ paddingLeft: '15px' }}
            junkWords={junkWords}
            setIsJunkWordFound={setIsJunkWordFound}
          />
        ) : null}
        <hr
          style={{
            borderColor: 'rgb(252,252,252)',
            width: '100%',
            marginBottom: '-1px',
            marginTop: '0px',
          }}
        />
        {emojiFinder(messageInputValue).length ? (
          <div
            style={{
              display: 'inline-flex',
              backgroundColor: 'rgb(255,251,230)',
              paddingLeft: '15px',
              alignItems: 'center',
            }}
          >
            <p style={{ marginBottom: '-1px' }}>
              &nbsp; Unicode (Special characters/emojis) {`" ${emojiFinder(messageInputValue)} "`} detected &nbsp;
            </p>
            <Tooltip
              zIndex={2223}
              title="Utilizing Unicode (special characters/emojis) for text reduces the character count per text from 160 to 70 and may result in carrier flagging text as spam. Their usage also results in an increased number of texts sent and credits used."
              placement="topLeft"
            >
              <ExclamationCircleIcon />
            </Tooltip>
          </div>
        ) : null}
        {showCharacterCount ? (
          <div className="message-character-count">
            {charactersPerMessage - (messageLength % charactersPerMessage)}/
            {Math.floor(messageLength / charactersPerMessage)}
            &nbsp; &nbsp;
          </div>
        ) : null}
      </div>
    );
  };

  getIsSendMessageAllowed = ({ messageConsentStatus }) => {
    return (
      (!messageConsentStatus || messageConsentStatus === 'Approved' || messageConsentStatus === 'Failed') &&
      this.getIsValidConsentStatus()
    );
  };

  getIsValidConsentStatus = () => {
    const {
      currentValues,
      contact: { ConsentStatus: consentStatus },
    } = this.props;
    const { selectedPhone } = currentValues;
    return !consentStatus || consentStatus === 'Approved' || (selectedPhone && selectedPhone.IsAddedByUser);
  };

  getSendButtonState = () => {
    const {
      getMessageConsentStatus,
      messages,
      currentValues,
      contact: { ConsentStatus: consentStatus },
      featureToggleList,
      currentValues: { messageInputValue },
      sendMessageApiStatus,
      phonenumberMessages,
      isAllSMS,
    } = this.props;

    const selectedPhoneNumberMessages = getChatHistoryWithDatesOnTop(phonenumberMessages);
    const receivedMessages = selectedPhoneNumberMessages.filter(message => !message.IsByPerson && message.To);
    const lastSentMessageFromRecruiter = receivedMessages?.length ? receivedMessages[receivedMessages.length - 1] : {};
    const lastSentMessageBody = lastSentMessageFromRecruiter.Body?.trim();
    const currentMessageBody = this.myRef.current
      ? _.trimEnd(this.myRef.current?.quillRef?.getText()?.trim(), '\n')
      : null;
    const lastSentMessageTime = moment.utc(lastSentMessageFromRecruiter.SentTime).local();
    const isSendingMessageWithinAnHour = moment().diff(lastSentMessageTime, 'hour') < 1;
    const isCurrentAndLastSentMessageByRecruiterSame =
      currentMessageBody?.toLowerCase() === lastSentMessageBody?.toLowerCase();
    const isSameMessageWithinAnHour = isCurrentAndLastSentMessageByRecruiterSame && isSendingMessageWithinAnHour;
    if (isSameMessageWithinAnHour)
      return {
        isSendButtonDisabled: true,
        tooltipTitle: <FormattedMessage {...jobMessage.sameMessageCannotBeSendLabel} />,
      };
    if (sendMessageApiStatus === 'INPROGRESS') return { isSendButtonDisabled: true };

    const { selectedPhone } = currentValues;
    let messageConsentStatus = getMessageConsentStatus();
    if (!messageConsentStatus && messages?.find(m => m.To === selectedPhone.Number)) {
      messageConsentStatus = 'Pending';
    }
    const isMessagingAllowedForUser = featureToggleList.MessageRead.IsAllowed;
    const dollarSignRegex = /\n$/gi;
    const specialCharacterRegex = /(<([^>]+)>)/gi;
    const messageInputLength = messageInputValue?.replace(specialCharacterRegex, '')?.length;
    const {
      props: {
        values: { totalTextCount },
      },
    } = messageCount(messageInputValue, messageConsentStatus === undefined || messageConsentStatus === 'Failed');
    const isSendMessageAllowed = this.getIsSendMessageAllowed({ messageConsentStatus });
    let isSendButtonDisabled = !isMessagingAllowedForUser || !isSendMessageAllowed;
    if (this.myRef.current) {
      isSendButtonDisabled =
        isSendButtonDisabled ||
        this.myRef.current.quillRef.getText().trim().replace(dollarSignRegex, '').length === 0 ||
        !selectedPhone ||
        totalTextCount > 7;
    }
    if (!this.myRef.current && !messageInputLength) {
      isSendButtonDisabled = true;
    }
    return { isSendButtonDisabled };
  };

  getTemplateDropdown = ({
    isValidConsentStatus,
    messageConsentStatus,
    isSendMessageAllowed,
    showAddMergeTags,
    isPlanExistOrCreditZero,
    isCommunicationAllowed,
    isAllSMS,
  }) => {
    const candidate360Style =
      !isSendMessageAllowed || isPlanExistOrCreditZero
        ? { background: '#F5F5F5', marginTop: '10px' }
        : { background: 'white', marginTop: '10px' };
    const { isSendButtonDisabled, tooltipTitle } = this.getSendButtonState();

    const templateDropDownStyle = candidate360Style;
    const buttonDisableStyle = isSendButtonDisabled || isPlanExistOrCreditZero ? { filter: 'grayscale(1)' } : {};

    const shouldSentNonConsentMessage = isValidConsentStatus && messageConsentStatus === 'Approved';

    const sendButton = (
      <div className="action-button">
        <Tooltip
          placement="right"
          getPopupContainer={node => node.parentNode}
          title={
            isSendButtonDisabled ? <FormattedMessage {...message.sendButtonDisabledMaxTextCountTooltipText} /> : null
          }
        >
          <Button
            id="connect-message-send-button"
            htmlType="submit"
            disabled={isSendButtonDisabled || isPlanExistOrCreditZero || !isCommunicationAllowed || isAllSMS}
            onClick={this.handleSubmit}
            style={buttonDisableStyle}
          >
            <AryaSendIconV2 />
          </Button>
        </Tooltip>
      </div>
    );
    const sendButtonWithTooltip = tooltipTitle ? (
      <Tooltip title={tooltipTitle} overlayStyle={{ zIndex: 111111 }} placement="topLeft">
        {sendButton}
      </Tooltip>
    ) : (
      sendButton
    );

    return shouldSentNonConsentMessage ? (
      <div
        id="template-dropdown-credits-wrap"
        className="connect-message-window-flex-items"
        style={templateDropDownStyle}
      >
        {this.getNonConsentTemplateDropdown({ isSendMessageAllowed, isPlanExistOrCreditZero })}
        <div className="merge-tag-360">{showAddMergeTags}</div>
        {sendButtonWithTooltip}
      </div>
    ) : (
      <div
        id="template-dropdown-credits-wrap"
        className="connect-message-window-flex-items"
        style={templateDropDownStyle}
      >
        {this.getConsentTemplateDropdown({ isSendMessageAllowed, isValidConsentStatus, isPlanExistOrCreditZero })}
        <div className="merge-tag-360">{showAddMergeTags}</div>
        {sendButtonWithTooltip}
      </div>
    );
  };

  getMessagingNotAllowedAlert = ({ isMessagingAllowedForUser }) => {
    return isMessagingAllowedForUser === 'false' ? (
      <div className="user-alert-wrapper">
        <UserAlerts
          header="Pro feature alert!"
          content={
            <span>
              <FormattedMessage {...jobMessage.featureNotEnabledInfoLabel} />{' '}
              <a href="mailto:support@leoforce.com">support@leoforce.com</a>{' '}
              <FormattedMessage {...jobMessage.upgradeYourPlanLabel} />
            </span>
          }
        />
      </div>
    ) : null;
  };

  getTemplateWarningMessage = () => {
    const { candidateContext } = this.props;
    const className = 'template-lable-360';
    return candidateContext === 'segment' ? (
      <div className={`template-label ${className}`}>
        <FormattedMessage {...message.segmentTemplateSelectionPrompt} />
      </div>
    ) : null;
  };

  getConsentMessage = ({ isSendMessageAllowed }) => {
    const { consentText } = this.props;
    return isSendMessageAllowed ? (
      <div>
        <div className="message-window-template-consent-note-wrapper">
          <span className="message-window-template-consent-note">
            <FormattedMessage {...message.noteLabel} />: <FormattedMessage {...message.textConsentFooter} />
          </span>
        </div>
        <div className="message-window-template-consent-text">{consentText}</div>
      </div>
    ) : null;
  };

  showCharacterCountWithTooltip = () => {
    const {
      getMessageConsentStatus,
      currentValues: { messageInputValue },
    } = this.props;
    const messageConsentStatus = getMessageConsentStatus();
    return (
      <div
        style={{
          marginLeft: '15px',
          justifyContent: 'center',
          paddingTop: '5px',
          paddingBottom: '-5px',
          display: 'inline-flex',
        }}
      >
        {messageCount(messageInputValue, messageConsentStatus === undefined || messageConsentStatus === 'Failed')}{' '}
        &nbsp;
        <div style={{ marginTop: '2px' }}>
          <Tooltip zIndex={2223} title={<FormattedMessage {...message.totalCharactersLabel} />} placement="rightBottom">
            <ExclamationCircleIcon />
          </Tooltip>
        </div>
      </div>
    );
  };

  showConsentMessage = ({ messageConsentStatus, isSendMessageAllowed }) => {
    return messageConsentStatus === 'Approved' ? null : <div>{this.getConsentMessage({ isSendMessageAllowed })}</div>;
  };

  setCurrentPage = page => {
    this.setState({ currentPage: page });
  };

  getCandidate360MessageWindow = candidate360MessageComposeSectionPayload => {
    const { isMessageWriteEnabled, isValidConsentStatus, messageConsentStatus, isSendMessageAllowed, add360MergeTags } =
      candidate360MessageComposeSectionPayload;
    const {
      featureToggleList,
      getConsentStatusInfoBanner,
      phonenumberMessages,
      messageConversationApiStatus,
      getCreditInfoBanner,
      availableCreditInfo,
      fetchMessages,
      conversationId,
      personId,
      selectedPhoneNumber,
      count,
      isAllSMS,
      currentValues: { isCommunicationAllowed },
      isComposeMessageDisabled,
      jobsBasicInfoById,
      basicInfoJobGuidToIdMapping,
      openJobViewInNewTabCallBack,
      openCandidateView,
      candidate,
      version,
    } = this.props;

    const { currentPage } = this.state;

    const consentBanner = getConsentStatusInfoBanner();
    const isPlanExistOrCreditZero = availableCreditInfo === 'N/A' || availableCreditInfo === 0;
    const isMessagingAllowedForUser = featureToggleList.MessageRead.IsAllowed;
    const shouldSentNonConsentMessage = isValidConsentStatus && messageConsentStatus === 'Approved';
    const showConsentBanner = shouldSentNonConsentMessage || isPlanExistOrCreditZero ? null : consentBanner;
    const showAddMergeTags = add360MergeTags || null;

    let textHistoryStyle;

    if (isComposeMessageDisabled) {
      textHistoryStyle = {
        height: 'calc(100vh - 50px)',
      };
    } else if (messageConsentStatus === 'Pending') {
      textHistoryStyle = {
        height: 'calc(100vh - 400px)',
      };
    } else if (messageConsentStatus === 'Failed') {
      textHistoryStyle = {
        height: 'calc(100vh - 490px)',
      };
    } else if (messageConsentStatus === 'Approved') {
      textHistoryStyle = {
        height: 'calc(100vh - 370px)',
      };
    } else {
      textHistoryStyle = {
        height: 'calc(100vh - 410px)',
      };
    }
    const sortedSelectedPhoneNumberMessages = [...(phonenumberMessages ?? [])].sort((a, b) => {
      const dateA = new Date(a.CreatedTime.replace(/Z$/, '')).getTime();
      const dateB = new Date(b.CreatedTime.replace(/Z$/, '')).getTime();
      return dateB - dateA;
    });
    const selectedPhoneNumberMessages = getChatHistoryWithDatesOnTop(sortedSelectedPhoneNumberMessages);
    const isChatHistoryLoading = messageConversationApiStatus === 'INPROGRESS';
    return (
      <div className="candidate-360-message-window">
        <div id="connect-message-window" ref={this.formRef}>
          {!isComposeMessageDisabled ? (
            <div className="candidate-360-phone-with-label">
              <div className="candidate-360-phone-send-to">
                <span className="candidate-360-phone-label">
                  <FormattedMessage {...message.sendToLabel} />:
                </span>{' '}
                {this.getSelectPhoneNumberDropdown()}
              </div>
            </div>
          ) : null}
          <div className="candidate-360-text-history-container" style={textHistoryStyle || {}}>
            <div>
              <Skeleton active loading={isChatHistoryLoading}>
                <div className="candidate-360-text-history-container" style={textHistoryStyle || {}}>
                  <InfiniteScroll
                    useWindow={false}
                    initialLoad={false}
                    loadMore={() => {
                      fetchMessages(conversationId, personId, 'SMS', selectedPhoneNumber, currentPage * 10, 10);
                      this.setCurrentPage(currentPage + 1);
                    }}
                    threshold={1000}
                    hasMore={currentPage * 10 < count}
                  >
                    <ChatTextHistory
                      className="connect-chat-window"
                      inputFieldRef={null}
                      isComposeMessageDisabled={isComposeMessageDisabled}
                    >
                      {selectedPhoneNumberMessages.map(message => (
                        <MessageBubbleFromTextHistory
                          key={message.Id}
                          message={formatTextMessage(message.Body)}
                          timestamp={message.CreatedTime}
                          createdByName={message.CreatedByName}
                          align={message.IsByPerson ? 'left' : 'right'}
                          className={message.IsByPerson ? 'leftText' : 'rightText'}
                          type={message.type}
                          DeliveryStatus={message.DeliveryStatus}
                          RequestStatus={message.RequestStatus}
                          messageDeliveryStatusInformation={message.MessageDeliveryStatusInformation}
                          readOnly
                          jobsBasicInfoById={jobsBasicInfoById}
                          basicInfoJobGuidToIdMapping={basicInfoJobGuidToIdMapping}
                          refId={message.RefId}
                          personId={personId}
                          openJobViewInNewTabCallBack={openJobViewInNewTabCallBack}
                          openCandidateView={openCandidateView}
                          version={version}
                          candidate={candidate}
                        />
                      ))}
                    </ChatTextHistory>
                  </InfiniteScroll>
                </div>
              </Skeleton>
            </div>
          </div>
          <div
            className={classNames('template-dropdown-credits-message-input-box-consent-wrapper', {
              messageNotSent: messageConsentStatus !== 'Approved' && isSendMessageAllowed,
              messageSentConsentNotApproved: !isSendMessageAllowed && messageConsentStatus !== 'Approved',
              smsWindowExtraContentShowLowCreditBanner: !!getCreditInfoBanner(),
              composeMessageDisabled: isComposeMessageDisabled,
            })}
          >
            {isMessageWriteEnabled && !isComposeMessageDisabled ? (
              <div>
                {!isAllSMS && showConsentBanner}
                {getCreditInfoBanner()}
                {this.getTemplateWarningMessage()}
                {this.getTemplateDropdown({
                  isValidConsentStatus,
                  messageConsentStatus,
                  isSendMessageAllowed,
                  showAddMergeTags,
                  isPlanExistOrCreditZero,
                  isCommunicationAllowed,
                  isAllSMS,
                })}
                <div className="message-input-box-consent-wrapper">
                  <div id="message-input-box-wrap" className="connect-message-window-flex-items">
                    <Skeleton active loading={isChatHistoryLoading}>
                      {this.getTextEditorWithCharacterCount({
                        isSendMessageAllowed,
                        showCharacterCount: false,
                        shouldSentNonConsentMessage,
                      })}
                    </Skeleton>
                  </div>
                  <div>
                    {!isAllSMS && this.showConsentMessage({ messageConsentStatus, isSendMessageAllowed })}
                    {this.showCharacterCountWithTooltip()}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          {this.getMessagingNotAllowedAlert({ isMessagingAllowedForUser })}
        </div>
      </div>
    );
  };

  handleCandidateResponse = async (conversationId, payload) => {
    const { sendCandidateResponse } = this.props;
    await sendCandidateResponse(conversationId, payload);
  };

  onReadPhoneNumberSMS = async () => {
    const {
      currentValues: { selectedPhone },
      onReadSMS,
      fetchBulkUnReadConversationsCount,
      conversationId,
    } = this.props;
    await onReadSMS(selectedPhone.Number);
    fetchBulkUnReadConversationsCount({ ConversationIds: [conversationId] });
  };

  render() {
    const {
      messages,
      currentValues,
      mergeTags,
      featureToggleList,
      currentValues: { isCommunicationAllowed },

      isComposeMessageModalVisible,
      setMessageComposeModalVisibility,
      isCandidateViewHeaderVisible,
      getConsentStatusInfoBanner,
      getMessageConsentStatus,
      jobId,
      jobTitle,
      onCloseMessageComposer,
      eventCallbacks,
      form,
      sendMessageApiStatus,
      candidateContext,
      jobCode,
      version,
      availableCreditInfo,
      fetchMessages,
      conversationId,
      phonenumberMessages,
      reportConversationApiStatus,
      getReportConversationDetails,
      reportDetails,
      personId,
      openCandidateView,
      candidate,
      isComposeMessageDisabled,
      phoneNumber,
      openJobViewInNewTabCallBack,
    } = this.props;
    const {
      contact: { ConsentStatus: consentStatus },
    } = this.props;
    const { setFieldsValue } = form;
    const { mergeTagPopover, isReportChatVisible } = this.state;
    const isPlanExistOrCreditZero = availableCreditInfo === 'N/A' || availableCreditInfo === 0;
    const sortedSelectedPhoneNumberMessages = [...(phonenumberMessages ?? [])].sort((a, b) => {
      const dateA = new Date(a.CreatedTime.replace(/Z$/, '')).getTime();
      const dateB = new Date(b.CreatedTime.replace(/Z$/, '')).getTime();
      return dateB - dateA;
    });
    const selectedPhoneNumberMessages = getChatHistoryWithDatesOnTop(sortedSelectedPhoneNumberMessages);

    const mergeTagLabelName = <FormattedMessage {...message.mergeTagsLabel} />;
    const mergeTagsForMessage = mergeTags.filter(mergeTag => mergeTag.Scopes.includes('Sms'));
    const { selectedPhone } = currentValues;

    const isMessageWriteEnabled = featureToggleList.MessageWrite.IsEnabled;
    const messageConsentTemplateCreationEnabled = featureToggleList.MessageConsentTemplateCreation.IsEnabled;
    const chatBotDemoEnabled = featureToggleList.ChatBotDemo?.IsEnabled ?? false;
    let messageConsentStatus = getMessageConsentStatus();
    if (!messageConsentStatus && messages && messages.find(m => m.To === selectedPhone.Number)) {
      messageConsentStatus = 'Pending';
    }

    const isValidConsentStatus = this.getIsValidConsentStatus();
    const isSendMessageAllowed = this.getIsSendMessageAllowed({ messageConsentStatus });
    const { isSendButtonDisabled } = this.getSendButtonState();
    const popoverContent = (
      <div className="tags-popover-content">
        {mergeTagsForMessage.map(mergeTag => (
          <div className="merge-tag" onClick={() => this.addTags(mergeTag)} role="presentation" key={mergeTag.Key}>
            + {mergeTag.DisplayName}
          </div>
        ))}
      </div>
    );
    const shouldSentNonConsentMessage = isValidConsentStatus && messageConsentStatus === 'Approved';
    const isTextEditorDisable =
      !isSendMessageAllowed || (!shouldSentNonConsentMessage && !messageConsentTemplateCreationEnabled);
    const isMergeTagVisible =
      messageConsentStatus !== 'Pending' && messageConsentStatus !== 'Denied' && !isTextEditorDisable;
    const add360MergeTags = (
      <div className="message-window-merge-tag">
        <Popover
          overlayStyle={{ zIndex: 2223 }}
          onVisibleChange={() => this.setState({ mergeTagPopover: !mergeTagPopover })}
          visible={mergeTagPopover}
          content={isMergeTagVisible ? popoverContent : null}
          placement="top"
          trigger="click"
        >
          {!isMergeTagVisible || isPlanExistOrCreditZero ? (
            <span style={{ cursor: 'not-allowed', opacity: 0.5, pointerEvents: 'none' }}>
              {mergeTagLabelName} <Icon type="down" />
            </span>
          ) : (
            <span>
              {mergeTagLabelName} <Icon type={`${mergeTagPopover ? 'up' : 'down'}`} />
            </span>
          )}
        </Popover>
      </div>
    );

    const consentBanner = getConsentStatusInfoBanner();

    const candidate360MessageComposeSectionPayload = {
      isMessageWriteEnabled,
      isValidConsentStatus,
      messageConsentStatus,
      isSendMessageAllowed,
      add360MergeTags,
    };

    return (
      <>
        {isCommunicationAllowed ? null : <div className="connect-message-consent-banner">{consentBanner}</div>}
        <Form
          className={classNames('connect-message-form-360', {
            'extended-connect-message-form-360': !isCandidateViewHeaderVisible,
          })}
        >
          {isReportChatVisible ? (
            <ReportChatModal
              onClose={this.onReportChatClose}
              isVisible={isReportChatVisible}
              onSubmit={this.onReportChatSubmit}
              reportConversationApiStatus={reportConversationApiStatus}
              reportDetails={reportDetails}
            />
          ) : null}
          <Candidate360MessageStack
            openComposeTextModal={setMessageComposeModalVisibility}
            messages={messages}
            isMessageWriteEnabled={isMessageWriteEnabled}
            setPhoneNumber={eventCallbacks.onChangePhone}
            setFieldsValue={setFieldsValue}
            sendMessageApiStatus={sendMessageApiStatus}
            personId={personId}
            openCandidateView={openCandidateView}
            candidate={candidate}
            version={version}
            openJobViewInNewTabCallBack={openJobViewInNewTabCallBack}
          />
          {isComposeMessageModalVisible ? (
            <>
              {' '}
              {!chatBotDemoEnabled ? (
                <Candidate360MailAndMessageComposeWindow
                  jobId={jobId}
                  jobTitle={jobTitle}
                  version={version}
                  jobCode={jobCode}
                  candidateContext={candidateContext}
                  getCandidate360MessageWindow={this.getCandidate360MessageWindow}
                  id="connect-message-send-button"
                  htmlType="submit"
                  isSendDisabled={isSendButtonDisabled}
                  handleSubmit={this.handleSubmit}
                  isModalVisible={isComposeMessageModalVisible}
                  setModalVisibility={setMessageComposeModalVisibility}
                  composeModalTitle={<FormattedMessage {...message.composeTextButton} />}
                  candidate360MessageComposeSectionPayload={candidate360MessageComposeSectionPayload}
                  context="message"
                  onCloseEmailComposer={onCloseMessageComposer}
                  readChat={this.onReadPhoneNumberSMS}
                  fetchMessages={fetchMessages}
                  currentValues={currentValues}
                  conversationId={conversationId}
                  setPhoneNumber={eventCallbacks.onChangePhone}
                  handleCandidateResponse={this.handleCandidateResponse}
                  getReportConversationDetails={getReportConversationDetails}
                  isComposeMessageDisabled={isComposeMessageDisabled}
                  phoneNumber={phoneNumber}
                  personId={personId}
                />
              ) : (
                <DemoCandidate360MailAndMessageComposeWindow
                  jobId={jobId}
                  jobTitle={jobTitle}
                  version={version}
                  jobCode={jobCode}
                  candidateContext={candidateContext}
                  getCandidate360MessageWindow={this.getCandidate360MessageWindow}
                  id="connect-message-send-button"
                  htmlType="submit"
                  isSendDisabled={isSendButtonDisabled}
                  handleSubmit={this.handleSubmit}
                  isModalVisible={isComposeMessageModalVisible}
                  setModalVisibility={setMessageComposeModalVisibility}
                  composeModalTitle={<FormattedMessage {...message.composeTextButton} />}
                  candidate360MessageComposeSectionPayload={candidate360MessageComposeSectionPayload}
                  context="message"
                  onCloseEmailComposer={onCloseMessageComposer}
                  readChat={this.onReadPhoneNumberSMS}
                  fetchMessages={fetchMessages}
                  currentValues={currentValues}
                  conversationId={conversationId}
                  setPhoneNumber={eventCallbacks.onChangePhone}
                  handleCandidateResponse={this.handleCandidateResponse}
                  selectedPhoneNumberMessages={selectedPhoneNumberMessages}
                  getReportConversationDetails={getReportConversationDetails}
                  isComposeMessageDisabled={isComposeMessageDisabled}
                  phoneNumber={phoneNumber}
                  personId={personId}
                />
              )}
            </>
          ) : null}
        </Form>
      </>
    );
  }
}
export default injectIntl(Form.create()(ConnectMessageWindow));
export { ConnectMessageWindow as ConnectMessageWindowWithoutForm };

import { setApiStatus } from '../ApiStatusActions';

const SET_DISTRIBUTE_JOB_API_STATUS = 'SET_DISTRIBUTE_JOB_API_STATUS';
const SET_JOB_DISTRIBUTION_STATUS = 'SET_JOB_DISTRIBUTION_STATUS';
const ADD_MUST_HAVES = 'ADD_MUST_HAVES';
const SET_MUST_HAVES_FOR_JOB = 'SET_MUST_HAVES_FOR_JOB';
const SET_JOB_DETAILS = 'SET_JOB_DETAILS';
const SET_UNDO_JOB_AUTO_REJECT_API_STATUS = 'SET_UNDO_JOB_AUTO_REJECT_API_STATUS';
const SET_UPDATE_JOB_INTELLIGENCE_STATUS = 'SET_UPDATE_JOB_INTELLIGENCE_STATUS';
const SET_JOB_REACTIVATION_API_STATUS = 'SET_JOB_REACTIVATION_API_STATUS';
const SET_EDIT_SEGMENT_API_STATUS = 'SET_EDIT_SEGMENT_API_STATUS';
export const SET_ADVANCED_JOB_FORM_DATA = 'SET_ADVANCED_JOB_FORM_DATA';
export const SET_CREATE_SEGMENT_API_STATUS = 'SET_CREATE_SEGMENT_API_STATUS';
export const UPDATE_FAVOURITE_AGGREGATION = 'UPDATE_FAVOURITE_AGGREGATION';
export const SET_SHOW_CURRENT_USER_CANDIDATE_VIEWED_FLAG = 'SET_SHOW_CURRENT_USER_CANDIDATE_VIEWED_FLAG';
export const SET_MINIMIZE_MANUAL_SEARCH_STATUS = 'SET_MINIMIZE_MANUAL_SEARCH_STATUS';
export const SET_JOBS_BASIC_INFO_LIST = 'SET_JOBS_BASIC_INFO_LIST';
export const GET_BOOST_STOP_INFO_API_STATUS = 'GET_BOOST_STOP_INFO_API_STATUS';
export const SET_BOOST_STOP_INFO = 'SET_BOOST_STOP_INFO';
export const SET_BOT_CONFIG_DRAWER_VISIBILITY_TIME = 'SET_BOT_CONFIG_DRAWER_VISIBILITY_TIME';

const setUndoJobAutoRejectApiStatus = status => ({
  type: SET_UNDO_JOB_AUTO_REJECT_API_STATUS,
  payload: {
    status,
  },
});
const setDistributeJobApiStatus = (jobId, status) => ({
  type: SET_DISTRIBUTE_JOB_API_STATUS,
  payload: {
    jobId,
    status,
  },
});

const setJobDistributionStatus = (jobId, statuses) => ({
  type: SET_JOB_DISTRIBUTION_STATUS,
  payload: {
    jobId,
    statuses,
  },
});

export const updateFavouriteAggregation = payload => {
  return {
    type: UPDATE_FAVOURITE_AGGREGATION,
    payload,
  };
};

const addMustHavesToJob = (jobId, mustHaves) => ({
  type: ADD_MUST_HAVES,
  payload: {
    jobId,
    mustHaves,
  },
});

const setMustHavesForJob = (jobId, mustHaves) => ({
  type: SET_MUST_HAVES_FOR_JOB,
  payload: {
    jobId,
    mustHaves,
  },
});

const setJobDetails = jobDetails => ({
  type: SET_JOB_DETAILS,
  payload: jobDetails,
});

const setUpdateJobIntelligenceApiStatus = status => ({
  type: SET_UPDATE_JOB_INTELLIGENCE_STATUS,
  payload: status,
});

const setJobReactivationApiStatus = status => ({
  type: SET_JOB_REACTIVATION_API_STATUS,
  payload: status,
});

const setAdvancedJobData = data => ({
  type: SET_ADVANCED_JOB_FORM_DATA,
  payload: data,
});

const clearSegmentList = () => ({
  type: 'CLEAR_SEGMENT_LIST',
  payload: {
    statusId: undefined,
    status: undefined,
    totalCount: 0,
    jobs: [],
  },
});

const setCreateSegmentApiStatus = status => setApiStatus({ apiName: 'createSegmentApiStatus', status });

const setPutEditSegmentApiStatus = status => setApiStatus({ apiName: 'putEditSegmentApiStatus', status });

const setShowCurrentUserCandidateViewedFlag = (candidateViewStausFlag, jobId) => ({
  type: SET_SHOW_CURRENT_USER_CANDIDATE_VIEWED_FLAG,
  payload: { candidateViewStausFlag, jobId },
});

const updateSourcingStats = ({ jobId, status, bucketCount }) => ({
  type: 'UPDATE_SOURCING_STATS_COUNT',
  payload: { status, jobId, bucketCount },
});

const setMinimizeManualSearchAction = (jobId, status) => {
  return {
    type: SET_MINIMIZE_MANUAL_SEARCH_STATUS,
    payload: { jobId, status },
  };
};

const setJobsBasicInfoList = jobs => ({
  type: SET_JOBS_BASIC_INFO_LIST,
  payload: jobs,
});

const setBoostStopJobInfo = (jobId, payload) => ({
  type: 'SET_BOOST_STOP_INFO',
  payload: {
    jobId,
    data: payload,
  },
});

const setBotConfigDrawerVisibilityTime = payload => ({
  type: SET_BOT_CONFIG_DRAWER_VISIBILITY_TIME,
  payload,
});

export {
  SET_DISTRIBUTE_JOB_API_STATUS,
  SET_JOB_DISTRIBUTION_STATUS,
  ADD_MUST_HAVES,
  SET_MUST_HAVES_FOR_JOB,
  SET_JOB_DETAILS,
  SET_UNDO_JOB_AUTO_REJECT_API_STATUS,
  SET_UPDATE_JOB_INTELLIGENCE_STATUS,
  SET_JOB_REACTIVATION_API_STATUS,
  SET_EDIT_SEGMENT_API_STATUS,
  setUndoJobAutoRejectApiStatus,
  setJobDistributionStatus,
  setDistributeJobApiStatus,
  addMustHavesToJob,
  setMustHavesForJob,
  setJobDetails,
  setUpdateJobIntelligenceApiStatus,
  setJobReactivationApiStatus,
  setAdvancedJobData,
  setCreateSegmentApiStatus,
  setPutEditSegmentApiStatus,
  setShowCurrentUserCandidateViewedFlag,
  updateSourcingStats,
  clearSegmentList,
  setMinimizeManualSearchAction,
  setJobsBasicInfoList,
  setBoostStopJobInfo,
  setBotConfigDrawerVisibilityTime,
};

import React from 'react';
import { Button } from 'antd';
import _ from 'lodash';
import { getCountableNounForm } from '../../Utils/TextUtils';
import { OrderViewCancelIcon } from '../../Icons/AryaIcons';
import './OrderCancellationComponent.scss';

function OrderCancellationComponent(props) {
  const { invoiceNumber, candidateCount, showCancelConfirmation, viewCandidates, updateOrderApiStatus } = props;
  const candidatesText = getCountableNounForm('candidate', 'candidates', candidateCount);
  return (
    <div className="order-cancellation-wrapper">
      <div className="order-cancellation-candidate-count">
        {`Leoforce found ${candidateCount} ${candidatesText} for you!`}
      </div>
      <div className="order-cancellation-message">
        {`However, if this is not a satisfactory number of candidates, you can cancel your order ID ${invoiceNumber} at this time without charge.`}
      </div>
      <div className="order-cancellation-warning">No refunds if you accept.</div>
      <div className="cancel-view-order-icon-wrapper">
        <OrderViewCancelIcon className="cancel-view-order-icon" />
      </div>
      <div className="order-cancellation-buttons">
        <Button className="order-cancellation-cancel-button" shape="round" onClick={showCancelConfirmation}>
          Cancel Order
        </Button>
        <Button
          className="order-cancellation-view-button"
          shape="round"
          type="primary"
          onClick={viewCandidates}
          loading={_.get(updateOrderApiStatus, 'status', '') === 'INPROGRESS'}
        >
          View
        </Button>
      </div>
    </div>
  );
}

export default OrderCancellationComponent;

import { Avatar, Divider, Tag } from 'antd';
import React, { forwardRef } from 'react';
import { getConfiguration } from '../Collaborators/Collaborators';
import styles from './ThreadReplyItem.module.scss';
import TimeAgo from './TimeAgoComponent';
import HtmlViewer from '../HtmlViewer/HtmlViewer';

function ThreadReplyItem(props, ref) {
  const { threadReply } = props;
  const {
    Body: { Content: replyMessage },
    CreatedTime: repliedAt,
    From: { User: replyUserDetails, Role: userRole },
  } = threadReply;
  const replyInfo = {
    displayName: replyUserDetails.DisplayName,
    postedAt: repliedAt,
    content: replyMessage,
  };
  const isAryaRecruiter = userRole?.Name === 'SupportAdmin';
  const configuration = getConfiguration(replyUserDetails.DisplayName);
  return (
    <div className={styles.replyItem} ref={ref}>
      <div className={styles.avatarSection}>
        <Avatar style={{ color: '#ffffff', backgroundColor: configuration.color }}>{configuration.text} </Avatar>{' '}
        <Divider className={styles.verticalDivider} type="vertical" />
      </div>
      <div className={styles.contentSection}>
        <div className={styles.userDetails}>
          <span className={styles.displayName}>{replyInfo.displayName}</span>
          <TimeAgo createdTime={replyInfo.postedAt} />
          {isAryaRecruiter && <Tag className={styles.aryaRecruiterTag}>Leoforce Recruiter</Tag>}
        </div>
        <div className={styles.replyContent}>
          <HtmlViewer
            forbidTags={['style']}
            htmlContent={replyInfo?.content && replyInfo?.content.replace(/\n/gi, '<br />')}
          />
        </div>
      </div>
    </div>
  );
}

export default forwardRef(ThreadReplyItem);

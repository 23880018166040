import React from 'react';
import { Select, Icon, Button, Skeleton } from 'antd';
import _ from 'lodash';
import classnames from 'classnames';
import moment from 'moment';
import ChatPane from '../ChatPane/ChatPane';
import './MessagePopup.scss';
import { formatTextMessage } from '../../../Utils/TextUtils';
import ReportInvalidIncorrectButton from '../ReportInvalidPhone/ReportInvalidPhoneButton';
import InvalidPhoneButton from '../ReportInvalidPhone/InvalidPhoneButton';
import { getLocaleDateStringWithTimeStamp } from '../../../Utils/RelativeTimeCalculator';

import UnsubscribedButton from '../../UnsubscribeCandidate/UnsubscribedButton';
import UnsubscribeCandidateButton from '../../UnsubscribeCandidate/UnsubsCribeCandidateButton';
import { checkForJunkWordsInText, parseTextForJunkDetection } from '../../Utils/SmsUtils';
import SpamAlertText from '../../Common/SpamTextAlert/SpamTextAlert';
import RichTextEditor from '../../Editor/RichTextEditor';

const { Option } = Select;
const { MessageBubble } = ChatPane;

const debouncedCheckForJunkWordsInText = _.debounce((plainTextContent, setter, junkWordsSetter) => {
  const { hasJunkWords, junkWords } = checkForJunkWordsInText(plainTextContent);
  setter(hasJunkWords);
  junkWordsSetter(junkWords);
}, 600);

//! TODO: move to util? Or a component?
function getConsentStatusInfoBanner(
  messageConsentStatus,
  candidateName,
  selectedPhone,
  connectInfo,
  userByGuId,
  consentInfo,
  jobDetails,
  orgPhoneConsentInfo
) {
  const nameParts = (candidateName || '').split(' ');
  const firstName = nameParts && nameParts.length > 0 ? nameParts[0] : '';
  const subscription = connectInfo?.Contact?.Subscription;
  const selectedPhoneNumberDetails = orgPhoneConsentInfo?.[selectedPhone];

  if (
    selectedPhoneNumberDetails?.ConsentStatus === 'Denied' &&
    selectedPhoneNumberDetails?.IsOrgLevel &&
    selectedPhoneNumberDetails?.UpdatedTime
  ) {
    const updatedTime = moment.utc(selectedPhoneNumberDetails.UpdatedTime).local().format('DD-MMM-YYYY hh:mm A');
    if (!jobDetails.JobTitle) return <Skeleton loading active paragraph={{ rows: 1, width: '20' }}></Skeleton>;
    return (
      <div className="consent-status-info-message consent-denied">
        <Icon type="close-circle" theme="filled" />
        <span>{`Looks like ${candidateName} has unsubscribed to receive further TEXT from ${updatedTime} as per the outreach made for ${jobDetails?.JobTitle} - ${jobDetails?.JobId}.`}</span>{' '}
      </div>
    );
  }

  if (subscription?.Status?.toLowerCase() === 'unsubscribed') {
    const { UpdatedTime, Remarks: remarks } = subscription;
    const createdByName = userByGuId[subscription.CreatedBy]?.FirstName;
    return (
      <div className="consent-status-info-message consent-denied">
        <Icon type="info-circle" theme="filled" />
        <span>{`You won't be able to communicate with ${candidateName} as ${createdByName} has marked the candidate as unsubscribed with reason “ ${remarks} ” on ${getLocaleDateStringWithTimeStamp(
          UpdatedTime
        )}`}</span>
      </div>
    );
  }
  if (!messageConsentStatus) {
    return (
      <div className="consent-status-info-message">
        <Icon type="info-circle" theme="filled" />
        <span>{`A consent text will be sent with your first message. You must receive ${firstName}'s consent before sending further texts.`}</span>
      </div>
    );
  }
  // if (messageConsentStatus === 'Denied' && consentInfo?.IsOrgLevel) {
  //   const updatedTime = moment.utc(consentInfo?.UpdatedTime).local().format('DD-MMM-YYYY hh:mm A');
  //   if (!jobDetails.JobTitle) return <Skeleton loading active paragraph={{ rows: 1, width: '20' }}></Skeleton>;
  //   return (
  //     <div className="consent-status-info-message consent-denied">
  //       <Icon type="close-circle" theme="filled" />
  //       <span>{`Looks like ${candidateName} has unsubscribed to receive further TEXT from ${updatedTime} as per the outreach made for ${jobDetails?.JobTitle} - ${jobDetails?.JobId}.`}</span>{' '}
  //     </div>
  //   );
  // }
  if (messageConsentStatus === 'Denied' && !consentInfo?.IsOrgLevel) {
    // if (!jobDetails.JobTitle) return <Skeleton loading active paragraph={{ rows: 1, width: '20' }}></Skeleton>;
    return (
      <div className="consent-status-info-message consent-denied">
        <Icon type="close-circle" theme="filled" />
        <span>{`We're sorry, but ${firstName} has declined consent. You may no longer communicate on this number via Leoforce.`}</span>
      </div>
    );
  }
  if (messageConsentStatus === 'Pending') {
    return (
      <div className="consent-status-info-message consent-pending">
        <Icon type="clock-circle" theme="filled" />
        <span>{`Consent is pending. Communication will be enabled when ${firstName} gives consent.`}</span>
      </div>
    );
  }
  if (selectedPhone?.IsUnsubscribeByUser) {
    return (
      <div className="consent-status-info-message consent-denied">
        <Icon type="close-circle" theme="filled" />
        <span>Recruiter has unsubscribed {selectedPhone.Number}</span>
      </div>
    );
  }
  return null;
}

export default class MessagePopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = { messageText: '', isCandidateUnsubscribed: false, isJunkWordFound: false, junkWords: [] };
    this.onSend = this.onSend.bind(this);
    this.updateIsCandidateUnsubscribed = this.updateIsCandidateUnsubscribed.bind(this);
    this.setIsCandidateUnsubscribed = this.setIsCandidateUnsubscribed.bind(this);
    this.myRef = React.createRef();
  }

  componentDidMount() {
    this.updateIsCandidateUnsubscribed();
  }

  componentDidUpdate(prevProps) {
    if (this.props.subscription !== prevProps.subscription) {
      this.updateIsCandidateUnsubscribed();
    }
  }

  setIsJunkWordFound = value => {
    this.setState({ isJunkWordFound: value });
  };

  setJunkWordsFound = value => {
    this.setState({ junkWords: value });
  };

  updateIsCandidateUnsubscribed() {
    const { subscription } = this.props;
    if (subscription) {
      this.setState({
        isCandidateUnsubscribed: subscription?.toLowerCase() === 'unsubscribed',
      });
    }
  }

  setIsCandidateUnsubscribed(value) {
    this.setState({ isCandidateUnsubscribed: value });
  }

  onSend(e) {
    e.preventDefault();
    const { sendMessage, selectedPhone } = this.props;
    const text = _.trimEnd(this.myRef.current.quillRef.getText().trim(), '\n');
    sendMessage(text, selectedPhone);
    this.setState({ messageText: '' });
  }

  onUpdateMessageContent = ({ htmlContent }) => {
    this.setState({
      messageText: htmlContent,
    });
    debouncedCheckForJunkWordsInText(
      parseTextForJunkDetection(htmlContent),
      this.setIsJunkWordFound,
      this.setJunkWordsFound
    );
  };

  render() {
    const {
      className,
      onClose,
      conversationId,
      messages,
      personDetails,
      messageType,
      connectInfo,
      consentStatus,
      phones,
      onChangePhone,
      selectedPhone,
      headerSubText,
      personId,
      featureToggleList,
      connectApiStatus,
      userByGuId,
      history,
      notificationPayload,
      saveRecentReadNotificationInfo,
      consentInfo,
      jobDetails,
      orgPhoneConsentInfo,
    } = this.props;
    const { messageText, isCandidateUnsubscribed, isJunkWordFound, junkWords } = this.state;

    const isSendMessageAllowed = (messageType === 'Chat' || consentStatus === 'Approved') && !isCandidateUnsubscribed;
    const candidate = { ConversationId: conversationId, PersonId: personId, connectInfo };
    const isUnsubscribeCandidateEnabled = featureToggleList?.UnsubscribeCandidate.IsEnabled;
    let unsubscribeCandidateButton = null;
    const isCurrentSelectedPhoneNumberInvalid = phones?.find(
      phone =>
        phone.Number === selectedPhone && phone.ContactMismatchInfo && phone.ContactMismatchInfo?.IsMismatch === true
    );
    if (connectApiStatus === 'COMPLETED') {
      unsubscribeCandidateButton = isCandidateUnsubscribed ? (
        <UnsubscribedButton candidate={candidate} />
      ) : (
        <UnsubscribeCandidateButton
          setIsCandidateUnsubscribed={this.setIsCandidateUnsubscribed}
          candidate={candidate}
        />
      );
    }

    return (
      <div className={`popup-container ${className}`}>
        <div className="popup-header">
          <div className="popup-name-close">
            <div className="popup-name-wrapper">
              <div
                className="popup-name"
                onClick={() => {
                  if (messageType === 'chat') history.push('/connect?activeTab=bot');
                  else history.push('/connect?activeTab=message');
                  saveRecentReadNotificationInfo('Connect', notificationPayload);
                }}
              >
                {personDetails.Name}
              </div>

              <div className="popup-job-details">{headerSubText}</div>
            </div>
            {isUnsubscribeCandidateEnabled && (
              <span
                style={{
                  transform: 'scale(0.75)',
                  marginLeft: '0px !important',
                  display: 'flex',
                  maxHeight: '30px',
                  minWidth: 'fit-content',
                  maxWidth: '155px',
                  position: 'absolute',
                  right: '10px',
                  top: '5px',
                }}
              >
                {unsubscribeCandidateButton}
              </span>
            )}
            <div className="popup-close">
              <Icon type="close" onClick={() => onClose(conversationId)} />
            </div>
          </div>
          {messageType === 'SMS' ? (
            <div className="popup-phones">
              <Select
                dropdownClassName="popup-message-dropdown"
                defaultValue={selectedPhone}
                className={classnames(
                  `phone-select ${isCurrentSelectedPhoneNumberInvalid ? 'phone-select-invalid' : ''}`
                )}
                value={selectedPhone}
                onChange={onChangePhone}
              >
                {phones.map(phone => {
                  const invalidReason = phone?.ContactMismatchInfo?.MismatchReason;
                  return (
                    <Option key={phone.Id} value={phone.Number}>
                      <span className={invalidReason ? 'lineForInvalidContact' : ''}>{phone.Number}</span>
                    </Option>
                  );
                })}
              </Select>
              {isCurrentSelectedPhoneNumberInvalid ? (
                <InvalidPhoneButton
                  isCurrentSelectedPhoneNumberInvalid={isCurrentSelectedPhoneNumberInvalid}
                  userByGuId={userByGuId}
                />
              ) : (
                <ReportInvalidIncorrectButton candidate={candidate} selectedPhone={selectedPhone} />
              )}
            </div>
          ) : null}
        </div>
        <div className="popup-body">
          <ChatPane className="popup-chat-pane">
            {messages.map(message =>
              message.Body ? (
                <MessageBubble
                  key={message.Id}
                  message={formatTextMessage(message.Body)}
                  align={message.IsByPerson ? 'left' : 'right'}
                  timestamp={message.CreatedTime}
                  status={message.Status}
                  CreatedByName={message.CreatedByName || personDetails.Name}
                  personDetails={personDetails}
                  isByBot={message?.IsByBot}
                />
              ) : null
            )}
          </ChatPane>
        </div>
        <div className="popup-footer">
          {isSendMessageAllowed ? (
            <div
              className={classnames('popup-chat-textarea', { 'popup-chat-textarea-junk-text': isJunkWordFound })}
              style={isJunkWordFound ? { display: 'flex', flexDirection: 'column' } : {}}
            >
              <RichTextEditor
                className="message-editor"
                editorContent={{
                  htmlContent: messageText,
                }}
                placeholder="Type a message"
                onChange={this.onUpdateMessageContent}
                ref={this.myRef}
                showToolbar={false}
                editorContext="Message"
              />
              <div
                style={
                  isJunkWordFound
                    ? { width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }
                    : {}
                }
              >
                {isJunkWordFound ? (
                  <SpamAlertText
                    style={{ paddingLeft: '15px' }}
                    alertStyle={{ width: '285px' }}
                    junkWords={junkWords}
                  />
                ) : null}
                <Button type="primary" id="popup-chat-send-button" onClick={this.onSend}>
                  <Icon type="arrow-right" />
                </Button>
              </div>
            </div>
          ) : (
            <>
              {connectInfo.Contact
                ? getConsentStatusInfoBanner(
                    consentStatus,
                    personDetails.Name,
                    selectedPhone,
                    connectInfo,
                    userByGuId,
                    consentInfo,
                    jobDetails,
                    orgPhoneConsentInfo
                  )
                : null}
            </>
          )}
        </div>
      </div>
    );
  }
}

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import _ from 'lodash';
import moment from 'moment';
import classnames from 'classnames';
import { Tooltip, Tag, Icon, Button, Form } from 'antd';
import { getHighlightedKeyWords, getCleanedHighlights, highlightKeyWords } from '../../Utils/KeywordHighlightUtil';
import { getMatchingJobCodeText, getCandidateJobMatchingLocation } from '../../Utils/CandidateJobMatchingUtils';
import { getInternalSourceWithCandidateId, getPushedAtsSource } from '../../Utils/SourceUtils';
import styles from './JobMatchingListItem.module.scss';
import { getFeatureToggleList } from '../../Reducers/FeatureToggleReducer.ts';
import message from '../../Containers/CandidateDrawer/messages';

const emptyObject = {};

const mapStateToProps = state => {
  return {
    featureToggleList: getFeatureToggleList(state),
  };
};
const MAX_SKILL_DISPLAY_COUNT = 20;

export const getJobMatchingSkillsEllipsedText = (processedSkills, candidateJobMatchingCardSkills) =>
  processedSkills.length - candidateJobMatchingCardSkills.length > 0 ? (
    <span className={styles.jobMatchingSkillsEllipsedText}>...</span>
  ) : null;

const JobMatchingModalListItem = props => {
  const {
    matchingJob,
    matchingJobHighlights = [],
    matchingJobDetails = emptyObject,
    candidate,
    recommendCandidate,
    sourceCandidateStatusUpdate,
    getMatchingJobs,
    version,
    countries = [],
    currentUser,
    openJobViewInNewTabCallBack,
    onClickAddJobCallBack,
    pageNumber,
    pageSize,
    candidateMatchingJobsTotalCount,
    setPageNumber,
    featureToggleList,
    intl,
  } = props;
  const { JobId: jobId } = matchingJob;
  const {
    MoveToAppliedInJobs: { IsEnabled: isMoveToAppliedInJobsEnabled },
  } = featureToggleList;
  useEffect(() => {
    let keywordsToHighlight;
    if (matchingJobHighlights.length >= MAX_SKILL_DISPLAY_COUNT) {
      keywordsToHighlight = matchingJobHighlights;
    } else {
      keywordsToHighlight = _.uniq([
        ...matchingJobHighlights,
        ...(candidate.Skills || []),
        ...(candidate.Keywords || []),
      ]);
      keywordsToHighlight = keywordsToHighlight?.slice(0, 400);
    }
    const domSelectors = [`div.jobSkillContent-${jobId}`];
    highlightKeyWords(domSelectors, keywordsToHighlight);
  }, []);

  const isJobPreferSkills = matchingJob.Derived?.Keywords?.Prefers?.length > 0;
  const jobPreferSkills = isJobPreferSkills ? matchingJob.Derived.Keywords.Prefers : [];
  let processedSkills = [];
  let unprocessedSkills = [];
  let highlightedSkillsLength;
  let matchingJobCodeText = '';
  if (version === 'ats') {
    matchingJobCodeText = getMatchingJobCodeText(matchingJob.JobCode);
  }

  const getAllJobKeywords = ({ mustHaves, niceToHaves, currentJobDetails }) => {
    const jobTitle = currentJobDetails.Title.Value;
    const jobSynonyms = currentJobDetails.Title.Synonyms;
    return [..._.flatten(mustHaves), ..._.flatten(niceToHaves), jobTitle || '', ...(jobSynonyms || [])];
  };
  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }
  function minMaxExp() {
    let minMaxExpText;
    if (matchingJob.MinExperience && matchingJob.MaxExperience) {
      minMaxExpText = (
        <span>
          {matchingJob.MinExperience} - {matchingJob.MaxExperience}{' '}
          <FormattedMessage {...message.yearsShortFormLabel} />
        </span>
      );
    } else if (matchingJob.MaxExperience) {
      minMaxExpText = (
        <span>
          <FormattedMessage {...message.uptoLabel} /> {matchingJob.MaxExperience}{' '}
          <FormattedMessage {...message.yearsShortFormLabel} />
        </span>
      );
    } else {
      minMaxExpText = (
        <span>
          {matchingJob.MinExperience}+ <FormattedMessage {...message.yearsShortFormLabel} />
        </span>
      );
    }
    return minMaxExpText;
  }

  const getRearrangedSkills = skills => {
    const jobKeywords = getAllJobKeywords({
      mustHaves: [], // Need to be Handled
      niceToHaves: jobPreferSkills,
      currentJobDetails: matchingJob,
    }).filter(onlyUnique);
    const keywordsToHighlight = _.uniq([...(skills || []), ...(candidate.Keywords || [])]);
    const { highlightedSkills: highlightedJobKeywords } = getHighlightedKeyWords(jobKeywords, keywordsToHighlight);
    const reorderedJobKeywords = _.uniq([...highlightedJobKeywords, ...jobKeywords]);
    return { reorderedJobKeywords, highlightedJobKeywords };
  };

  if (candidate.Skills) {
    const rearrangedSkills = getRearrangedSkills(candidate.Skills);
    unprocessedSkills = rearrangedSkills.reorderedJobKeywords;
    highlightedSkillsLength = rearrangedSkills.highlightedJobKeywords.length;
  } else {
    unprocessedSkills = getAllJobKeywords({
      mustHaves: [],
      niceToHaves: jobPreferSkills,
      currentJobDetails: matchingJob,
    });
  }

  processedSkills = _.uniq(matchingJobHighlights.concat(getCleanedHighlights(unprocessedSkills)));
  highlightedSkillsLength += matchingJobHighlights.length;
  const candidateJobMatchingCardSkills = processedSkills.slice(0, MAX_SKILL_DISPLAY_COUNT);
  const highlightedSkillList = candidateJobMatchingCardSkills.slice(0, highlightedSkillsLength);
  const nonHighlightedSkillList = candidateJobMatchingCardSkills.slice(highlightedSkillsLength);

  const highlightedSkillListTags = highlightedSkillList.map(skill => {
    const trimSkill = skill.trim();
    if (trimSkill) {
      return (
        <Tag className={styles.jobCardSkillTag} key={trimSkill}>
          {trimSkill}
        </Tag>
      );
    }
    return null;
  });
  const nonHighlightedSkillListTags = nonHighlightedSkillList.map(skill => {
    const trimSkill = skill.trim();
    if (trimSkill) {
      return (
        <Tag className={styles.jobCardSkillTag} key={trimSkill}>
          {trimSkill}
        </Tag>
      );
    }
    return null;
  });
  const fetchMatchingJobs = () => {
    const isLastPage = candidateMatchingJobsTotalCount - (pageNumber - 1) * pageSize < 2;
    getMatchingJobs(isLastPage ? pageNumber - 1 : pageNumber, pageSize, '', false);
    if (candidateMatchingJobsTotalCount - (pageNumber - 1) * pageSize < 2) setPageNumber(pageNumber - 1);
  };
  const onJobClick = async status => {
    let candidatePayLoad = { ...candidate, jobId: matchingJob.JobId, candidateId: candidate.Id };
    if (status === 'Shortlisted') {
      candidatePayLoad.status = 'Shortlisted';
    } else if (status === 'Applied') {
      candidatePayLoad = {
        ...candidatePayLoad,
        connectionStatus: 'Connected',
        candidateOrigin: 'Recruiter',
        addToApplied: true,
      };
    }
    if (version === 'ats' && onClickAddJobCallBack) {
      const candidateSources = candidate.Sources;
      const isAtsVersion = version === 'ats';
      const candidateSource = isAtsVersion
        ? getInternalSourceWithCandidateId(candidateSources)
        : getPushedAtsSource(candidateSources);
      onClickAddJobCallBack(matchingJob?.JobCode, candidateSource?.CandidateId, status);
    }
    await recommendCandidate(candidatePayLoad, 'segment');
    fetchMatchingJobs();
  };

  const handleOpenJob = () => {
    if (!matchingJob.IsJobAssignedToCurrentUser) return;
    if (version !== 'ats') {
      window.open(`/jobs/${matchingJob.JobId}`);
    } else if (version === 'ats' && matchingJob.JobCode?.trim().length > 0) {
      openJobViewInNewTabCallBack(matchingJob.JobCode);
    }
  };

  const jobCountryName = countries.find(country => country.Iso2Code === matchingJob.CountryCode)?.Name;

  const industryValue = matchingJob.Industries?.[0] ? (
    matchingJob.Industries.join(', ')
  ) : (
    <FormattedMessage {...message.notAvailableShortFormLabel} />
  );

  const jobMatchingCardIndustryField =
    version !== 'ats' ? (
      <div>
        <div>
          <FormattedMessage {...message.industryLabel} />: &nbsp;
        </div>
        <Tooltip title={industryValue}>{industryValue}</Tooltip>
      </div>
    ) : null;

  const matchingJobLocation = getCandidateJobMatchingLocation(matchingJob.Location, jobCountryName);

  const isAdminOrJobAssignedToCurrentUser =
    version === 'ats'
      ? matchingJob.IsJobAssignedToCurrentUser
      : matchingJob.IsJobAssignedToCurrentUser || currentUser?.IsAdmin;

  const jobMatchingCardLocationField = (
    <div>
      <div>
        <FormattedMessage {...message.locationLabel} />: &nbsp;
      </div>
      <Tooltip title={matchingJobLocation}>
        <span className={styles.jobMatchingLocation}>{matchingJobLocation}</span>
      </Tooltip>
    </div>
  );

  return (
    <div className={styles.jobCardWrapper}>
      <div className={styles.jobCard}>
        <div className={styles.jobCardInfo}>
          <div className={styles.jobCardContent}>
            <div className={styles.jobCardPrimaryContent} role="presentation">
              <Tooltip
                title={
                  !matchingJob.IsJobAssignedToCurrentUser
                    ? intl.formatMessage({ ...message.jobIsNotSharedWithYouLabel })
                    : ''
                }
                placement="topRight"
              >
                <div
                  role="button"
                  tabIndex={0}
                  onClick={handleOpenJob}
                  onKeyPress={handleOpenJob}
                  style={!matchingJob.IsJobAssignedToCurrentUser ? { cursor: 'not-allowed' } : {}}
                >
                  <div className={styles.jobCardPersonalInfo}>
                    <div className={styles.jobCardCandidateName}>
                      <Tooltip title={<div>{matchingJob.Title.Value}</div>}>
                        <span className={styles.matchingJobTitle}>{matchingJob.Title.Value} </span>
                      </Tooltip>
                      <span>|</span>
                      <Tooltip title={<div>{matchingJob.Client}</div>}>
                        <span> {matchingJob.Client}</span>
                      </Tooltip>
                    </div>
                  </div>
                  <div className={styles.jobCardTitle}>
                    <Tooltip title={matchingJob.Modifieddate}>
                      <div className={styles.jobCardLastUpdatedinfo}>
                        <span>
                          <FormattedMessage {...message.idLabel} />:
                        </span>
                        &nbsp;{matchingJob.JobId}&nbsp;|{matchingJobCodeText}&nbsp;
                        <FormattedMessage {...message.jobMatchingLastUpdatedLabel} /> :&nbsp;
                        {moment.utc(matchingJob.ModifiedDate).local().format('MMM DD, YYYY')}
                      </div>
                    </Tooltip>
                  </div>
                </div>
              </Tooltip>
            </div>
          </div>
          <div className={styles.features}>
            <div className={styles.experience}>
              <div>
                <div>
                  <FormattedMessage {...message.experienceLabel} />: &nbsp;
                </div>
                <Tooltip
                  title={
                    matchingJob.MaxExperience || matchingJob.MinExperience ? (
                      minMaxExp()
                    ) : (
                      <FormattedMessage {...message.notAvailableShortFormLabel} />
                    )
                  }
                >
                  {matchingJob.MaxExperience || matchingJob.MinExperience ? (
                    minMaxExp()
                  ) : (
                    <FormattedMessage {...message.notAvailableShortFormLabel} />
                  )}
                </Tooltip>
              </div>
              {jobMatchingCardIndustryField}
              {jobMatchingCardLocationField}
            </div>
            <div className={styles.jobCardSecondaryContent}>
              <div className={styles.jobCardSkills}>
                <Tooltip title={(processedSkills || []).join(', ')}>
                  <div className={`jobCardSkillsContent jobSkillContent-${jobId}`}>
                    {highlightedSkillListTags}
                    {nonHighlightedSkillListTags}
                    {getJobMatchingSkillsEllipsedText(processedSkills, candidateJobMatchingCardSkills)}
                  </div>
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.shortlistedAppliedLinks}>
          <Tooltip
            title={
              !matchingJob.IsJobAssignedToCurrentUser
                ? intl.formatMessage({ ...message.jobIsNotSharedWithYouLabel })
                : ''
            }
            placement="topRight"
          >
            <div>
              <div
                className={classnames(styles.jobCardCandidateSelect, {
                  [styles.disabled]: !isAdminOrJobAssignedToCurrentUser,
                })}
                key={_.get(matchingJob, 'JobId')}
                onClick={isAdminOrJobAssignedToCurrentUser ? () => onJobClick('Shortlisted') : null}
                onKeyPress={isAdminOrJobAssignedToCurrentUser ? () => onJobClick('Shortlisted') : null}
              >
                {sourceCandidateStatusUpdate?.[candidate.Id]?.[matchingJob.JobId]?.Shortlisted === 'INPROGRESS' ? (
                  <div key="loader" className={styles.addButtonLoaderIconDiv}>
                    <Icon className={styles.addButtonLoaderIcon} type="loading" spin />
                  </div>
                ) : (
                  <Button
                    type="link"
                    size="small"
                    style={{ textDecoration: 'underline' }}
                    disabled={
                      !matchingJob.IsJobAssignedToCurrentUser ||
                      matchingJobDetails.RecommendationStatus === 'Shortlisted'
                    }
                  >
                    <p>
                      {matchingJobDetails.RecommendationStatus === 'Shortlisted' ? (
                        <FormattedMessage {...message.addedLabel} />
                      ) : (
                        <FormattedMessage {...message.addLabel} />
                      )}{' '}
                      <FormattedMessage {...message.toShortlistedLabel} />
                    </p>
                  </Button>
                )}
              </div>
            </div>
          </Tooltip>
          {isMoveToAppliedInJobsEnabled ? (
            <Tooltip
              title={
                !matchingJob.IsJobAssignedToCurrentUser
                  ? intl.formatMessage({ ...message.jobIsNotSharedWithYouLabel })
                  : ''
              }
            >
              <div>
                <div
                  className={classnames(styles.jobCardCandidateSelect, {
                    [styles.disabled]: !isAdminOrJobAssignedToCurrentUser,
                  })}
                  key={_.get(matchingJob, 'JobId')}
                  onClick={isAdminOrJobAssignedToCurrentUser ? () => onJobClick('Applied') : null}
                  onKeyPress={isAdminOrJobAssignedToCurrentUser ? () => onJobClick('Applied') : null}
                >
                  {sourceCandidateStatusUpdate?.[candidate.Id]?.[matchingJob.JobId]?.Connected === 'INPROGRESS' ? (
                    <div key="loader" className={styles.addButtonLoaderIconDiv}>
                      <Icon className={styles.addButtonLoaderIcon} type="loading" spin />
                    </div>
                  ) : (
                    <Button
                      type="link"
                      className={styles.jobMatchingShortlistedButton}
                      size="small"
                      style={{ textDecoration: 'underline' }}
                      disabled={!matchingJob.IsJobAssignedToCurrentUser}
                    >
                      <p>
                        <FormattedMessage {...message.addToAppliedLabel} />
                      </p>
                    </Button>
                  )}
                </div>
              </div>
            </Tooltip>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, null)(injectIntl(Form.create()(JobMatchingModalListItem)));
export { JobMatchingModalListItem as JobMatchingModalListItemWithoutStore };

import { Avatar, Button, Tag } from 'antd';
import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import styles from './NewPostComponent.module.scss';
import { trimHtmlContent } from '../../Utils/DomParserUtils';
// eslint-disable-next-line import/imports-first
import 'react-quill/dist/quill.snow.css';
import { CreateNewThreadButtonIcon } from '../../Icons/SendReplyButtonIcon';

function NewPostComponent(props) {
  const { addThreadToTheChat, chatId, topicId, closeNewPost, isChatDisabled, currentMember } = props;
  const { avatarConfiguration, isAryaRecruiter, userGuid, displayName } = currentMember;
  const [inputMessage, setInputMessage] = useState('');
  const quillRef = React.useRef(null);
  const quill = quillRef?.current?.getEditor();

  const onCreateNewThreadButtonClick = () => {
    const unformattedText = quill.getText().trim();
    const formattedLength = quill.getLength();
    const sanitizedMessage = trimHtmlContent(inputMessage);
    if (unformattedText.length === 0 || formattedLength <= 1) {
      return;
    }
    addThreadToTheChat({ chatId, topicId, userGuid, messageContent: sanitizedMessage, isThread: true });
    closeNewPost();
  };
  const onTextAreaKeyPress = e => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      onCreateNewThreadButtonClick();
    }
  };
  const formats = ['bold', 'italic', 'underline', 'list', 'bullet'];
  return (
    <div className={styles.newPost}>
      <div className={styles.userDetails}>
        <Avatar style={{ color: '#ffffff', backgroundColor: avatarConfiguration.color }}>
          {avatarConfiguration.text}{' '}
        </Avatar>
        <span className={styles.userName}>{displayName}</span>
        {isAryaRecruiter && <Tag className={styles.aryaRecruiterTag}>Leoforce Recruiter</Tag>}
      </div>
      <div className={styles.inputSection}>
        <ReactQuill
          ref={quillRef}
          value={inputMessage}
          onChange={setInputMessage}
          onKeyDown={onTextAreaKeyPress}
          placeholder="Start typing here..."
          readOnly={isChatDisabled}
          theme="snow"
          className={styles.richTextEditor}
          formats={formats}
          modules={{ toolbar: false }}
        />
        <Button
          type="primary"
          className={styles.createNewThreadButton}
          onClick={() => {
            onCreateNewThreadButtonClick();
          }}
          disabled={isChatDisabled || quill?.getText().trim().length === 0}
        >
          <CreateNewThreadButtonIcon />
        </Button>
      </div>
    </div>
  );
}

export default NewPostComponent;

import React, { useState } from 'react';
import { Tabs, Badge, Icon } from 'antd';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import InfiniteScroll from 'react-infinite-scroller';
import NetworkIssueErrorBoundary from '../../ErrorBoundary/NetworkIssue/NetworkIssue';
import './ContentPaneBody.scss';
import MessageWindowContainer from '../../../Containers/MessageWindow/MessageWindow';
import MailWindowContainer from '../../../Containers/MailWindow/MailWindow';
import CallWindowContainer from '../../../Containers/CallWindow/CallWindow';
import ChatWindowContainer from '../../../Containers/ChatWindow/ChatWindow';
import jobMessage from '../../JobForm/JobMessages';

const { TabPane } = Tabs;

const ContentPaneBody = ({
  jobId,
  jobGuid,
  candidate,
  availableProviders,
  smsCount,
  chatCount,
  showBotConfigDrawer,
  isBotEnabled,
  FromEmailAddress,
  jobCountryCode,
  currentJobDetails,
  version,
  openSipCallWindowsApp,
  isComposeEmailModalVisible,
  setEmailComposeModalVisibility,
  isComposeMessageModalVisible,
  setMessageComposeModalVisibility,
  onConnectContentTabClick,
  activeConnectContentTab,
  connectMessageRef,
  connectALLMessageRef,
  connectMailRef,
  connectAllMailRef,
  unsubscribeCandidateButton,
  isWorkflowWindowEnabled,
  featureToggleList,
  candidateContext = 'job',
  currentSelectedEmail,
  setCurrentSelectedEmail,
  isComposeWorkflowModalVisible,
  setWorkflowComposeModalVisibility,
  workflowSelectedEmails,
  setWorkflowSelectedEmails,
  isUnsubscribeCandidateEnabled,
  callConversations,
  fetchEmailTemplates,
  fetchMails,
  allContextualMailCount,
  contextualMailCount,
  contextualMailsConversationApiStatus,
  allContextualMailsConversationApiStatus,
  fetchCustomConsentText,
  openCandidateView,
  openJobViewInNewTabCallBack
}) => {
  const messageTabPaneName = (
    <Badge dot={smsCount}>
      <FormattedMessage {...jobMessage.textLabel} />
    </Badge>
  );
  const allMessageTabPaneName = (
    <Badge dot={smsCount}>
      <FormattedMessage {...jobMessage.allSMSLabel} />
    </Badge>
  );
  const emailTabPaneName = (
    <Badge>
      <FormattedMessage {...jobMessage.emailLabel} />
    </Badge>
  );
  const allEmailTabPaneName = (
    <Badge>
      <FormattedMessage {...jobMessage.allEmailLabel} />
    </Badge>
  );
  const callTabPaneName = (
    <Badge>
      <FormattedMessage {...jobMessage.phoneLabel} />
    </Badge>
  );
  const botTabPaneName = (
    <Badge dot={chatCount}>
      <FormattedMessage {...jobMessage.aryaBotLabel} />
    </Badge>
  );
  const WorkflowTabPaneName = (
    <Badge>
      <FormattedMessage {...jobMessage.workflowLabel} />
    </Badge>
  );

  const {
    AllConversations: { IsEnabled: isAllConversationsEnabled },
  } = featureToggleList;

  const [currentPage, setCurrentPage] = useState(0);
  const mailCount = contextualMailCount(candidate.ConversationId);
  const allMailCount = allContextualMailCount(candidate.PersonId);
  const [messageTab, setMessageTab] = React.useState('');

  React.useEffect(() => {
    if (activeConnectContentTab && (activeConnectContentTab === 'workflow' || activeConnectContentTab === 'email')) {
      const excludeTags = [];
      if (activeConnectContentTab === 'workflow') excludeTags.push('Drip');
      else excludeTags.push('workflow');
      fetchEmailTemplates(0, 100, true, 'en-US', excludeTags);
    }
  }, [activeConnectContentTab]);

  React.useEffect(() => {
    fetchCustomConsentText(jobCountryCode);
  }, [jobCountryCode]);

  return (
    <div className={classNames('connectTabs', 'connectTabs360View')}>
      <Tabs
        defaultActiveKey={FromEmailAddress ? 'email' : ''}
        tabBarStyle={{ marginBottom: '0px' }}
        className="connect-icons-tab"
        onChange={onConnectContentTabClick}
        activeKey={activeConnectContentTab}
      >
        <TabPane tab={emailTabPaneName} key="email">
          <div className="master-profile-tab-content">
            <InfiniteScroll
              useWindow={false}
              initialLoad={false}
              loadMore={page => {
                fetchMails(candidate.ConversationId || null, null, null, null, page * 10, 10);
                setCurrentPage(page);
              }}
              loader={
                <div key="loader" style={{ width: '100%', textAlign: 'center' }}>
                  <Icon style={{ fontSize: '20px' }} type="loading" spin />
                </div>
              }
              threshold={50}
              hasMore={(currentPage + 1) * 10 < mailCount}
            >
              {candidate ? (
                <NetworkIssueErrorBoundary>
                  <MailWindowContainer
                    jobId={jobId}
                    conversationId={candidate.ConversationId}
                    personId={null}
                    candidate={candidate}
                    availableProviders={availableProviders}
                    timeLineClassName="connect-window-display-drip-template"
                    FromEmailAddress={FromEmailAddress}
                    currentJobDetails={currentJobDetails}
                    version={version}
                    isComposeEmailModalVisible={isComposeEmailModalVisible}
                    setEmailComposeModalVisibility={setEmailComposeModalVisibility}
                    setWorkflowComposeModalVisibility={setWorkflowComposeModalVisibility}
                    ref={connectMailRef}
                    isConnectContentPane
                    emailApiStatus={contextualMailsConversationApiStatus}
                    openCandidateView={openCandidateView}
                    openJobViewInNewTabCallBack={openJobViewInNewTabCallBack}
                  />
                </NetworkIssueErrorBoundary>
              ) : null}
            </InfiniteScroll>
          </div>
        </TabPane>
        {isAllConversationsEnabled && (
          <TabPane tab={allEmailTabPaneName} key="allEmail">
            <div className="master-profile-tab-content">
              <InfiniteScroll
                useWindow={false}
                initialLoad={false}
                loadMore={page => {
                  fetchMails(null, candidate.PersonId || null, null, null, page * 10, 10);
                  setCurrentPage(page);
                }}
                loader={
                  <div key="loader" style={{ width: '100%', textAlign: 'center', paddingBottom: '100px' }}>
                    <Icon style={{ fontSize: '20px' }} type="loading" spin />
                  </div>
                }
                threshold={50}
                hasMore={(currentPage + 1) * 10 < allMailCount}
              >
                {candidate ? (
                  <NetworkIssueErrorBoundary>
                    <MailWindowContainer
                      jobId={jobId}
                      conversationId={null}
                      personId={candidate.PersonId}
                      candidate={candidate}
                      availableProviders={availableProviders}
                      timeLineClassName="connect-window-display-drip-template"
                      FromEmailAddress={FromEmailAddress}
                      currentJobDetails={currentJobDetails}
                      version={version}
                      isComposeEmailModalVisible={isComposeEmailModalVisible}
                      setEmailComposeModalVisibility={setEmailComposeModalVisibility}
                      setWorkflowComposeModalVisibility={setWorkflowComposeModalVisibility}
                      ref={connectAllMailRef}
                      isConnectContentPane
                      emailApiStatus={allContextualMailsConversationApiStatus}
                      openCandidateView={openCandidateView}
                      openJobViewInNewTabCallBack={openJobViewInNewTabCallBack}
                    />
                  </NetworkIssueErrorBoundary>
                ) : null}
              </InfiniteScroll>
            </div>
          </TabPane>
        )}
        <TabPane tab={messageTabPaneName} key="message">
          <div className="master-profile-tab-content">
            {candidate ? (
              <NetworkIssueErrorBoundary>
                <MessageWindowContainer
                  jobId={jobId}
                  jobGuid={jobGuid}
                  conversationId={candidate.ConversationId}
                  personId={null}
                  candidate={candidate}
                  availableProviders={availableProviders}
                  smsCount={smsCount}
                  currentJobDetails={currentJobDetails}
                  version={version}
                  isComposeMessageModalVisible={isComposeMessageModalVisible}
                  setMessageComposeModalVisibility={setMessageComposeModalVisibility}
                  ref={connectMessageRef}
                  isConnectContentPane
                  setMessageTab={setMessageTab}
                />
              </NetworkIssueErrorBoundary>
            ) : null}
          </div>
        </TabPane>
        {isAllConversationsEnabled && (
          <TabPane tab={allMessageTabPaneName} key="all message">
            <div className="master-profile-tab-content">
              {candidate ? (
                <NetworkIssueErrorBoundary>
                  <MessageWindowContainer
                    jobId={jobId}
                    jobGuid={jobGuid}
                    conversationId={null}
                    personId={candidate.PersonId}
                    candidate={candidate}
                    availableProviders={availableProviders}
                    smsCount={smsCount}
                    currentJobDetails={currentJobDetails}
                    version={version}
                    isComposeMessageModalVisible={isComposeMessageModalVisible}
                    setMessageComposeModalVisibility={setMessageComposeModalVisibility}
                    ref={connectALLMessageRef}
                    isConnectContentPane
                    setMessageTab={setMessageTab}
                    openCandidateView={openCandidateView}
                    openJobViewInNewTabCallBack={openJobViewInNewTabCallBack}
                  />
                </NetworkIssueErrorBoundary>
              ) : null}
            </div>
          </TabPane>
        )}
        <TabPane tab={callTabPaneName} key="call">
          <div className="master-profile-tab-content">
            {candidate ? (
              <div style={{ height: '85%', position: 'relative', overflow: 'hidden' }}>
                <div style={{ position: 'absolute', height: '90%', width: '100%' }}>
                  <NetworkIssueErrorBoundary>
                    <CallWindowContainer
                      jobId={jobId}
                      conversationId={candidate.ConversationId}
                      candidate={candidate}
                      portal={candidate.Portal}
                      candidateId={candidate.Id}
                      availableProviders={availableProviders}
                      jobCountryCode={jobCountryCode}
                      currentJobDetails={currentJobDetails}
                      openSipCallWindowsApp={openSipCallWindowsApp}
                      unsubscribeCandidateButton={unsubscribeCandidateButton}
                      connectComponent
                    />
                  </NetworkIssueErrorBoundary>
                </div>
                {isUnsubscribeCandidateEnabled && callConversations?.Order.length > 0 ? (
                  <div
                    style={{
                      position: 'absolute',
                      bottom: '0px',
                      borderTop: '1px solid #dadbdd',
                      width: '100%',
                      background: 'white',
                      paddingTop: '6px',
                    }}
                  >
                    {unsubscribeCandidateButton}
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>
        </TabPane>
        {isBotEnabled ? (
          <TabPane tab={botTabPaneName} key="bot">
            <div className="master-profile-tab-content">
              {candidate ? (
                <NetworkIssueErrorBoundary>
                  <ChatWindowContainer
                    jobId={jobId}
                    jobGuid={jobGuid}
                    conversationId={candidate.ConversationId}
                    personId={candidate.PersonId}
                    showBotConfigDrawer={showBotConfigDrawer}
                    candidate={candidate}
                    isCandidate360View
                  />
                </NetworkIssueErrorBoundary>
              ) : null}
            </div>
          </TabPane>
        ) : null}
        {isWorkflowWindowEnabled && (
          <TabPane tab={WorkflowTabPaneName} key="workflow">
            <MailWindowContainer
              workflowWindowFlag
              availableProviders={availableProviders}
              candidate={candidate}
              jobId={jobId}
              featureToggleList={featureToggleList}
              currentJobDetails={currentJobDetails}
              candidateContext={candidateContext}
              version={version}
              jobGuid={jobGuid}
              currentSelectedEmail={currentSelectedEmail}
              setCurrentSelectedEmail={setCurrentSelectedEmail}
              isComposeWorkflowModalVisible={isComposeWorkflowModalVisible}
              setWorkflowComposeModalVisibility={setWorkflowComposeModalVisibility}
              workflowSelectedEmails={workflowSelectedEmails}
              setWorkflowSelectedEmails={setWorkflowSelectedEmails}
              openCandidateView={openCandidateView}
              openJobViewInNewTabCallBack={openJobViewInNewTabCallBack}
            />
          </TabPane>
        )}
      </Tabs>
    </div>
  );
};
export default ContentPaneBody;

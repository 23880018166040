import * as actionCreators from './ActionCreators/NotesActionCreator';

function setCandidateNotePopupInfo(data) {
  return actionCreators.setCandidateNotePopup(data);
}

function toggleNotePopup(data) {
  return actionCreators.toggleNotesPopUp(data);
}

function closeNotePopup(notePopupId) {
  return actionCreators.closeNotesPopUp(notePopupId);
}
function updateNotePopup(data) {
  return actionCreators.updateNotePopup(data);
}
export { setCandidateNotePopupInfo, toggleNotePopup, closeNotePopup, updateNotePopup };

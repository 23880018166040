import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import _ from 'lodash';
import classnames from 'classnames';
import { Button, Select, Icon, Alert } from 'antd';
import PropTypes from 'prop-types';
import CallHistory from '../../CallHistory/CallHistory';
import './ConnectCallWindow.scss';
import UserAlerts from '../../UserAlerts/UserAlerts';
import { validateStatus } from '../../../Utils/ContactUtils';
import { getCommunicationTabsErrorMessage } from '../../../Utils/CandidateApiErrorResponseMapper';
import { getEventNameByCandidateStatus } from '../../../Analytics/Candidate/CandidateEventUtils';
import eventTypes from '../../../Analytics/EventTypes';
import jobMessage from '../../JobForm/JobMessages';
import placeholder from '../../Placeholders/PlaceholdersMessages';

const { Option } = Select;

function getPhoneDropdownOptions(contact) {
  if (!contact) {
    return null;
  }
  const { Phones: phones } = contact;
  if (!phones) return null;
  return phones.map(phone => {
    return _.get(phone, ['IsRefunded'], false) ? null : (
      <Option value={phone.Number} key={phone.Id}>
        <span className={validateStatus(phone.ValidityStatus) ? 'invalid-spam-phone' : null}> {phone.Number} </span>
      </Option>
    );
  });
}

function getCallerIdsDropdownOptions(callerIds) {
  if (!callerIds?.length) {
    return null;
  }
  return callerIds.map(callerId => {
    return (
      <Option value={callerId} key={callerId}>
        <span> {callerId} </span>
      </Option>
    );
  });
}

function onChangePhone(phoneValue, onPhoneSelection) {
  onPhoneSelection(phoneValue);
}

function getConsentStatusInfoBanner(
  contact,
  selectedPhone,
  candidateName,
  candidateStatus,
  isCommunicationAllowed,
  usersByGuId
) {
  if (!isCommunicationAllowed) {
    const { Subscription: subscription } = contact;
    const _subscription = subscription
      ? { ...subscription, CreatedByName: usersByGuId[subscription.CreatedBy]?.FullName }
      : null;
    return getCommunicationTabsErrorMessage({ candidateStatus, candidateName, subscription: _subscription });
  }
  const { ConsentStatus: consentStatus } = contact;
  if (selectedPhone && selectedPhone.IsAddedByUser) return null;
  const nameParts = (candidateName || '').split(' ');
  const firstName = nameParts && nameParts.length > 0 ? nameParts[0] : '';
  if (consentStatus === 'Denied') {
    return (
      <div className="call-connect-consent-status">
        <div className="consent-status-info-message consent-denied">
          <Icon type="close-circle" theme="filled" />
          <span>{`We're sorry, but ${firstName} has declined consent. You may no longer communicate via Leoforce.`}</span>
        </div>
      </div>
    );
  }
  if (consentStatus === 'Failed') {
    return (
      <div className="call-connect-consent-status">
        <div className="consent-status-info-message consent-denied">
          <Icon type="close-circle" theme="filled" />
          <span>{`We're sorry, we are unable to send consent request to ${firstName}.`}</span>
        </div>
      </div>
    );
  }
  if (consentStatus === 'Pending') {
    return (
      <div className="call-connect-consent-status">
        <div className="consent-status-info-message consent-pending">
          <Icon type="clock-circle" theme="filled" />
          <span>{`Consent is pending. Communication will be enabled when ${firstName} gives consent.`}</span>
        </div>
      </div>
    );
  }
  if (selectedPhone?.IsUnsubscribeByUser) {
    return (
      <div className="call-connect-consent-status">
        <div className="consent-status-info-message consent-denied">
          <Icon type="close-circle" theme="filled" />
          <span>
            <FormattedMessage {...jobMessage.recruiterHasUnsubscribedLabel} /> {selectedPhone.Number}
          </span>
        </div>
      </div>
    );
  }
  return null;
}

function CallWindow(props) {
  const {
    contact,
    onCall,
    callButtonEnabled,
    onPhoneSelection,
    selectedPhone,
    callHistory,
    currentUser,
    connectUsersById,
    featureToggleList,
    candidateName,
    candidateStatus,
    isCommunicationAllowed,
    selectCallerId,
    selectedCallerId,
    callerIds,
    candidate,
    callLoading,
    openSipCallWindowsApp,
    usersByGuId,
    unsubscribeCandidateButton,
    isUnsubscribeCandidateEnabled,
    connectComponent,
    isAlertOpen,
    setIsAlertOpen,
    intl,
  } = props;
  const consentBanner = getConsentStatusInfoBanner(
    contact,
    selectedPhone,
    candidateName,
    candidateStatus,
    isCommunicationAllowed,
    usersByGuId
  );
  const isCallingEnabledForUser = featureToggleList.AudioRead.IsAllowed;
  const isCallingWriteEnabled = featureToggleList.AudioWrite.IsEnabled;
  const {
    contact: { Phones: phones },
  } = props;
  const handleAlertClose = () => {
    setIsAlertOpen(false);
  };

  return (
    <div className="connect-call-window">
      {isCommunicationAllowed ? (
        <>
          <div className="connect-call-window-flex-items phone-dropdown">
            <div className="dropdown-with-label-wrapper">
              <div className="dropdown-with-label">To</div>
              <Select
                dropdownClassName="connect-drawer"
                placeholder={intl.formatMessage({ ...placeholder.selectPhoneNumberLabel })}
                value={selectedPhone ? selectedPhone.Number : undefined}
                style={{ width: 200 }}
                onChange={value => onChangePhone(value, onPhoneSelection)}
                disabled={!phones || !phones.length}
              >
                {getPhoneDropdownOptions(contact)}
              </Select>
            </div>
            {isCallingWriteEnabled ? (
              <Button
                className="call"
                type="primary"
                shape="round"
                loading={callLoading}
                onClick={() => onCall(null)}
                disabled={!callButtonEnabled || !isCallingEnabledForUser || consentBanner}
                sk-event-name={getEventNameByCandidateStatus(
                  eventTypes.candidate.candidateConnect.callCandidate,
                  candidate
                )}
              >
                <FormattedMessage {...jobMessage.callLabel} />
              </Button>
            ) : null}
          </div>
          {isCallingWriteEnabled && !openSipCallWindowsApp ? (
            <div className="connect-call-window-flex-items phone-dropdown">
              <div className="dropdown-with-label-wrapper">
                <div className="dropdown-with-label">From</div>
                <Select
                  dropdownClassName="connect-drawer"
                  placeholder={intl.formatMessage({ ...placeholder.selectDisplayNumberLabel })}
                  value={selectedCallerId}
                  style={{ width: 200 }}
                  onChange={value => selectCallerId(value)}
                >
                  {getCallerIdsDropdownOptions(callerIds)}
                </Select>
              </div>
            </div>
          ) : null}
          {isCallingWriteEnabled && !callerIds?.length ? (
            <Alert
              message="Configure outgoing caller ID from call settings"
              description={[
                'This default Caller ID for all voice calls originating from this system.',
                <br />,
                'Account administrators or users with the required access permissions can configure the caller ID displayed for users when they make an outbound call.',
              ]}
              type="warning"
              showIcon
              closable
              onClose={handleAlertClose}
            />
          ) : null}
          {consentBanner === null ? (
            <div
              className={classnames(
                `connect-call-window-call-history ${
                  !isAlertOpen || !(isCallingWriteEnabled && !callerIds?.length)
                    ? 'connect-call-window-call-history-no-alert'
                    : ''
                }`
              )}
            >
              <CallHistory
                callHistory={callHistory}
                currentUser={currentUser}
                connectUsersById={connectUsersById}
                connectComponent={connectComponent}
                unsubscribeCandidateButton={unsubscribeCandidateButton}
                isUnsubscribeCandidateEnabled={isUnsubscribeCandidateEnabled}
              />
            </div>
          ) : (
            consentBanner
          )}

          {isCallingEnabledForUser === false && isCommunicationAllowed ? (
            <div className="user-alert-wrapper">
              <UserAlerts
                header="Pro feature alert!"
                content={
                  <span>
                    <FormattedMessage {...jobMessage.featureNotEnabledInfoLabel} />{' '}
                    <a href="mailto:support@leoforce.com">support@leoforce.com</a>{' '}
                    <FormattedMessage {...jobMessage.upgradeYourPlanLabel} />
                  </span>
                }
              />
            </div>
          ) : null}
        </>
      ) : (
        consentBanner
      )}
    </div>
  );
}

CallWindow.propTypes = {
  onCall: PropTypes.func.isRequired,
  callButtonEnabled: PropTypes.bool,
};

CallWindow.defaultProps = {
  callButtonEnabled: true,
};

export default injectIntl(CallWindow);
export { CallWindow as CallwindowWithoutInjectIntl };

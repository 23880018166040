import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import NotesTab from './NotesTab';
import * as candidateActions from '../../Actions/CandidateActions';
import {
  setCandidateNotePopupInfo,
  toggleNotePopup,
  closeNotePopup,
  updateNotePopup,
} from '../../Actions/NotesActions';
import { getAllPopupNotes, getAllPopupNotesOrder } from '../../Reducers/NotesReducer';
import { getApiStatus } from '../../Reducers/ApiStatusReducer';
import styles from './NotesTab.module.scss';
import NotesTabMinimized from './NotesTabMinimized';

const mapStateToProps = state => ({
  notesPopupList: getAllPopupNotes(state),
  notesPopupOrder: getAllPopupNotesOrder(state),
  candidateNoteCreateApiStatus: getApiStatus(state, 'candidateNoteCreateApiStatus'),
});

const mapDispatchToProps = {
  createCandidateNote: candidateActions.createCandidateNote,
  fetchBulkCandidateAllNotes: candidateActions.fetchBulkCandidateAllNotes,
  setCandidateNoteTab: setCandidateNotePopupInfo,
  toggleNotePopup,
  closeNotePopup,
  updateNotePopup,
};

const NotesTabContainer = props => {
  const {
    createCandidateNote,
    notesPopupList,
    setCandidateNoteTab,
    activeTab,
    openInNewTab,
    candidateNoteCreateApiStatus,
    toggleNotePopup,
    closeNotePopup,
    updateNotePopup,
    notesPopupOrder,
    fetchBulkCandidateAllNotes,
  } = props;
  return (
    <div className={styles.notesPopupContainer}>
      {notesPopupOrder.map(noteId => {
        const notesData = notesPopupList[noteId];
        return (
          <div key={noteId}>
            <NotesTab
              visible={notesData.visible}
              minimized={notesData.minimized}
              note={notesData.note}
              notesTabPopUpId={noteId}
              setCandidateNoteTab={setCandidateNoteTab}
              candidatesNotesList={notesPopupList}
              createCandidateNote={createCandidateNote}
              jobId={notesData.jobId}
              activeTab={activeTab}
              openInNewTab={openInNewTab}
              vaults={notesData.vaults}
              candidate={notesData.candidate}
              candidateNoteCreateApiStatus={candidateNoteCreateApiStatus}
              candidateContext={notesData.candidateContext}
              toggleNotePopup={toggleNotePopup}
              closeNotePopup={closeNotePopup}
              updateNotePopup={updateNotePopup}
              fetchBulkCandidateAllNotes={fetchBulkCandidateAllNotes}
            />
          </div>
        );
      })}
      {notesPopupOrder.length > 0 && (
        <div className={styles.notesMinimizedFooter}>
          <div className={styles.notesMinimizedContainer}>
            <div className={styles.horizontalScroll}>
              <NotesTabMinimized
                candidatesNotesList={notesPopupList}
                toggleNotePopup={toggleNotePopup}
                closeNotePopup={closeNotePopup}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(NotesTabContainer);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import { Tag, Tooltip, Skeleton, Icon, Popover, Button } from 'antd';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import uuid from 'uuid';
import deepEqual from 'fast-deep-equal';
import shallowEqual from 'shallowequal';
import memoizeOne from 'memoize-one';
import './CandidateListItem.scss';
import { capitalizeAndFormat } from '../../Utils/TextTransformationUtil';
import messages from './messages';
import FetchContactWidget from '../ConnectCard/FetchContactWidget/FetchContactWidget';
import ConnectMediaWidget from '../ConnectCard/ConnectMediaWidget/ConnectMediaWidget';
import RatingIcon from '../Rating/Rating';
import MoverIcon from '../Mover/Mover';
import {
  getInternalSourceWithCandidateId,
  getSourceDisplayName,
  getCandidateSource,
  getPortalName,
  displaySecondaryInternalSourceIcon,
} from '../../Utils/SourceUtils';
import {
  getCandidateOptOutReason,
  v1RejectReasonKeysToDisplayNameMapper,
} from '../../Utils/CandidateRejectReasonsUtils';
import { getNotesIndicatorTextAndStatus } from '../../Utils/NotesUtils';
import { getIsMetadataCardVisible } from '../../Utils/CandidateMetadataUtils';
import { CandidateViewIcon, NotesFilledIcon, DownArrowIcon, UpArrowIcon } from '../../Icons/AryaIcons';
import CandidateShortListReject from '../CandidateShortListReject/CandidateShortListReject';
import CandidateAssignedStatus from '../CandidateStatus/CandidateAssignedStatus';
import { highlightKeyWords, getHighlightedKeyWords } from '../../Utils/KeywordHighlightUtil';
import AppliedSourceTag from './AppliedSourceTag/AppliedSourceTag';
import CandidateOptOutWidget from '../CandidateOptOutWidget/CandidateOptOutWidget';
import ParsingFailedIcon from '../../Icons/CandidateIcons/ParsingFailedIcon';
import styles from './CandidateListItem.module.scss';
import config from '../../Config/Config';
import eventTypes, { modules } from '../../Analytics/EventTypes';
import { getEventNameByCandidateStatus } from '../../Analytics/Candidate/CandidateEventUtils';
import { logSmartKarrotEvent } from '../../Analytics/EventUtils';
import { isPulseUser } from '../../Utils/ConfigUtils';
import {
  getCandidateCardCursorStyle,
  REJECTED,
  SERVICE_REP,
  PULSE_USER,
  getLatestProfileDateMarkup,
  getCandidatePrimarySourceNameToDisplay,
} from '../../Utils/CandidateListUtils';
import SourceImageIcon from './SourceImageIcon/SourceImageIcon';
import candidateCardMessage from '../CandidateCard/CandidateCardMessages';

export function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

class RejectDislikeIcon extends React.PureComponent {
  getpublishedStatusReasonsPopoverContentStyle = publishedStatusReasonsPopoverContent => {
    return publishedStatusReasonsPopoverContent === null ? { display: 'none' } : null;
  };

  render() {
    const { candidateStatus, publishedStatus, publishedStatusReasonsPopoverContent } = this.props;
    return publishedStatus?.toLowerCase() === REJECTED && candidateStatus?.toLowerCase() !== REJECTED ? (
      <Popover
        placement="topLeft"
        overlayStyle={this.getpublishedStatusReasonsPopoverContentStyle(publishedStatusReasonsPopoverContent)}
        content={publishedStatusReasonsPopoverContent}
        title={null}
      >
        <div className="rejected-dislike-icon">
          <Icon className="icon-like" type="dislike" />
        </div>
      </Popover>
    ) : null;
  }
}

class CandidateDisplaySkills extends Component {
  rearrangedSkills = memoizeOne((keywordsToHighlight, keywords, skills) => {
    return this.getRearrangedSkills(keywordsToHighlight, keywords, skills);
  });

  getRearrangedSkills = (keywordsToHighlight = [], keywords = [], skills = []) => {
    const allHighlights = _.uniq([...(keywordsToHighlight || []), ...(keywords || [])]);
    let newSkills = [];
    newSkills = skills.filter(onlyUnique);
    const { highlightedSkills } = getHighlightedKeyWords(newSkills, allHighlights);
    newSkills = _.uniq(highlightedSkills.concat(skills || []));
    const matchedHighlights = _.uniq([...(keywordsToHighlight || []), ...(keywords || [])]);
    return { newSkills, highlightedSkills, matchedHighlights };
  };

  areNextPropsShallowEqual(nextProps) {
    const { props } = this;
    return Object.keys(nextProps).every(key => nextProps[key] === props[key]);
  }

  highlightCandidateCard = () => {
    const { keywordHighlightKey, keywordsToHighlight, keywords, skills } = this.props;
    const candidateSkillsLength = skills?.length || 0;
    const keywordsLength = _.get(keywordsToHighlight, ['length'], 0);
    if (candidateSkillsLength && keywordsLength) {
      const { matchedHighlights } = this.rearrangedSkills(keywordsToHighlight, keywords, skills);

      const allHighlights = matchedHighlights;
      const domSelectors = [`div.candidate-card-skills-content-${keywordHighlightKey}`];
      // Defer highligting momentarily off the main thread
      setTimeout(() => {
        highlightKeyWords(domSelectors, allHighlights);
      }, 1000);
    }
  };

  componentDidMount() {
    this.highlightCandidateCard();
  }

  shouldComponentUpdate(nextProps) {
    return !this.areNextPropsShallowEqual(nextProps) && !deepEqual(nextProps, this.props);
  }

  componentDidUpdate() {
    this.highlightCandidateCard();
  }

  componentWillUnmount() {
    this.rearrangedSkills.clear();
  }

  onTooltipVisibleChange = isTooltipVisible => {
    if (isTooltipVisible) {
      const { keywordsToHighlight, candidate, activeTab, activeSourceName, keywordHighlightKey } = this.props;
      const allHighlights = _.uniq([
        ...(keywordsToHighlight || []),
        ...(activeTab === 'sourced' && activeSourceName !== 'AryaRecommended' ? [] : candidate.Keywords || []),
      ]);
      const domSelectors = [`div.candidate-card-skills-tooltip-${keywordHighlightKey}`];
      highlightKeyWords(domSelectors, allHighlights);
    }
  };

  render() {
    const { skills, keywordsToHighlight, keywords, keywordHighlightKey } = this.props;
    let newSkills = [];
    if (skills) {
      ({ newSkills } = this.rearrangedSkills(keywordsToHighlight, keywords, skills));
    }
    const candidateCardSkills = newSkills.slice(0, 20);
    const skillList = candidateCardSkills.map(skill => {
      const trimSkill = skill.trim();
      if (trimSkill) {
        return (
          <Tag className="candidate-card-skill-tag" key={trimSkill}>
            {trimSkill}
          </Tag>
        );
      }
      return null;
    });
    return (
      <Tooltip
        title={(newSkills || []).join(', ')}
        onVisibleChange={this.onTooltipVisibleChange}
        overlayClassName={`candidate-card-skills-tooltip-${keywordHighlightKey} candidate-card-skills-tooltip`}
      >
        <div className={`candidate-card-skills-content-${keywordHighlightKey} candidate-card-skills-content`}>
          {skillList}
        </div>
      </Tooltip>
    );
  }
}

export function getCandidateStatus(candidate, featureToggleList, jobGuid) {
  const atsSource = getInternalSourceWithCandidateId(candidate.Sources);
  const isPaidJobServiceEnabled = _.get(featureToggleList, ['PaidJobService', 'IsEnabled'], false);
  const isApplicationViewEnabled = _.get(featureToggleList, ['ViewApplicationOnCandidateCard', 'IsEnabled'], false);
  const showViewApplicationButtonInDrawer = !(
    isPaidJobServiceEnabled ||
    !isApplicationViewEnabled ||
    !candidate?.AppliedTime ||
    !jobGuid
  );
  const portalUrl = config.urls.careerportalUrl;
  const formattedPortalUrl = portalUrl
    ?.replace('{{jobId}}', jobGuid)
    .replace('{{candidateId}}', candidate.Guid)
    .replace('{{appliedTime}}', candidate.AppliedTime)
    .replace('{{aryaCandidateId}}', candidate.Id);
  const candidateOrigin = candidate?.CandidateOrigin ?? '';
  if (isPaidJobServiceEnabled) return null;
  let candidateStatus = _.get(atsSource, 'Status');
  if (candidateStatus) {
    if (candidateStatus.toLowerCase() === 'applied' && candidateOrigin)
      candidateStatus = candidateStatus?.concat(' through ', candidateOrigin);
    return <div className="candidate-card-candidate-status">{candidateStatus}</div>;
  }

  if (candidate.AssignedStatus) {
    return <CandidateAssignedStatus assignedStatus={candidate.AssignedStatus} candidateOrigin={candidateOrigin} />;
  }
  if (candidate.AppliedTime) {
    return (
      <AppliedSourceTag
        sourceName={candidateOrigin}
        showViewApplicationButtonInDrawer={showViewApplicationButtonInDrawer}
        formattedPortalUrl={formattedPortalUrl}
      />
    );
  }
  return null;
}

export function getCandidateApplicationPreviewBtn(jobGuid, candidate, featureToggleList, currentUser, userConfig) {
  const atsSource = getInternalSourceWithCandidateId(candidate.Sources);
  const sourceDisplayName = getSourceDisplayName(atsSource || getCandidateSource(candidate), userConfig);
  const isPaidJobServiceEnabled = _.get(featureToggleList, ['PaidJobService', 'IsEnabled'], false);
  const isApplicationViewEnabled = _.get(featureToggleList, ['ViewApplicationOnCandidateCard', 'IsEnabled'], false);
  // application review to be disabled for social candidates
  const isInvalidSource = sourceDisplayName?.toLowerCase() === 'social';
  if (isPaidJobServiceEnabled || isInvalidSource || !isApplicationViewEnabled || !candidate?.AppliedTime || !jobGuid)
    return null;
  const portalUrl = config.urls.careerportalUrl;
  const impersonateId = localStorage.getItem('Impersonate') || null;
  const canImpersonate = !!(
    userConfig?.CanImpersonate ||
    (impersonateId !== null && impersonateId !== currentUser?.sub)
  );
  let userKey = currentUser?.email;
  if (canImpersonate) {
    userKey = `${userKey};QM`;
  }
  const formattedPortalUrl = portalUrl
    ?.replace('{{jobId}}', jobGuid)
    .replace('{{candidateId}}', candidate.Guid)
    .replace('{{appliedTime}}', candidate.AppliedTime)
    .replace('{{rId}}', btoa(userKey))
    .replace('{{aryaCandidateId}}', candidate.Id);
  return (
    <Button
      type="link"
      className={styles.applicationReviewBtn}
      onClick={e => {
        e.stopPropagation();
        window.open(formattedPortalUrl, '_blank');
      }}
    >
      View Application
    </Button>
  );
}
export const getIsResumeOrCvUnlocked = (candidateSourcePortal = '', candidate) => {
  const isResumeOrCvLibrary = ['resumelibrary', 'cvlibrary'].includes(candidateSourcePortal.toLowerCase());
  if (isResumeOrCvLibrary) {
    return candidate?.IsUnlocked;
  }
  return true;
};

export const candidateRejectSection = rejectedBy => {
  return (
    <div className="candidate-rejected-status-tag">
      <div className="candidate-rejected-status">
        Rejected by
        <div className="candidate-rejected-status-text">{rejectedBy}</div>
      </div>
    </div>
  );
};

export const getCandidateRejectSection = (candidateId, userConfig, activeTab, rejectedCandidateIds) => {
  const rejectedCandidateDetailsKey = rejectedCandidateIds?.find(key => key.CandidateId === candidateId);
  if (activeTab === REJECTED && isPulseUser() && userConfig.Role !== SERVICE_REP) {
    return rejectedCandidateDetailsKey?.UserType === PULSE_USER
      ? candidateRejectSection('Me')
      : candidateRejectSection(SERVICE_REP);
  }
  if (activeTab === REJECTED && isPulseUser() && userConfig.Role === SERVICE_REP) {
    return candidateRejectSection(rejectedCandidateDetailsKey?.RejectedByName);
  }
  return null;
};

const getCandidateInfo = (value, defaultValue, candidate) => {
  return _.get(candidate, [value], defaultValue);
};

const getPublishedStatusReasonsPopoverContent = candidate => {
  const publishedStatusReasons = getCandidateInfo('PublishStatusReasons', [], candidate);
  if (publishedStatusReasons.length === 0) {
    return (
      <div className="publishedStatusReasons">
        <p>No reasons selected</p>
      </div>
    );
  }
  return (
    <div className="publishedStatusReasons">
      {publishedStatusReasons.map(reason => {
        const reasonDisplayName = v1RejectReasonKeysToDisplayNameMapper[reason?.toUpperCase()];
        return reasonDisplayName ? <p key={reason}>{reasonDisplayName}</p> : null;
      })}
    </div>
  );
};

export const getPublishedTag = ({ publishedStatus, candidateStatus, candidate }) => {
  const candidateServiceStatus = candidate.ServiceStatus || 'default';
  const publishTagClassName = `${candidateServiceStatus.toLowerCase()}-publish-tag`;
  const publishedStatusReasonsPopoverContent = getPublishedStatusReasonsPopoverContent(candidate);
  return (
    <div className="publish-icon-wrapper">
      {publishedStatus === 'Shortlisted' ? (
        <div className="shortlisted-like-icon">
          <Icon className="icon-like" type="like" />
        </div>
      ) : null}
      <RejectDislikeIcon
        candidateStatus={candidateStatus}
        publishedStatus={publishedStatus}
        publishedStatusReasonsPopoverContent={publishedStatusReasonsPopoverContent}
      />
      <div className={`candidate-publish-tag ${publishTagClassName}`}>
        <Icon type="check" />
        <div className="candidate-publish-tag-text">
          <FormattedMessage {...candidateCardMessage.publishedLabel} />
        </div>
      </div>
    </div>
  );
};

class CandidateListItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keywordHighlightKey: uuid.v4(),
    };
    this.getNotesIndicator = this.getNotesIndicator.bind(this);
  }

  getNotesIndicator() {
    const {
      candidate,
      featureToggleList,
      version,
      candidateType,
      candidateContext = 'job',
      candidateAllNotesFetchStatus,
    } = this.props;

    const candidateAllNotesFetched = candidateAllNotesFetchStatus !== 'INPROGRESS';
    const isNotesEnabled = _.get(featureToggleList, ['Notes', 'IsEnabled'], false);
    const candidateNotesCount = _.get(candidate, 'CandidateNotesCount', null);
    const callNotesCount = _.get(candidate, 'CallNotesCount', null);
    const notesIndicatorTextandStatus = getNotesIndicatorTextAndStatus(candidateNotesCount, callNotesCount, version);
    const isNotesIndicatorEnabled = _.get(featureToggleList, ['NotesIndicator', 'IsEnabled']);
    let isNotesIndicatorDisplayed = false;
    if (isNotesIndicatorEnabled && candidateType !== 'quick-search' && isNotesEnabled && candidateContext === 'job') {
      isNotesIndicatorDisplayed = true;
    }

    return (
      <div className="candidate-card-footer-secondary">
        {isNotesIndicatorDisplayed ? (
          <Skeleton active loading={!candidateAllNotesFetched} title={false} rows={1}>
            <div
              className="candidate-card-notes"
              onClick={() => this.onCandidateCardClick('notes')}
              role="presentation"
            >
              <div className="candidate-card-notes-icon-wrapper">
                <NotesFilledIcon className="candidate-card-notes-icon" />
              </div>
              <div className="candidate-card-notes-count">{notesIndicatorTextandStatus.text}</div>
            </div>
          </Skeleton>
        ) : null}
      </div>
    );
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !shallowEqual(nextProps, this.props) || !deepEqual(nextProps, this.props) || !deepEqual(nextState, this.state)
    );
  }

  getConnectMediaWidget = ({ isCandidateNotesAvailable }) => {
    const {
      featureToggleList,
      connectStatusLoaded,
      showCandidateDetail,
      candidate,
      indexKey,
      currentJobDetails,
      handleContactPull,
      showConnectWidget,
      candidateContext = 'job',
    } = this.props;

    const isContactFetchEnabled = featureToggleList?.ContactFetch?.IsEnabled;
    const candidateOptOutReason = getCandidateOptOutReason(candidate);
    const optedOutAdditionDetails = candidateOptOutReason?.AdditionalDetails;

    const getContactPullIcon = isContactFetchEnabled ? (
      <FetchContactWidget
        fetchContactStatus={candidate.fetchContactStatus}
        candidate={candidate}
        currentJobDetails={currentJobDetails}
        handleContactPull={handleContactPull}
      />
    ) : null;
    if (showConnectWidget && !candidateOptOutReason) {
      const isEmailReadVisible = featureToggleList.EmailRead.IsEnabled || candidateContext === 'segment';
      const isMessageReadVisible = featureToggleList.MessageRead.IsEnabled || candidateContext === 'segment';
      const isAudioReadVisible = featureToggleList.AudioRead.IsEnabled || candidateContext === 'segment';
      return (
        <Skeleton active loading={!connectStatusLoaded} rows={1} title={false}>
          {candidate.PersonId ? (
            <ConnectMediaWidget
              candidate={candidate}
              onIconClick={showCandidateDetail}
              indexKey={indexKey}
              ConnectStatus={candidate.connectInfo}
              fetchContactStatus={candidate.fetchContactStatus}
              featureToggleList={featureToggleList}
              currentJobDetails={currentJobDetails}
              isEmailReadVisible={isEmailReadVisible}
              isMessageReadVisible={isMessageReadVisible}
              isAudioReadVisible={isAudioReadVisible}
              isCandidateNotesAvailable={isCandidateNotesAvailable}
            />
          ) : (
            getContactPullIcon
          )}
        </Skeleton>
      );
    }
    if (candidateOptOutReason) {
      return <CandidateOptOutWidget candidateOptOutType={optedOutAdditionDetails.CandidateOptOutType} />;
    }
    return null;
  };

  getCandidateInfo = (value, defaultValue) => {
    const { candidate } = this.props;
    return _.get(candidate, [value], defaultValue);
  };

  getCandidateScoreContent = ({ showCandidateScore, candidate, scoreCss, whiteLabelInfo }) => {
    const { status } = this.props;
    const AppName = _.get(whiteLabelInfo, ['AppName'], 'Leoforce');
    const scoreIndicator = (
      <div key="candidate-card-score" className={`candidate-card-score  ${scoreCss}`}>
        {Number(candidate?.Score).toFixed(2)}
      </div>
    );
    const scoreErrorIndicator = (
      <Tooltip title={`${AppName} could not analyze this candidate`} placement="topLeft">
        <div>
          <ParsingFailedIcon className={styles.scoreErrorIcon} />
        </div>
      </Tooltip>
    );
    const scorePendingIndicator = (
      <Tooltip title={`${AppName} still analyzing`} placement="topLeft">
        <div>
          <img
            src={`${config.urls.appUrl}/static/Images/loader-icon.gif`}
            alt="Leoforce still analyzing"
            className={styles.scoreErrorPendingIcon}
          />
        </div>
      </Tooltip>
    );
    let scoreContent = null;
    const appliedTimeDifference = moment().diff(moment.utc(candidate?.AppliedTime), 'minutes');

    if (showCandidateScore) {
      if (Number.isFinite(candidate?.Score) && candidate?.Score >= 0) {
        scoreContent = scoreIndicator;
      } else if (status?.toLowerCase() === 'applied' && [null, undefined]?.includes(candidate?.Score)) {
        if (candidate?.AppliedResumeParsingStatus?.toUpperCase() === 'FAILED') {
          scoreContent = scoreErrorIndicator;
        } else if (appliedTimeDifference >= 5) {
          scoreContent = scoreErrorIndicator;
        } else scoreContent = scorePendingIndicator;
      }
    }
    return <span className="candidate-card-info-icon">{scoreContent}</span>;
  };

  onCandidateCardClick = tabName => {
    const { showCandidateDetail, candidate, indexKey } = this.props;
    const candidateId = this.getCandidateInfo('Id', null);
    if (this.isCandidateInfoPanelAllowed(candidateId)) {
      if (showCandidateDetail) {
        showCandidateDetail(candidate, 'profile', indexKey, tabName);
      }
      if (tabName === 'notes') {
        logSmartKarrotEvent(
          getEventNameByCandidateStatus(eventTypes.candidate.candidateProfile.goToCandidateNotes, candidate),
          { Module: modules.candidate.candidateActions }
        );
      } else
        logSmartKarrotEvent(getEventNameByCandidateStatus(eventTypes.candidate.candidateView.view, candidate), {
          Module: modules.candidate.candidateActions,
        });
    }
  };

  getPrimaryFooterStyle = isCandiate360Visible => {
    if (!isCandiate360Visible) {
      return { flex: '4.5 3' };
    }
    return { flex: '2.9 3' };
  };

  isCandidateInfoPanelAllowed = candidateIdData => {
    const { userConfig, activeTab, rejectedCandidateIds } = this.props;
    if (activeTab === REJECTED && userConfig.Role !== SERVICE_REP && isPulseUser()) {
      const candidateIndex = rejectedCandidateIds?.find(key => key.CandidateId === candidateIdData);
      return candidateIndex?.UserType === PULSE_USER;
    }
    return true;
  };

  getCandidateRejectedStatusUpdate = candidate => {
    const { candidateStatusUpdate } = this.props;
    return candidateStatusUpdate?.[candidate.Id]?.Rejected;
  };

  getCandidateShortlistedStatusUpdate = candidate => {
    const { candidateStatusUpdate } = this.props;
    return candidateStatusUpdate?.[candidate.Id]?.Shortlisted;
  };

  showInternalIconForDownloadedCandidates = showInternalIcon => {
    const { showVaultName } = this.props;
    const internalIcon = (
      <div className={styles.overlappingSourceImage}>
        <SourceImageIcon
          tooltipVisibility
          placement="top"
          sourceName="Internal"
          sourcePortal="InternalForDownloadedCandidates"
          iconStyle={{ fontSize: 18, display: 'flex' }}
          showVaultName={showVaultName}
        />
      </div>
    );
    if (showInternalIcon) {
      return internalIcon;
    }
    return null;
  };

  getCandidate360ListItemClassnames = () => {
    return { candidateCardContactStyle: 'candidate-card-connect-content' };
  };

  getIsCandidateNotesAvailable = ({ candidate = {}, candidate360Notes = {} }) => {
    const callNotesCount = candidate.CallNotesCount;
    const candidate360NotesCount = Object.keys(candidate360Notes)?.length || 0;
    return candidate360NotesCount + (callNotesCount || 0) > 0;
  };

  onClickCandidateCardPush = redirectTabName => {
    const { version } = this.props;
    if (version === 'ats') this.onCandidateCardClick(redirectTabName);
  };

  render() {
    const {
      candidate,
      onCandidateStatusChange,
      onCandidateReject,
      candidateType,
      version,
      featureToggleList,
      openCandidateView,
      whiteLabelInfo,
      isShortlistIconEnabled = true,
      candidateMetadataDetails,
      handleCandidateMetadataClick,
      showCandidateMetadataCard,
      isDisplayShortlistInfo,
      rejectTooltipTitle,
      shortlistTooltipTitle,
      showShortlistIconReject = true,
      candidateContext = 'job',
      isScoreVisible = true,
      isRatingVisible = true,
      isMoverPredictionVisible = true,
      keywordsToHighlight,
      userConfig,
      currentJobDetails,
      getCandidate360Notes,
      activeSourceName,
      activeTab,
      rejectedCandidateIds,
      selectedSubSegmentId,
    } = this.props;

    const { keywordHighlightKey } = this.state;
    const jobGuid = currentJobDetails?.JobGuid;
    const showCandidateScore = userConfig.IsCandidateScoreVisible;
    const showVaultName = userConfig.ShowVaultName;
    const revealActiveChannelSourceName = featureToggleList.RevealPortalsUnderGroup.IsEnabled;
    const candidateRejectedStatusUpdate = this.getCandidateRejectedStatusUpdate(candidate);
    const candidateShortlistedStatusUpdate = this.getCandidateShortlistedStatusUpdate(candidate);
    const candidate360Notes = getCandidate360Notes(candidate?.Id) || {};
    const candidateKeywords =
      activeTab === 'sourced' && activeSourceName !== 'AryaRecommended' ? [] : candidate.Keywords || [];

    const isCandidateNotesAvailable = this.getIsCandidateNotesAvailable({
      candidate,
      candidate360Notes,
    });
    const jobFunctionText = <span>{capitalizeAndFormat(_.get(candidate, ['Derived', 'JobFunction'], ''))}</span>;

    const industryText = <span>{capitalizeAndFormat(candidate.Industry)}</span>;

    const goldText = <span>{candidate.RatingReason}</span>;

    let isNew = !candidate.IsViewed ? 'candidate-card-new' : '';

    if (candidateType === 'quick-search') {
      isNew = true;
    }

    let scoreCss = 'candidate-card-score-good';
    if (candidate.Rating === 'Best') {
      scoreCss = 'candidate-card-score-best';
    } else if (candidate.Rating === 'Better') {
      scoreCss = 'candidate-card-score-better';
    } else if (candidate.Rating === 'Good') {
      scoreCss = 'candidate-card-score-good';
    }

    const candidateTitle =
      candidate.Title && candidate.Company
        ? `${candidate.Title} @ ${candidate.Company}`
        : candidate.Title || candidate.Company;

    const { candidateSourceName, candidateSource } = getCandidatePrimarySourceNameToDisplay(
      candidate,
      version,
      showVaultName,
      whiteLabelInfo,
      revealActiveChannelSourceName,
      userConfig
    );

    const candidateSourcePortal = getPortalName(candidate.Sources, revealActiveChannelSourceName);
    const publishedStatus = this.getCandidateInfo('PublishStatus', null);
    const candidateStatus = this.getCandidateInfo('Status', null);
    const candidateId = this.getCandidateInfo('Id', null);
    const showPublishedTag =
      candidate.IsPublished && _.get(featureToggleList, ['PublishingCandidates', 'IsEnabled'], false);
    const isPaidJobServiceEnabled = _.get(featureToggleList, ['PaidJobService', 'IsEnabled'], false);
    const isShortlistRejectEnabled = _.get(featureToggleList, ['ShortlistReject', 'IsEnabled'], true);
    const yearsOfExperience = this.getCandidateInfo('YearsOfExperience', null);
    const candidateApplicationPreviewBtn = getCandidateApplicationPreviewBtn(jobGuid, candidate, featureToggleList);
    const showRejectIcon =
      candidateContext === 'job' ||
      (activeTab !== 'rejected' &&
        candidateContext === 'segment' &&
        (!candidateStatus ||
          candidateStatus?.toLowerCase() === 'shortlisted' ||
          candidateStatus?.toLowerCase() === 'sourced'));
    const showShortlistIcon =
      (candidateContext === 'job' && isShortlistIconEnabled) ||
      (candidateContext === 'segment' &&
        (!candidateStatus ||
          candidateStatus?.toLowerCase() === 'rejected' ||
          activeTab === 'rejected' ||
          candidateStatus?.toLowerCase() === 'sourced'));

    const lowerCasedCandidateSourceName = candidateSourcePortal.toLowerCase();
    const isSRPulseUser = featureToggleList?.WhiteGloveServiceProgress?.IsEnabled;
    const isResumeOrCvUnlocked = getIsResumeOrCvUnlocked(candidateSourcePortal, candidate);
    const showInternalIcon = displaySecondaryInternalSourceIcon(
      lowerCasedCandidateSourceName,
      isSRPulseUser,
      isResumeOrCvUnlocked,
      isPulseUser(),
      candidate
    );

    const { candidateCardContactWrapperStyle, candidateCardContactStyle } = this.getCandidate360ListItemClassnames();

    const redirectTabCandidateSources = ['social', 'passive'];

    const redirectTabName = redirectTabCandidateSources.includes(candidateSourcePortal?.toLowerCase())
      ? 'work-history'
      : 'profile';

    return (
      <div className="candidate-card-wrapper">
        <div className={`candidate-card ${isNew}`}>
          <div className="candidate-card-info">
            <div className={styles.candidateTags}>
              <div className="candidate-status-push-button-wrapper">
                <div className="candidate-card-push-button">
                  {candidateSource ? (
                    <Tooltip title={<div>Client Candidate ID: {candidateSource.CandidateId}</div>}>
                      <div>
                        <Icon
                          type="upload"
                          className="push-status-icon"
                          onClick={() => this.onClickCandidateCardPush(redirectTabName)}
                        />
                      </div>
                    </Tooltip>
                  ) : null}
                </div>
              </div>
              {getCandidateRejectSection(candidateId, userConfig, activeTab, rejectedCandidateIds)}
              {getCandidateStatus(candidate, featureToggleList, jobGuid)}
              {showPublishedTag ? getPublishedTag({ publishedStatus, candidateStatus, candidate }) : null}
            </div>
            <div className="candidate-card-content">
              <div
                className="candidate-card-primary-content"
                onClick={() => this.onCandidateCardClick(redirectTabName)}
                onKeyPress={() => this.onCandidateCardClick(redirectTabName)}
                role="presentation"
                style={getCandidateCardCursorStyle(candidateStatus, publishedStatus)}
              >
                <div className="candidate-card-personal-info">
                  <div className={styles.overlappedIcons}>
                    <div className={styles.sourceImage}>
                      <SourceImageIcon
                        tooltipVisibility
                        placement="top"
                        sourceName={candidateSourceName}
                        sourcePortal={candidateSourcePortal}
                        iconStyle={{ fontSize: 18, display: 'flex' }}
                        showVaultName={showVaultName}
                      />
                    </div>
                    {this.showInternalIconForDownloadedCandidates(showInternalIcon)}
                  </div>{' '}
                  <div
                    className="candidate-card-candidate-name"
                    style={{ maxWidth: _.get(candidate, ['AppliedTime'], null) ? '30%' : '50%' }}
                  >
                    <Tooltip title={candidate.Name}>{candidate.Name}</Tooltip>
                  </div>
                  <div
                    className="candidate-card-candidate-location"
                    style={{ maxWidth: _.get(candidate, ['AppliedTime'], null) ? '30%' : '50%' }}
                  >
                    <Tooltip title={candidate.Location}>{candidate.Location}</Tooltip>
                  </div>
                  {yearsOfExperience !== null ? (
                    <div className="candidate-personal-experience-info">
                      {' '}
                      <span className="candidate-personal-experience-info-text">
                        {yearsOfExperience === 0 ? '< 1year' : `${yearsOfExperience} +yrs`}
                      </span>
                    </div>
                  ) : null}
                  {openCandidateView && this.isCandidateInfoPanelAllowed(candidateId) ? (
                    <div className="candidate-view-icon-wrapper">
                      <CandidateViewIcon
                        className="candidate-view-icon"
                        onClick={e => {
                          openCandidateView(candidate);
                          e.stopPropagation();
                        }}
                        sk-event-name={getEventNameByCandidateStatus(
                          eventTypes.candidate.candidateView.viewInNewTab,
                          candidate
                        )}
                      />
                    </div>
                  ) : null}
                </div>
                <div className="candidate-card-title">
                  <Tooltip title={candidateTitle}>
                    <div className="candidate-card-past-info">{candidateTitle}</div>
                  </Tooltip>
                </div>
                <div
                  className="candidate-card-experience-info"
                  style={getCandidateCardCursorStyle(candidateStatus, publishedStatus)}
                >
                  <div className="candidate-card-info-tag">
                    <FormattedMessage {...messages.candidatePast} />
                  </div>
                  <Tooltip title={candidate.RecentExperience || '-'}>
                    <div className="candidate-card-past-info font-size-13px">{candidate.RecentExperience || '-'}</div>
                  </Tooltip>
                </div>
                <div
                  className="candidate-card-education-info"
                  style={getCandidateCardCursorStyle(candidateStatus, publishedStatus)}
                >
                  <div className="candidate-card-info-tag">
                    <FormattedMessage {...messages.candidateEdu} />
                  </div>
                  <Tooltip title={candidate.HighestDegree || '-'}>
                    <div className="candidate-card-education font-size-13px">{candidate.HighestDegree || '-'}</div>
                  </Tooltip>
                </div>
              </div>
              <div className="candidate-card-secondary-content">
                <div
                  className="candidate-card-skills"
                  style={getCandidateCardCursorStyle(candidateStatus, publishedStatus)}
                >
                  <CandidateDisplaySkills
                    skills={candidate.Skills}
                    keywords={candidateKeywords}
                    keywordsToHighlight={keywordsToHighlight}
                    keywordHighlightKey={keywordHighlightKey}
                    candidate={candidate}
                    activeTab={activeTab}
                    activeSourceName={activeSourceName}
                  />
                </div>
                {candidate.Derived && candidate.Derived.JobFunction ? (
                  <div className="candidate-card-occupation">
                    <div
                      className="candidate-card-occupation-title"
                      style={getCandidateCardCursorStyle(candidateStatus, publishedStatus)}
                    >
                      <FormattedMessage {...messages.occupation} />
                      :&nbsp;
                      <Tooltip placement="top" title={jobFunctionText}>
                        <span className="candidate-card-occupation-details">
                          {capitalizeAndFormat(candidate.Derived.JobFunction)}
                        </span>
                      </Tooltip>
                    </div>
                  </div>
                ) : null}
                {candidate.Industry ? (
                  <div className="candidate-card-industry">
                    <div
                      className="candidate-card-industry-title"
                      style={getCandidateCardCursorStyle(candidateStatus, publishedStatus)}
                    >
                      <FormattedMessage {...messages.industry} />
                      :&nbsp;
                      <Tooltip placement="top" title={industryText}>
                        <span className="candidate-card-industry-details">
                          {capitalizeAndFormat(candidate.Industry)}
                        </span>
                      </Tooltip>
                    </div>
                  </div>
                ) : null}
              </div>

              <div className={classnames('candidate-card-tertiary-content')}>
                <div className={candidateCardContactWrapperStyle}>
                  <div className={candidateCardContactStyle}>
                    {this.getConnectMediaWidget({ isCandidateNotesAvailable })}
                  </div>
                </div>
              </div>
            </div>
            <div className="candidate-card-footer">
              <div className="candidate-card-footer-primary" style={this.getPrimaryFooterStyle()}>
                {isScoreVisible
                  ? this.getCandidateScoreContent({ showCandidateScore, candidate, scoreCss, whiteLabelInfo })
                  : null}
                {isRatingVisible ? (
                  <span className="candidate-card-info-icon">
                    <Tooltip placement="topRight" title={goldText} overlayClassName="rating-tooltip">
                      <div>
                        <RatingIcon type={candidate.Rating} />
                      </div>
                    </Tooltip>
                  </span>
                ) : null}
                {isMoverPredictionVisible && candidate.MoversPrediction ? (
                  <span className="candidate-card-info-icon">
                    <Tooltip placement="top" title={candidate.MoversPrediction}>
                      <div>
                        <MoverIcon type={candidate.MoversPrediction} />
                      </div>
                    </Tooltip>
                  </span>
                ) : null}
                {getLatestProfileDateMarkup(candidate)}
              </div>
              {this.getNotesIndicator()}
              <div className="candidate-card-footer-tertiary">
                {getIsMetadataCardVisible({ candidateMetadataDetails, isDisplayShortlistInfo }) ? (
                  <div
                    className={classnames(
                      `candidate-card-footer-button ${
                        showCandidateMetadataCard ? 'candidate-card-footer-button-open' : ''
                      }`
                    )}
                    onClick={() => handleCandidateMetadataClick(candidate.Id)}
                    role="button"
                    tabIndex={0}
                    onKeyPress={() => handleCandidateMetadataClick(candidate.Id)}
                  >
                    <span className="candidate-card-footer-button-text">
                      {!showCandidateMetadataCard ? 'See more' : 'See less'}
                    </span>
                    <span className="candidate-card-footer-button-icon">
                      {!showCandidateMetadataCard ? <DownArrowIcon /> : <UpArrowIcon />}
                    </span>
                  </div>
                ) : null}
                {candidateApplicationPreviewBtn ? (
                  <div className="candidate-card-footer-applicationReview">{candidateApplicationPreviewBtn}</div>
                ) : null}
              </div>
            </div>
          </div>
          {showShortlistIconReject && isShortlistRejectEnabled ? (
            <div className="candidate-card-actions">
              <CandidateShortListReject
                onCandidateStatusChange={onCandidateStatusChange}
                onCandidateReject={onCandidateReject}
                isFeedbackRequired={candidateContext === 'job'}
                candidate={candidate}
                candidateType={candidateType}
                candidateShortlistedStatusUpdate={candidateShortlistedStatusUpdate}
                candidateRejectedStatusUpdate={candidateRejectedStatusUpdate}
                isPaidJobServiceEnabled={isPaidJobServiceEnabled}
                showShortlistIcon={showShortlistIcon}
                showRejectIcon={showRejectIcon}
                featureToggleList={featureToggleList}
                rejectTooltipTitle={rejectTooltipTitle}
                shortlistTooltipTitle={shortlistTooltipTitle}
                candidateContext={candidateContext}
                activeTab={activeTab}
                version={version}
                selectedSubSegmentId={selectedSubSegmentId}
              />
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

CandidateListItem.propTypes = {
  candidate: PropTypes.shape({
    Skills: PropTypes.arrayOf(PropTypes.string),
    DiversityConfidence: PropTypes.number,
    ScoreReason: PropTypes.string,
    JobFunction: PropTypes.string,
    Name: PropTypes.string,
    Location: PropTypes.string,
    Title: PropTypes.string,
    Company: PropTypes.string,
    IsDiverse: PropTypes.bool,
    MoversPrediction: PropTypes.string,
    onCandidateStatusChange: PropTypes.func,
    fetchContactStatus: PropTypes.string,
  }),
  onClick: PropTypes.func,
};

CandidateListItem.defaultProps = {
  candidate: PropTypes.shape({
    Skills: [],
    DiversityConfidence: undefined,
    ScoreReason: undefined,
    JobFunction: undefined,
    Name: undefined,
    Location: undefined,
    Title: undefined,
    Company: undefined,
    IsDiverse: undefined,
    MoversPrediction: undefined,
    onCandidateStatusChange: () => {},
  }),
  onClick: () => {},
};

export default CandidateListItem;
export { CandidateDisplaySkills, RejectDislikeIcon, CandidateListItem as CandidateListItemWithoutStore };

import React from 'react';
import styles from './CandidateCardAppliedSourceTag.module.scss';

export default function CandidateCardAppliedSourceTag(props) {
  const { sourceName } = props;
  let appliedTagText = 'Applied';
  if (sourceName) {
    appliedTagText =
      sourceName === 'ChatBot' ? appliedTagText.concat(' via LeoforceBot') : appliedTagText.concat(' via ', sourceName);
  }

  return <div className={styles.candidateCardAppliedSourceTag}>{appliedTagText}</div>;
}

import { Skeleton, Tooltip } from 'antd';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import { connect } from 'react-redux';
import styles from './Candidate360MessageCard.module.scss';
import candidateDrawerMessage from '../../Containers/CandidateDrawer/messages';
import { getJobsBasicInfoById, getBasicInfoJobGuidToIdMapping } from '../../Reducers/JobsBasicInfoReducer';
import { getMessageDeliveryStatusIcon, getMessageDeliveryStatusInformation } from '../Utils/SmsUtils';

const mapStateToProps = (state, ownProps) => {
  return {
    basicInfoJobGuidToIdMapping: getBasicInfoJobGuidToIdMapping(state),
    jobsBasicInfoById: getJobsBasicInfoById(state),
  };
};

const Candidate360MessageCard = ({
  data,
  openComposeTextModal,
  setPhoneNumber,
  basicInfoJobGuidToIdMapping,
  jobsBasicInfoById,
  personId,
  openCandidateView,
  openJobViewInNewTabCallBack,
  candidate,
  version,
}) => {
  const {
    to,
    sentTime,
    message,
    status,
    unreadSmsCount,
    IsByPerson: isByPerson,
    createdByName,
    from,
    sendMessageApiStatus,
    pendingState,
    messageDeliveryStatusInformation,
    requestStatus,
    RefId,
  } = data;

  const jobId = basicInfoJobGuidToIdMapping?.[RefId];
  const jobDetails = jobsBasicInfoById[jobId];
  const jobTitle = jobDetails?.JobTitle;
  const entityType = jobDetails?.EntityType;
  const isJobAssignedToCurrentUser = jobDetails?.IsJobAssignedToCurrentUser;
  const jobCode = jobDetails?.JobCode || 'N/A';
  const isMessageUnRead = unreadSmsCount > 0;
  const infoText = isByPerson ? (
    <FormattedMessage {...candidateDrawerMessage.receivedFromLabel} />
  ) : (
    <FormattedMessage {...candidateDrawerMessage.sentToLabel} />
  );
  const displayMessage = message.length > 80 ? `${message.substring(0, 80)}...` : message;
  const messageCardStyle = isMessageUnRead ? { background: '#F5F5F5' } : {};
  const messageDisplayStyle = { fontWeight: 'bold', color: '#1F2730' };
  const candidatePhoneNumber = isByPerson ? from : to;

  const onClickMessageCard = () => {
    if (Array.isArray(candidatePhoneNumber)) {
      setPhoneNumber(candidatePhoneNumber[0]);
    } else {
      setPhoneNumber(candidatePhoneNumber);
    }
    openComposeTextModal(true);
  };

  const getMessageStatusIconWithToolTip = () => {
    const iconForStatus = getMessageDeliveryStatusIcon(status, messageDeliveryStatusInformation, requestStatus);
    const tooltipMessage =
      status.toLowerCase() === 'delivered'
        ? ''
        : getMessageDeliveryStatusInformation(messageDeliveryStatusInformation, status);
    return (
      <Tooltip key="message-status-icon" title={tooltipMessage} zIndex={2223} placement="left">
        {iconForStatus}
      </Tooltip>
    );
  };

  const statusColor = status === 'Failed' ? { color: 'rgba(245, 34, 45, 1)' } : { color: '#1f2730' };
  const showSkeleton = pendingState && sendMessageApiStatus === 'INPROGRESS';
  if (sendMessageApiStatus === 'FAILED') return '';

  const messageStatus = isByPerson ? status : getMessageStatusIconWithToolTip();

  const handleCandidateView = e => {
    if (version === 'ats' && jobCode !== 'N/A') openJobViewInNewTabCallBack(jobCode);
    else if (isJobAssignedToCurrentUser) openCandidateView(candidate, jobId, entityType);
    e.stopPropagation();
  };

  return (
    <Skeleton active loading={showSkeleton}>
      <div
        className={styles.candidate360Message}
        style={messageCardStyle}
        onClick={onClickMessageCard}
        role="presentation"
      >
        {personId && (
          <div className={styles.jobInfoContainer}>
            <FormattedMessage {...candidateDrawerMessage.title} values={{ entityType }} />: {jobTitle},&nbsp;
            <FormattedMessage {...candidateDrawerMessage.idLabel} />
            :&nbsp;
            <Tooltip title={!isJobAssignedToCurrentUser ? `${entityType} is not shared with you` : ''}>
              <div
                role="presentation"
                onClick={handleCandidateView}
                onKeyPress={handleCandidateView}
                style={!isJobAssignedToCurrentUser ? { cursor: 'not-allowed' } : {}}
              >
                <u>{version === 'ats' ? jobCode : jobId}</u>
              </div>
            </Tooltip>
          </div>
        )}
        <div className={styles.candidateJobInfo}></div>
        <div className={styles.contentContainer}>
          <p className={styles.phoneNumber}>
            {infoText} {candidatePhoneNumber}
          </p>
          <small>{sentTime}</small>
        </div>
        <div className={styles.contentContainer}>
          <p className={styles.message} style={isMessageUnRead ? messageDisplayStyle : {}}>
            {createdByName && <span style={{ fontWeight: 500, color: '#1F2730' }}>{createdByName}: </span>}
            {displayMessage}
          </p>
          <p className={styles.status} style={isMessageUnRead ? messageDisplayStyle : statusColor}>
            {isMessageUnRead ? (
              <FormattedMessage {...candidateDrawerMessage.unreadMessagesLabel} values={{ unreadSmsCount }} />
            ) : (
              messageStatus
            )}
          </p>
        </div>
      </div>
    </Skeleton>
  );
};

export default connect(mapStateToProps, null)(Candidate360MessageCard);
export { Candidate360MessageCard as Candidate360MessageCardWithoutStore };

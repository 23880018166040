import React from 'react';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import JobBoardSettings from '../../Components/JobBoardSettings/JobBoardSettings';
import messages from '../../Components/ApplicationSettings/messages';
import * as ConfigActions from '../../Actions/ConfigActions';
import {
  getConfig,
  getPortalCountries,
  getWhiteLabelInformation,
  getPortalsConfig,
} from '../../Reducers/ConfigReducer';
import styles from './JobBoardSettingsContainer.module.scss';

const mapStateToProps = state => ({
  userConfig: getConfig(state),
  portalCountries: getPortalCountries(state),
  whiteLabelInfo: getWhiteLabelInformation(state),
  portalsConfig: getPortalsConfig(state),
});

const mapDispatchToProps = {
  integratePortal: ConfigActions.integratePortal,
  disintegratePortal: ConfigActions.disintegratePortal,
  integrateSource: ConfigActions.integrateSource,
};

function JobBoardSettingsContainer(props) {
  const {
    userConfig,
    portalCountries,
    integrateSource,
    whiteLabelInfo,
    integratePortal,
    disintegratePortal,
    portalsConfig,
  } = props;

  let jobBoards = userConfig?.Sources?.filter(item => {
    if (['social', 'internal'].includes(item?.Source?.Portal?.toLowerCase())) return false;
    if (item?.Source?.Portal?.toLowerCase() === 'indeed') return item?.IsAuthorized && item?.IsEnabled;
    return true;
  }).map(item => item?.Source?.Portal || item?.Source?.Group);
  jobBoards = _.uniq(jobBoards);
  const sourceConfigsByName = userConfig?.SourceConfigsByName ?? [];
  const AppName = whiteLabelInfo?.AppName ?? 'Leoforce';
  const aryaVersion = userConfig?.SubscriptionType ?? '';

  return jobBoards?.length && aryaVersion !== 'Lite' ? (
    <>
      <div className={styles.jobboardSettings}>
        <div>
          <div className={styles.integrationTitle}>
            <FormattedMessage {...messages.jobBoardIntegration} />
          </div>
          <div className={styles.integrationMessage}>
            <FormattedMessage {...messages.jobBoardIntegrationMessage} values={{ AppName }} />
          </div>
        </div>
      </div>
      <div>
        {jobBoards?.map(jobBoard => (
          <JobBoardSettings
            jobBoard={jobBoard}
            portalSettings={portalsConfig?.[jobBoard] ?? {}}
            authenticate={integratePortal}
            disconnect={disintegratePortal}
            portalCountries={portalCountries?.[jobBoard] ?? {}}
            key={jobBoard}
            integrateSource={integrateSource}
            sourcesConfig={userConfig?.Sources ?? []}
            sourceConfig={sourceConfigsByName?.[jobBoard.toLowerCase()] ?? {}}
            AppName={AppName}
            whiteLabelInfo={whiteLabelInfo}
          />
        ))}
      </div>
    </>
  ) : null;
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(JobBoardSettingsContainer));
export { JobBoardSettingsContainer as JobBoardSettingsContainerWithoutStore };

/* eslint-disable react/no-danger */
import React from 'react';
import { Tooltip } from 'antd';
import DOMPurify from 'dompurify';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { getChatTextHistoryRelativeTime } from '../../../Utils/RelativeTimeCalculator';
import linkify from '../../../Utils/HyperLinkUtils';
import './ChatTextHistory.scss';
import { messageCount, getMessageDeliveryStatusInformation, getMessageDeliveryStatusIcon } from '../../Utils/SmsUtils';
import messages from '../../../Containers/ManualSearchV2/ManualSearchMessages';
import candidateDrawerMessage from '../../../Containers/CandidateDrawer/messages';

export default class ChatTextHistory extends React.Component {
  static MessageBubble = ({
    message,
    align,
    timestamp,
    className,
    type,
    DeliveryStatus,
    readOnly,
    createdByName,
    RequestStatus,
    messageDeliveryStatusInformation,
    hideTextFooter = false,
    jobsBasicInfoById,
    basicInfoJobGuidToIdMapping,
    refId,
    personId,
    openJobViewInNewTabCallBack,
    openCandidateView,
    version,
    candidate,
  }) => {
    const getMessageStatusIconWithToolTip = status => {
      const deliveryIcon = getMessageDeliveryStatusIcon(status, messageDeliveryStatusInformation, RequestStatus);
      const title =
        status.toLowerCase() === 'delivered'
          ? ''
          : getMessageDeliveryStatusInformation(messageDeliveryStatusInformation, status);
      return (
        <Tooltip title={title} placement="bottomLeft" zIndex={222322}>
          {deliveryIcon}
        </Tooltip>
      );
    };

    const isPending = DeliveryStatus === 'Pending';
    const finalMessage = readOnly
      ? `<div><b>${createdByName || ''}</b></div>${message}`
      : `<div><b>${createdByName || ''}</b></div>${linkify(message)}`;

    if (type === 'title')
      return (
        <div className="chat-pane-title-wrapper">
          <span className="chat-pane-title"> {message} </span>
        </div>
      );

    if (type === 'date')
      return (
        <div className="connect-message-date-wrapper">
          <div className="connect-message-date">
            {message === 'Today' || message === 'Yesterday' ? <FormattedMessage {...messages[message]} /> : message}
          </div>
        </div>
      );
    const messageStatus = RequestStatus === 'Success' ? DeliveryStatus : RequestStatus;
    const jobId = basicInfoJobGuidToIdMapping?.[refId];
    const jobDetails = jobsBasicInfoById[jobId];
    const jobTitle = jobDetails?.JobTitle;
    const entityType = jobDetails?.EntityType;
    const isJobAssignedToCurrentUser = jobDetails?.IsJobAssignedToCurrentUser;
    const jobCode = jobDetails?.JobCode || 'N/A';

    const handleCandidateView = e => {
      if (version === 'ats' && jobCode !== 'N/A') openJobViewInNewTabCallBack(jobCode);
      else if (isJobAssignedToCurrentUser) openCandidateView(candidate, jobId, entityType);
      e.stopPropagation();
    };

    if (align === 'right')
      return (
        <div className={`connect-message-bubble-wrapper ${align}`}>
          <div
            className={`connect-message-bubble  ${className} ${isPending ? 'pending' : ''}`}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(finalMessage, { ALLOWED_ATTR: ['target', 'href'] }),
            }}
          ></div>
          <div className={`connect-message-bubble-time ${align}`}>
            <span
              style={{
                color: messageStatus === 'Failed' ? '#eb4334' : '#1F2730',
                fontSize: '12px',
                fontStyle: 'italic',
              }}
            >
              {personId && (
                <span>
                  <FormattedMessage {...candidateDrawerMessage.jobTitleLabel} values={{ entityType }} />: {jobTitle}
                  ,&nbsp;
                  <FormattedMessage {...candidateDrawerMessage.idLabel} />
                  :&nbsp;
                  <Tooltip
                    title={!isJobAssignedToCurrentUser ? `${entityType} is not shared with you` : ''}
                    zIndex={100000}
                  >
                    <div
                      role="presentation"
                      onClick={handleCandidateView}
                      onKeyPress={handleCandidateView}
                      style={{
                        display: 'inline-block',
                        width: 'fit-content',
                        ...(isJobAssignedToCurrentUser ? {} : { cursor: 'not-allowed' }),
                      }}
                    >
                      <u>{version === 'ats' ? jobCode : jobId}</u>
                    </div>
                  </Tooltip>
                  &nbsp;|
                </span>
              )}
              {!hideTextFooter && (
                <>
                  {messageCount(message)}{' '}
                  {getMessageStatusIconWithToolTip(messageStatus === 'Success' ? 'Delivered' : messageStatus)}
                </>
              )}{' '}
            </span>
            <small>{getChatTextHistoryRelativeTime(timestamp)}</small>
          </div>
        </div>
      );

    return (
      <div className={`connect-message-bubble-wrapper ${align}`}>
        <div
          className={`connect-message-bubble ${className} ${isPending ? 'pending' : ''}`}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(finalMessage, { ALLOWED_ATTR: ['target', 'href'] }),
          }}
        ></div>
        <div className={`connect-message-bubble-time ${align}`}>{getChatTextHistoryRelativeTime(timestamp)}</div>
      </div>
    );
  };

  constructor(props) {
    super(props);
    this.messagesWrapper = React.createRef();
    this.scrollChatToBottom = this.scrollChatToBottom.bind(this);
  }

  componentDidUpdate() {
    this.scrollChatToBottom();
  }

  componentDidMount() {
    this.scrollChatToBottom();
  }

  scrollChatToBottom() {
    const messageContainer = document.querySelector('.inner-messages-wrapper');
    if (messageContainer.hasChildNodes()) {
      const lastMessage = messageContainer.lastElementChild;
      lastMessage.scrollIntoView();
    }
  }

  render() {
    const { children, className, isComposeMessageDisabled } = this.props;
    return (
      <div ref={this.messagesWrapper} className={`connect-message-window-flex-items messages-wrapper ${className}`}>
        <div
          className={classNames('inner-messages-wrapper', {
            composeMessageDisabled: isComposeMessageDisabled,
          })}
        >
          {children}
        </div>
      </div>
    );
  }
}

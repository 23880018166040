import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import qs from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { configureCognito } from '../../Cognito/Configure';
import { generateMagicLink } from '../../Actions/UserActions';
import { getMagicLinkSuccessContent } from '../../Components/Auth/PulseLogin/MagicLinkSuccessComponent';
import BrandMessageDisplayCard from '../../Components/BrandMessageDisplayCard/BrandMessageDisplayCard';
import { getApiStatus } from '../../Reducers/ApiStatusReducer';
import { monitorUserEmailActivity } from '../../Actions/UserActivityActions';
import styles from './MagicSignIn.module.scss';

configureCognito();

const getContent = (signInStatus, email, type = 'default') => {
  const dispatch = useDispatch();
  const generateMagicLinkApiStatus = useSelector(state => getApiStatus(state, 'generateMagicLinkApiStatus'));
  const isGenerateLinkButtonDisabled = generateMagicLinkApiStatus === 'SUCCESS';
  const isLoading = generateMagicLinkApiStatus === 'INPROGRESS';
  const onBackToLogin = () => {
    localStorage.clear();
    window.location.href = '/';
  };
  const onGenerateLink = () => {
    dispatch(
      generateMagicLink({
        Email: email?.trim().toLowerCase(),
        IsTryNow: false,
      })
    );
  };
  if (signInStatus === null || signInStatus === 'PENDING' || signInStatus === 'SUCCESS') {
    return (
      <div className={styles.loader}>
        <img
          src={`${process.env.PUBLIC_URL}/static/Images/aryaloader.gif`}
          height="65px"
          width="65px"
          alt="Leoforce loading"
        />
        <div className={styles.loaderHeaderContent}>Great things are happening. Please standby.</div>
        <div className={styles.loaderContent}>
          We are busy loading your data...might be a good time to stand up and stretch
        </div>
      </div>
    );
  }

  if (signInStatus === 'ERROR') {
    const { description } = getMagicLinkSuccessContent(type);
    const messageTitle = generateMagicLinkApiStatus === 'SUCCESS' ? 'Link sent successfully' : 'Link is invalid';
    const messageContent =
      generateMagicLinkApiStatus === 'SUCCESS'
        ? description
        : 'The existing link might have expired or already used. Please generate a new link and try logging in again.';
    const invalidLinkDisplayCardProps = {
      messageTitle,
      messageContent,
      confirmButtonContent: 'Back to Login',
      generateLinkButtonContent: 'Generate new link',
      isGenerateLinkButtonDisabled,
      isLoading,
      onConfirmClick: onBackToLogin,
      onGenerateLink,
    };

    return <BrandMessageDisplayCard {...invalidLinkDisplayCardProps} />;
  }

  const displayCardProps = {
    messageTitle: ' Something went wrong',
    messageContent: 'Please try again later or generate a new link to login.',
    confirmButtonContent: 'Back to Login',
    generateLinkButtonContent: 'Generate new link',
    isGenerateLinkButtonDisabled,
    isLoading,
    onConfirmClick: onBackToLogin,
    onGenerateLink,
  };
  return <BrandMessageDisplayCard {...displayCardProps} />;
};

function MagicSignIn(props) {
  const { location, history } = props;
  const { email, code, emailType } = qs.parse(location.search);
  const dispatch = useDispatch();

  const [signInStatus, setSignInStatus] = useState(null);

  const userEmailActivityPayload = {
    EmailType: emailType,
    Action: 'Clicked',
    Email: email,
  };

  async function authSignin() {
    Auth.configure({ authenticationFlowType: 'CUSTOM_AUTH' });
    setSignInStatus('PENDING');
    try {
      const user = await Auth.signIn({ username: email });
      if (user.challengeName === 'CUSTOM_CHALLENGE') {
        await Auth.sendCustomChallengeAnswer(user, code);
        setSignInStatus('SUCCESS');
        userEmailActivityPayload.Status = 'SUCCESS';
      } else {
        setSignInStatus('INAVLID_ROUTE');
        userEmailActivityPayload.Status = 'INAVLID_ROUTE';
      }
    } catch (e) {
      setSignInStatus('ERROR');
      userEmailActivityPayload.Status = 'ERROR';
    }
    dispatch(monitorUserEmailActivity(userEmailActivityPayload));
  }

  useEffect(() => {
    authSignin();
  }, []);

  useEffect(() => {
    if (signInStatus === 'SUCCESS') {
      history.push('/');
    }
  }, [signInStatus]);

  const content = getContent(signInStatus, email);

  return (
    <div className={styles.magicSignInContainer}>
      <div className={styles.magicSignInContent}>{content}</div>
    </div>
  );
}

export default withRouter(MagicSignIn);

import React from 'react';
import { Pagination, Skeleton } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import qs from 'query-string';
import JobList from '../../Components/JobList/JobList';
import JobListHeader from '../../Components/JobListHeader/JobListHeader';
import PullJobsDialog from '../../Components/PullJobsDialog/PullJobsDialog';
import ActivationDialogContainer from '../ActivationDialog/ActivationDialog';
import AnnouncementBanner from '../AnnouncementBanner/AnnouncementBanner';
import styles from './JobList.module.scss';
import * as AtsActions from '../../Actions/AtsActions';
import * as JobActions from '../../Actions/JobActions';
import * as ScoutingAgentActions from '../../Actions/ScoutingAgentActions';
import * as UserActions from '../../Actions/UserActions';
import * as JobStatusActions from '../../Actions/JobStatusActions';
import * as PayOrderActions from '../../Actions/AryaPayOrderActions';
import { getConfig, getVaults, getWhiteLabelInformation } from '../../Reducers/ConfigReducer';
import {
  getJobsIdsByStatus,
  getJobsById,
  getJobUtilities,
  getSelectedTags,
  getJobListPageSize,
  getJobListPageNumber,
  getJobListSearchTerm,
  getDefaultHomePageRenderFlag,
  getJobListSelectedUser,
} from '../../Reducers/JobReducer';
import { getCurrentJobStatusId, getJobStatus } from '../../Reducers/JobStatusReducer';
import { getUsersById, getUsersByGuId } from '../../Reducers/UserReducer';
import { getCurrentUser } from '../../Reducers/UserSessionReducer';
import { getApiStatus } from '../../Reducers/ApiStatusReducer';
import { getScoutingAgentDrawerStatus, getScoutingAgentConfig } from '../../Reducers/ScoutingAgentReducer';
import { getAtsConfig } from '../../Reducers/AtsReducer';
import { getFeatureToggleList } from '../../Reducers/FeatureToggleReducer.ts';
import { getBulkOrderStatuses } from '../../Reducers/OrderReducer';
import JobLanding from '../../Components/JobLanding/JobLanding';
import { getProductVariantsById, getPlanDetailsByProductVariantId } from '../../Reducers/AryaPayOrderReducer';
import { getUserByUserGuid } from '../../Utils/UserInfo';
import { getAccountTypes } from '../../Reducers/OrganizationsReducer';
import SelectJobCreation from '../../Components/SelectJobCreation/SelectJobCreation';
import { logSmartKarrotEvent } from '../../Analytics/EventUtils';
import eventTypes, { modules } from '../../Analytics/EventTypes';

const mapStateToProps = state => ({
  jobStatus: getJobStatus(state),
  currentJobStatusId: getCurrentJobStatusId(state),
  jobsIdsByStatus: getJobsIdsByStatus(state),
  jobsById: getJobsById(state),
  userConfig: getConfig(state) || {},
  usersById: getUsersById(state),
  jobListStatus: getApiStatus(state, 'jobListStatus'),
  jobCountByStatusesApiStatus: getApiStatus(state, 'jobCountByStatusesApiStatus'),
  updatePasswordApiStatus: getApiStatus(state, 'updatePasswordApiStatus'),
  utilities: getJobUtilities(state),
  atsConfig: getAtsConfig(state),
  vaults: getVaults(state),
  selectedTags: getSelectedTags(state),
  featureToggleList: getFeatureToggleList(state),
  ordersStatuses: getBulkOrderStatuses(state),
  whiteLabelInfo: getWhiteLabelInformation(state),
  productVariantsById: getProductVariantsById(state),
  planDetailsByProductVariantId: getPlanDetailsByProductVariantId(state),
  pageSize: getJobListPageSize(state),
  page: getJobListPageNumber(state),
  searchTerm: getJobListSearchTerm(state, 'job'),
  defaultHomePageRenderFlag: getDefaultHomePageRenderFlag(state),
  selectedUser: getJobListSelectedUser(state),
  currentUser: getCurrentUser(state),
  accountTypes: getAccountTypes(state),
  scoutingAgentDrawerStatus: getScoutingAgentDrawerStatus(state),
  scoutingAgentConfig: getScoutingAgentConfig(state),
  userByGuid: getUsersByGuId(state),
});
const mapDispatchToProps = {
  fetchJobStatus: JobStatusActions.fetchJobStatus,
  fetchJobCountByStatus: JobStatusActions.fetchJobCountByStatus,
  setCurrentJobStatusId: JobStatusActions.setCurrentJobStatusId,
  fetchJobList: JobActions.fetchJobList,
  fetchJobSourcingStats: JobActions.fetchJobSourcingStats,
  fetchActivationStatus: JobActions.fetchActivationStatus,
  changeJobStatus: JobActions.changeJobStatus,
  fetchJobScoutingAgent: ScoutingAgentActions.fetchJobScoutingAgent,
  fetchScoutingAgentsByJobIds: ScoutingAgentActions.fetchScoutingAgentsByJobIds,
  setScoutingAgentDrawerVisibility: ScoutingAgentActions.setScoutingAgentDrawerVisibility,
  pullJobs: AtsActions.pullJobs,
  toggleJobCard: JobActions.toggleJobCard,
  fetchPlanDetailsByProductVariantId: PayOrderActions.fetchPlanDetailsByProductVariantId,
  setJobListPageSize: JobActions.setJobListPageSize,
  setJobListPageNumber: JobActions.setJobListPageNumber,
  setJobListSearchTerm: JobActions.setJobListSearchTerm,
  setDefaultHomePageRenderFlag: JobActions.setDefaultHomePageRenderFlag,
  setJobListSelectedUser: JobActions.setJobListSelectedUser,
  updateUserPassword: UserActions.updateUserPassword,
  resetOrderDetails: PayOrderActions.resetOrderDetails,
  resetOrderDetailsWithoutTax: PayOrderActions.resetOrderDetailsWithoutTax,
};

class JobListContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initialJobListRender: true,
    };
    this.onStatusChange = this.onStatusChange.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.onShowSizeChange = this.onShowSizeChange.bind(this);
    this.onJobStatusChange = this.onJobStatusChange.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.getJobStats = this.getJobStats.bind(this);
    this.onSourcingProgressView = this.onSourcingProgressView.bind(this);
    this.closeActivationDialog = this.closeActivationDialog.bind(this);
    this.showPullJobDialog = this.showPullJobDialog.bind(this);
    this.closePullJobsDialog = this.closePullJobsDialog.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.onPullJobs = this.onPullJobs.bind(this);
    this.fetchJobsByUser = this.fetchJobsByUser.bind(this);
  }

  componentDidMount() {
    const {
      fetchPlanDetailsByProductVariantId,
      resetOrderDetails,
      resetOrderDetailsWithoutTax,
      jobsById,
      fetchScoutingAgentsByJobIds,
      featureToggleList,
    } = this.props;
    resetOrderDetails();
    resetOrderDetailsWithoutTax();
    this.fetchJobList(true);
    fetchPlanDetailsByProductVariantId();
    this.setState({ initialJobListRender: false });
    logSmartKarrotEvent(eventTypes.home.homePage.viewHomePage, {
      Module: modules.home.viewHomePage,
    });
    const isScoutingAgentEnabled = _.get(featureToggleList, ['ScoutingAgent', 'IsEnabled'], false);
    if (isScoutingAgentEnabled) {
      fetchScoutingAgentsByJobIds(Object.keys(jobsById));
    }
  }

  componentDidUpdate(prevProps) {
    const {
      userConfig,
      location,
      defaultHomePageRenderFlag,
      setDefaultHomePageRenderFlag,
      fetchScoutingAgentsByJobIds,
      jobsById,
      featureToggleList: currentFeatureToggleList,
    } = this.props;
    const { jobsById: prevJobsById } = prevProps;
    const { search } = location;
    const { isAssigned } = qs.parse(search);
    if (
      (userConfig &&
        userConfig.Role === 'Service Rep' &&
        isAssigned !== qs.parse(prevProps.location.search).isAssigned) ||
      (!prevProps.defaultHomePageRenderFlag && defaultHomePageRenderFlag)
    ) {
      this.fetchJobList(true);
      setDefaultHomePageRenderFlag(false);
    }
    const isScoutingAgentEnabled = _.get(currentFeatureToggleList, ['ScoutingAgent', 'IsEnabled'], false);
    if (Object.keys(jobsById).length !== Object.keys(prevJobsById).length && isScoutingAgentEnabled)
      fetchScoutingAgentsByJobIds(Object.keys(jobsById));
  }

  setIsAssignedValue(selectedUser) {
    const { location } = this.props;
    const { search } = location;
    const { isAssigned } = qs.parse(search);
    if (isAssigned === 'false') return false;
    if (selectedUser === 'all-users') return null;
    return selectedUser !== 'unassigned-jobs';
  }

  getJobListFetchPayload = ({ isJobStatsRequired }) => {
    const { searchTerm, currentJobStatusId, selectedTags, page, pageSize, selectedUser } = this.props;
    const selectedTagIds = (selectedTags || []).map(tag => tag.Id);
    return {
      statusId: currentJobStatusId,
      count: pageSize,
      skip: page >= 1 ? (page - 1) * pageSize : 0,
      userId: selectedUser === 'unassigned-jobs' || selectedUser === 'all-users' ? -1 : selectedUser,
      searchTerm: searchTerm?.trim(),
      isAssigned: this.setIsAssignedValue(selectedUser),
      tagIds: selectedTagIds,
      EntityType: 'Job',
      isJobStatsRequired,
    };
  };

  getJobTagsFetchPayload = () => {
    const { currentJobStatusId, selectedUser } = this.props;
    return {
      statusId: currentJobStatusId,
      userId: selectedUser === 'unassigned-jobs' || selectedUser === 'all-users' ? -1 : selectedUser,
      isAssigned: this.setIsAssignedValue(selectedUser),
    };
  };

  fetchJobList = isFetchJobsCount => {
    const { fetchJobList } = this.props;
    localStorage.removeItem('DecemberOffer');
    localStorage.removeItem('SampleCandidatesDisplayFlag');
    const jobListFetchPayload = this.getJobListFetchPayload({ isJobStatsRequired: isFetchJobsCount });
    fetchJobList(jobListFetchPayload);
    // if (isFetchJobsCount) fetchJobCountByStatus(jobListFetchPayload);
  };

  async onStatusChange(newStatus) {
    const { setCurrentJobStatusId, setJobListPageNumber } = this.props;
    await setJobListPageNumber(1);
    await setCurrentJobStatusId(newStatus);
    this.fetchJobList();
  }

  onSearch(value) {
    const { searchTerm } = this.props;
    if (searchTerm.trim().length && value.length) {
      this.fetchJobList(true);
    }
  }

  async onPageChange(page) {
    const { setJobListPageNumber } = this.props;
    await setJobListPageNumber(page);
    this.fetchJobList();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  async onShowSizeChange(size) {
    const { setJobListPageSize } = this.props;
    await setJobListPageSize(size);
    this.fetchJobList();
  }

  async onJobStatusChange(jobId, value, setPreviousJobStatusValue) {
    const { changeJobStatus, jobStatus, page, pageSize } = this.props;
    const filter = this.getJobListFetchPayload({ isJobStatsRequired: true });
    const status = jobStatus.find(elem => elem.Name === value);
    if (page !== 1 && (status.Count || 0) % pageSize === 1) {
      await this.onPageChange(page - 1, pageSize);
    }
    await changeJobStatus(
      jobId,
      status,
      { ...filter, skip: Math.max(page - 1, 0) * pageSize },
      false,
      setPreviousJobStatusValue
    );
  }

  async onSearchChange(event) {
    const { setJobListPageNumber, setJobListSearchTerm } = this.props;
    const searchTerm = event.target.value;
    await setJobListPageNumber(1);
    await setJobListSearchTerm(searchTerm, 'job');
    if (!searchTerm) {
      this.fetchJobList(true);
    }
  }

  fetchJobsOnAppliedFilter = async () => {
    const { setJobListPageNumber } = this.props;
    await setJobListPageNumber(1);
    this.fetchJobList(true);
  };

  onSourcingProgressView(jobId) {
    this.setState({
      activationDialogVisibility: true,
      jobIdForActivationDialog: jobId,
    });
  }

  onPullJobs(jobIds) {
    const { pullJobs, userConfig } = this.props;
    const jobIdList = jobIds.split(',');
    pullJobs(jobIdList, _.get(userConfig, ['Vaults', 0], null));
    this.closePullJobsDialog();
  }

  async fetchJobsByUser(userId) {
    const { setJobListPageNumber, setJobListSelectedUser } = this.props;
    await setJobListSelectedUser(userId);
    await setJobListPageNumber(1);
    this.fetchJobList(true);
  }

  getJobStats(JobIds) {
    const { fetchJobSourcingStats, fetchActivationStatus } = this.props;
    fetchJobSourcingStats(JobIds);
    fetchActivationStatus(JobIds);
  }

  async clearSearch() {
    const { setJobListPageNumber, setJobListSearchTerm } = this.props;
    await setJobListSearchTerm('', 'job');
    await setJobListPageNumber(1);
    this.fetchJobList(true);
  }

  closeActivationDialog() {
    this.setState({
      activationDialogVisibility: false,
    });
  }

  showPullJobDialog() {
    this.setState({
      pullJobsDialogVisibility: true,
    });
  }

  closePullJobsDialog() {
    this.setState({
      pullJobsDialogVisibility: false,
    });
  }

  updatePassword = password => {
    const { currentUser, updateUserPassword } = this.props;
    const email = _.get(currentUser, 'email');
    updateUserPassword({ Username: email, Password: password });
  };

  render() {
    const {
      jobStatus,
      currentJobStatusId,
      jobsIdsByStatus,
      jobsById,
      usersById,
      history,
      jobListStatus,
      utilities,
      userConfig,
      atsConfig,
      vaults,
      toggleJobCard,
      featureToggleList,
      ordersStatuses,
      whiteLabelInfo,
      jobCountByStatusesApiStatus,
      productVariantsById,
      planDetailsByProductVariantId,
      page,
      searchTerm,
      pageSize,
      selectedUser,
      currentUser,
      fetchJobScoutingAgent,
      scoutingAgentDrawerStatus,
      setScoutingAgentDrawerVisibility,
      scoutingAgentConfig,
      userByGuid,
    } = this.props;
    const { jobIdForActivationDialog, activationDialogVisibility, pullJobsDialogVisibility, initialJobListRender } =
      this.state;
    const AppName = _.get(whiteLabelInfo, ['AppName'], 'Leoforce');
    const jobIds = jobsIdsByStatus[currentJobStatusId] || [];
    const jobs = jobIds.map(jobId => jobsById[jobId]);
    const totalJobsCount = jobStatus.reduce((total, current) => total + (current.Count ? current.Count : 0), 0);
    const isPaidJobServiceEnabled = _.get(featureToggleList, ['PaidJobService', 'IsEnabled'], false);
    const isAdvancedJob = featureToggleList.AdvancedJobCreation.IsEnabled;
    const jobListStatusLoading = jobListStatus !== 'COMPLETED' && jobListStatus !== 'FAILED';
    const jobStatusesApiLoading =
      jobCountByStatusesApiStatus !== 'COMPLETED' && jobCountByStatusesApiStatus !== 'FAILED';

    const isJobListLoading = jobListStatusLoading || jobStatusesApiLoading || initialJobListRender;
    const currentUserGuid = _.get(currentUser, 'sub');
    const userInfo = getUserByUserGuid(usersById, currentUserGuid);
    const isTryNowUser = _.get(userInfo, 'IsTryNow');
    if (isTryNowUser) {
      history.push('/_trynow');
    }
    let currentJobStatusObject = {};
    if (currentJobStatusId !== -1) {
      currentJobStatusObject = jobStatus.find(status => status.Id === currentJobStatusId) || {};
    } else {
      currentJobStatusObject.Count = totalJobsCount;
    }

    if (totalJobsCount === 0 && isPaidJobServiceEnabled && !jobListStatusLoading && !jobStatusesApiLoading) {
      return (
        <>
          {isAdvancedJob ? (
            <SelectJobCreation isStepperRequired />
          ) : (
            <JobLanding
              history={history}
              planDetailsByProductVariantId={planDetailsByProductVariantId}
              productVariantsById={productVariantsById}
              featureToggleList={featureToggleList}
            />
          )}
        </>
      );
    }
    return (
      <>
        <AnnouncementBanner />
        <ActivationDialogContainer
          jobId={jobIdForActivationDialog}
          visible={activationDialogVisibility}
          handleCancel={this.closeActivationDialog}
          job={_.get(jobsById, `[${jobIdForActivationDialog}]`, {})}
          aryaVersion={_.get(userConfig, 'SubscriptionType')}
          type="job"
          onStatusChange={this.onStatusChange}
        />
        <PullJobsDialog
          visible={pullJobsDialogVisibility}
          handleCancel={this.closePullJobsDialog}
          onPullJobs={this.onPullJobs}
          AppName={AppName}
        />
        <div id={styles.jobList}>
          <Skeleton active paragraph={{ rows: 16 }} loading={isPaidJobServiceEnabled && isJobListLoading}>
            <JobListHeader
              jobStatus={jobStatus}
              jobCount={currentJobStatusObject.Count || 0}
              fetchJobsOnAppliedFilter={this.fetchJobsOnAppliedFilter}
              onSearch={this.onSearch}
              currentJobStatusId={currentJobStatusId}
              onStatusChange={this.onStatusChange}
              onChange={this.onSearchChange}
              addNewJob={this.addNewJob}
              searchTerm={searchTerm}
              currStatusCount={currentJobStatusObject.Count}
              showPullJobDialog={this.showPullJobDialog}
              history={history}
              userConfig={userConfig}
              atsConfig={atsConfig}
              isVaultConfigured={vaults ? vaults.length > 0 : false}
              featureToggleList={featureToggleList}
              select
              selectedUser={selectedUser}
              fetchJobsByUser={this.fetchJobsByUser}
              productVariantsById={productVariantsById}
              planDetailsByProductVariantId={planDetailsByProductVariantId}
              userByGuid={userByGuid}
            />
            <Skeleton active paragraph={{ rows: 8 }} loading={isJobListLoading}>
              <JobList
                toggleJobCard={toggleJobCard}
                jobs={jobs}
                scoutingAgentConfig={scoutingAgentConfig}
                fetchJobScoutingAgent={fetchJobScoutingAgent}
                getScoutingAgentDrawerStatus={scoutingAgentDrawerStatus}
                setScoutingAgentDrawerVisibility={setScoutingAgentDrawerVisibility}
                usersById={usersById}
                jobStatus={jobStatus}
                onJobStatusChange={this.onJobStatusChange}
                onSourcingProgressView={this.onSourcingProgressView}
                history={history}
                searchTerm={searchTerm}
                clearSearch={this.clearSearch}
                currStatus={_.get(currentJobStatusObject, 'Name', '')}
                countries={_.get(utilities, 'countries', [])}
                userConfig={userConfig}
                featureToggleList={featureToggleList}
                ordersStatuses={ordersStatuses}
                AppName={AppName}
                productVariantsById={productVariantsById}
              />
            </Skeleton>
            <div className={styles.paginationContainer}>
              <Pagination
                showSizeChanger
                size="small"
                pageSize={pageSize}
                total={currentJobStatusObject.Count}
                onChange={this.onPageChange}
                onShowSizeChange={(current, size) => this.onShowSizeChange(size)}
                hideOnSinglePage={currentJobStatusObject.Count <= 10}
                current={page}
                disabled={
                  jobListStatus !== 'COMPLETED' && jobListStatus !== 'FAILED' && !jobsIdsByStatus[currentJobStatusId]
                }
              />
            </div>
          </Skeleton>
        </div>
      </>
    );
  }
}

JobListContainer.propTypes = {
  jobStatus: PropTypes.arrayOf(PropTypes.shape({})),
  currentJobStatusId: PropTypes.number,
  jobEmailStats: PropTypes.shape({
    jobId: PropTypes.string,
  }),
  jobSourcingStats: PropTypes.shape({
    jobId: PropTypes.string,
  }),
  jobActivationStatus: PropTypes.shape({
    jobId: PropTypes.string,
  }),
  // fetchJobStatus: PropTypes.func.isRequired,
  // fetchJobCountByStatus: PropTypes.func.isRequired,
  fetchJobList: PropTypes.func.isRequired,
  fetchActivationStatus: PropTypes.func.isRequired,
  fetchJobSourcingStats: PropTypes.func.isRequired,
  changeJobStatus: PropTypes.func.isRequired,
  jobsIdsByStatus: PropTypes.shape({}),
  jobsById: PropTypes.shape({}),
};

JobListContainer.defaultProps = {
  jobStatus: [],
  currentJobStatusId: undefined,
  jobEmailStats: {},
  jobSourcingStats: {},
  jobActivationStatus: {},
  jobsIdsByStatus: {},
  jobsById: {},
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(JobListContainer));

export { JobListContainer as JobListContainerWithoutStore };

/* window */
import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Input, Form, Upload, Icon, Button, message } from 'antd';
import { injectIntl, FormattedMessage } from 'react-intl';
import * as ConnectSettingsActions from '../../Actions/ConnectSettingsActions';
import { getConnectSettings } from '../../Reducers/ConnectSettingsReducer';
import { getApiStatus } from '../../Reducers/ApiStatusReducer';
import { getWhiteLabelInformation } from '../../Reducers/ConfigReducer';
import convertBlobToBase64 from '../../Utils/ConvertBlobToBase64';
import convertBase64ToFile from '../../Utils/ConvertBase64ToFile';
import messages from './messages';
import applicationSettingMessages from '../ApplicationSettings/ApplicationSettingMessages';
import './BotSettings.scss';
import eventTypes from '../../Analytics/EventTypes';
import { setNotification } from '../../Actions/ActionCreators/ConnectActions';

const { TextArea } = Input;
const FormItem = Form.Item;

message.config({
  top: 100,
  duration: 1,
  maxCount: 1,
});

const mapStateToProps = state => ({
  settings: _.get(getConnectSettings(state), 'BotSettings', {}),
  updateBotDetailsApiStatus: getApiStatus(state, 'updateBotDetailsStatus'),
  whiteLabelInfo: getWhiteLabelInformation(state),
});

const mapDispatchToProps = {
  updateBotDetails: ConnectSettingsActions.updateBotDetails,
  fetchBotDetails: ConnectSettingsActions.fetchBotDetails,
  SetNotification: setNotification,
};

class BotSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.normFile = this.normFile.bind(this);
    this.onSave = this.onSave.bind(this);
    this.isSaveDisabled = this.isSaveDisabled.bind(this);
  }

  componentDidMount() {
    const { fetchBotDetails } = this.props;
    fetchBotDetails();
  }

  onSave() {
    const { updateBotDetails, form, settings } = this.props;
    const { base64Icon, base64Favicon } = this.state;
    const botDetails = form.getFieldsValue();
    botDetails.Icon = botDetails.Icon[0] ? botDetails.Icon[0].originFileObj : undefined;
    botDetails.Favicon = botDetails.Favicon[0] ? botDetails.Favicon[0].originFileObj : undefined;
    const formData = new FormData();
    formData.set('Title', botDetails.Title || '');
    formData.set('Name', botDetails.Name || '');
    formData.set('Location', botDetails.Location || '');
    formData.set('Description', botDetails.Description || '');
    formData.set('GreetingMessage', botDetails.GreetingMessage || '');
    formData.set('GoodbyeMessage', botDetails.GoodByeMessage || '');
    formData.set('Icon', botDetails.Icon);
    formData.set('Favicon', botDetails.Favicon);
    if (base64Icon !== undefined) {
      botDetails.Icon = base64Icon;
    } else {
      botDetails.Icon = settings.Icon;
    }
    if (base64Favicon !== undefined) {
      botDetails.Favicon = base64Favicon;
    } else {
      botDetails.Favicon = settings.Favicon;
    }
    updateBotDetails(formData, botDetails);
  }

  normFile(e, iconType) {
    const { SetNotification } = this.props;
    const newState = {};
    if (Array.isArray(e)) {
      return e;
    }
    if (_.get(e, ['fileList', 0], false)) {
      const isLt5K = e.fileList[0].size / 1024 < 5;
      if (!isLt5K) {
        SetNotification('ERROR', { messageId: 'imageMustBeSmallerThan5KB' });
        return e && [];
      }
      convertBlobToBase64(e.fileList[0].originFileObj).then(result => {
        newState[`base64${iconType}`] = result;
        this.setState({ ...newState });
      });
    } else {
      newState[`base64${iconType}`] = null;
      this.setState({ ...newState });
    }
    return e && e.fileList;
  }

  isSaveDisabled() {
    const { form, settings } = this.props;
    const { base64Icon, base64Favicon } = this.state;
    const botDetails = form.getFieldsValue();
    return Object.keys(botDetails).every(key => {
      if (key === 'Icon') {
        if (base64Icon !== undefined && settings.Icon !== base64Icon) {
          return false;
        }
      } else if (key === 'Favicon') {
        if (base64Favicon !== undefined && settings.Favicon !== base64Favicon) {
          return false;
        }
      } else if (botDetails[key] !== undefined && botDetails[key] !== settings[key]) {
        return false;
      }
      return true;
    });
  }

  render() {
    const { form, settings, updateBotDetailsApiStatus, whiteLabelInfo, intl } = this.props;
    const uploadButton = (
      <div>
        <Icon type="plus" />
        <div className="ant-upload-text">
          <FormattedMessage {...applicationSettingMessages.uploadLabel} />
        </div>
      </div>
    );
    const AppName = _.get(whiteLabelInfo, ['AppName'], 'Leoforce');
    return (
      <div className="bot-settings-app">
        <Form key="BotSettings">
          <div className="bot-settings-title">
            <FormattedMessage {...messages.aryaBotSettings} values={{}} />
          </div>
          <div className="bot-description">
            <FormattedMessage {...applicationSettingMessages.botSettingsSettingUpQuestions} />
          </div>
          <div>
            <div className="bot-ui-settings-title">
              <FormattedMessage {...messages.uiSettings} />
            </div>
            <div className="bot-ui-settings">
              <div>
                <FormItem label={<FormattedMessage {...messages.chatbotTitle} />} colon={false}>
                  {form.getFieldDecorator('Title', {
                    initialValue: settings.Title,
                  })(
                    <Input
                      placeholder={intl.formatMessage({ ...messages.botTitlePlaceholder })}
                      className="bot-input-title"
                    />
                  )}
                </FormItem>
              </div>
              <div className="bot-icon">
                <FormItem label={<FormattedMessage {...messages.botIcon} />} className="bot-icon-text" colon={false}>
                  {form.getFieldDecorator('Icon', {
                    initialValue: settings.Icon
                      ? [
                          {
                            uid: -1,
                            type: 'image',
                            name: 'BotIcon',
                            status: 'done',
                            originFileObj: convertBase64ToFile(settings.Icon),
                            url: URL.createObjectURL(convertBase64ToFile(settings.Icon)),
                          },
                        ]
                      : [],
                    valuePropName: 'fileList',
                    getValueFromEvent: e => this.normFile(e, 'Icon'),
                  })(
                    <Upload
                      accept="image/*"
                      beforeUpload={() => false}
                      listType="picture-card"
                      showUploadList={{ showPreviewIcon: false }}
                    >
                      {form.getFieldValue('Icon').length > 0 ? null : uploadButton}
                    </Upload>
                  )}
                </FormItem>
              </div>
              <div className="bot-icon">
                <FormItem label={<FormattedMessage {...messages.favicon} />} className="bot-icon-text" colon={false}>
                  {form.getFieldDecorator('Favicon', {
                    initialValue: settings.Favicon
                      ? [
                          {
                            uid: -2,
                            name: 'BotFavicon',
                            status: 'done',
                            type: 'image',
                            originFileObj: convertBase64ToFile(settings.Favicon),
                            url: URL.createObjectURL(convertBase64ToFile(settings.Favicon)),
                          },
                        ]
                      : [],
                    valuePropName: 'fileList',
                    getValueFromEvent: e => this.normFile(e, 'Favicon'),
                  })(
                    <Upload
                      accept="image/*"
                      beforeUpload={() => false}
                      listType="picture-card"
                      showUploadList={{ showPreviewIcon: false }}
                    >
                      {form.getFieldValue('Favicon').length > 0 ? null : uploadButton}
                    </Upload>
                  )}
                </FormItem>
              </div>
            </div>
          </div>
          <div>
            <div className="bot-settings-text">
              <FormattedMessage {...messages.botSettings} />
            </div>
            <div className="bot-settings">
              <div>
                <FormItem label={<FormattedMessage {...messages.botName} />} colon={false}>
                  {form.getFieldDecorator('Name', {
                    initialValue: settings.Name,
                  })(
                    <Input
                      placeholder={intl.formatMessage({ ...messages.botNamePlaceholder })}
                      className="bot-input-name"
                    />
                  )}
                </FormItem>
              </div>
              <div>
                <FormItem label={<FormattedMessage {...messages.botLocation} />} colon={false}>
                  {form.getFieldDecorator('Location', { initialValue: settings.Location })(
                    <Input
                      placeholder={intl.formatMessage({ ...messages.botLocationPlaceholder })}
                      className="bot-input-location"
                    />
                  )}
                </FormItem>
              </div>
              <div>
                <FormItem label={<FormattedMessage {...messages.botDescription} />} colon={false}>
                  {form.getFieldDecorator('Description', {
                    initialValue: settings.Description,
                  })(
                    <TextArea
                      placeholder={intl.formatMessage({ ...messages.botDescriptionPlaceholder })}
                      className="bot-input-description"
                    />
                  )}
                </FormItem>
              </div>
              <div>
                <FormItem label={<FormattedMessage {...messages.botGreetingMessage} />} colon={false}>
                  {form.getFieldDecorator('GreetingMessage', {
                    initialValue: settings.GreetingMessage,
                  })(
                    <TextArea
                      placeholder={intl.formatMessage(messages.botGreetingPlaceholder, { AppName })}
                      className="bot-input-greeting"
                    />
                  )}
                </FormItem>
              </div>
              <div>
                <FormItem label={<FormattedMessage {...messages.botGoodbyeMessage} />} colon={false}>
                  {form.getFieldDecorator('GoodByeMessage', {
                    initialValue: settings.GoodByeMessage,
                  })(
                    <TextArea
                      placeholder={intl.formatMessage({ ...messages.botGoodbyePlaceholder })}
                      className="bot-input-goodbye"
                    />
                  )}
                </FormItem>
              </div>
            </div>
          </div>
        </Form>
        <Button
          loading={updateBotDetailsApiStatus === 'InProgress'}
          disabled={this.isSaveDisabled()}
          onClick={this.onSave}
          className="app-save"
          sk-event-name={eventTypes.settings.aryaBotSettings.saveChatBotSettings}
        >
          <FormattedMessage {...applicationSettingMessages.botSettingSaveChangesButton} />
        </Button>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Form.create()(BotSettings)));

export const CREATE_NOTE_POPUP_FOR_CANDIDATE = 'CREATE_NOTE_POPUP_FOR_CANDIDATE';
export const TOGGLE_CANDIDATE_NOTE_POPUP = 'TOGGLE_CANDIDATE_NOTE_POPUP';
export const CLOSE_NOTE_POPUP = 'CLOSE_NOTE_POPUP';
export const UPDATE_NOTE_POPUP = 'UPDATE_NOTE_POPUP';

export function setCandidateNotePopup({ candidate, jobId, candidateContext, vaults }) {
  return {
    type: CREATE_NOTE_POPUP_FOR_CANDIDATE,
    payload: {
      candidate,
      jobId,
      candidateContext,
      vaults,
    },
  };
}

export function toggleNotesPopUp({ notesTabPopUpId, visible, minimized, note }) {
  return {
    type: TOGGLE_CANDIDATE_NOTE_POPUP,
    payload: {
      notesTabPopUpId,
      visible,
      minimized,
      note,
    },
  };
}

export function closeNotesPopUp(notesTabPopUpId) {
  return {
    type: CLOSE_NOTE_POPUP,
    payload: {
      notesTabPopUpId,
    },
  };
}

export function updateNotePopup({ notesTabPopUpId, note }) {
  return {
    type: UPDATE_NOTE_POPUP,
    payload: {
      notesTabPopUpId,
      note,
    },
  };
}

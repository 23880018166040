import cleanSet from 'clean-set';
import {
  CREATE_NOTE_POPUP_FOR_CANDIDATE,
  TOGGLE_CANDIDATE_NOTE_POPUP,
  CLOSE_NOTE_POPUP,
  UPDATE_NOTE_POPUP,
} from '../Actions/ActionCreators/NotesActionCreator';

const initialState = {
  NotesById: {},
  NotesOrder: [],
};

function removeKey(obj, key) {
  const { [key]: _, ...rest } = obj;
  return rest;
}

function NotesReducer(state = initialState, action) {
  let newState;
  if (action.type === CREATE_NOTE_POPUP_FOR_CANDIDATE) {
    const { candidate, jobId, candidateContext, vaults } = action.payload;
    const notesTabPopUpId = `${jobId}_${candidate.Id}`;
    if (state.NotesOrder.includes(notesTabPopUpId)) {
      const existingNotesTabData = state.NotesById[notesTabPopUpId];
      newState = cleanSet(state, ['NotesById', notesTabPopUpId], {
        ...existingNotesTabData,
        visible: true,
        minimized: false,
      });
      return newState;
    }
    const updatedNotesByOrder = [...state.NotesOrder, notesTabPopUpId];
    newState = cleanSet(
      cleanSet(state, ['NotesById', notesTabPopUpId], {
        candidate,
        jobId,
        visible: true,
        minimized: false,
        vaults,
        candidateContext,
      }),
      'NotesOrder',
      updatedNotesByOrder
    );
    return newState;
  }
  if (action.type === TOGGLE_CANDIDATE_NOTE_POPUP) {
    const { notesTabPopUpId, visible, minimized, note } = action.payload;
    const existingNotesTabData = state.NotesById[notesTabPopUpId];
    newState = cleanSet(state, ['NotesById', notesTabPopUpId], {
      ...existingNotesTabData,
      visible,
      minimized,
      note: note ?? existingNotesTabData.note,
    });
    return newState;
  }

  if (action.type === CLOSE_NOTE_POPUP) {
    const { notesTabPopUpId } = action.payload;
    const updatedNotesById = removeKey(state.NotesById, notesTabPopUpId);
    const updatedNotesByOrder = state.NotesOrder.filter(noteId => noteId !== notesTabPopUpId);
    newState = cleanSet(cleanSet(state, 'NotesById', updatedNotesById), 'NotesOrder', updatedNotesByOrder);
    return newState;
  }
  if (action.type === UPDATE_NOTE_POPUP) {
    const { notesTabPopUpId, note } = action.payload;
    const existingNotesTabData = state.NotesById[notesTabPopUpId];
    newState = cleanSet(state, ['NotesById', notesTabPopUpId], { ...existingNotesTabData, note });
    return newState;
  }
  return state;
}

function getAllPopupNotes(state) {
  return state.NotesReducer.NotesById;
}

function getAllPopupNotesOrder(state) {
  return state.NotesReducer.NotesOrder;
}

function getPopupNoteById(state, notesTabPopUpId) {
  return state.NotesReducer.NotesById[notesTabPopUpId];
}

export { NotesReducer, getAllPopupNotes, getPopupNoteById, getAllPopupNotesOrder };

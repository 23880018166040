import { Button, Tooltip } from 'antd';
import React from 'react';
import styles from './JobDescriptionRecommendationButton.module.scss';

export default function JobDescriptionRecommendationButton(props) {
  const { isInActive, onDescriptionRecommendationClick, isLoading } = props;

  let tooltipText = `Use Leoforce's smart AI technology to automatically generate a job description based on the above information you provided`;
  if (isInActive)
    tooltipText = 'Job description recommendations are not available at the moment for the given criteria';

  return (
    <Tooltip placement="topRight" title={tooltipText} overlayClassName={styles.tooltipStyle}>
      <div className={isInActive ? styles.inactiveButtonWrapper : styles.activeButtonWrapper}>
        Save time and attract the right talent with a well written job description from our recommendations
        <Button
          shape="round"
          className={isInActive ? styles.recommendationBtnInActive : styles.recommendationBtnActive}
          onClick={onDescriptionRecommendationClick}
          disabled={isInActive}
          loading={isLoading}
        >
          View job description library
        </Button>
      </div>
    </Tooltip>
  );
}

import React from 'react';
import styles from './NotesTab.module.scss';
import NotesTabHeader from './NotesTabHeader';

const MinimizedNote = props => {
  const { note, noteId, smallBox, toggleNotePopup, closeNotePopup } = props;

  const toggleMaximize = () => {
    toggleNotePopup({
      notesTabPopUpId: noteId,
      visible: true,
      minimized: false,
    });
  };
  const handleClose = () => {
    closeNotePopup(noteId);
  };

  return (
    <NotesTabHeader
      candidate={note.candidate}
      toggleMaximize={toggleMaximize}
      handleClose={handleClose}
      smallBox={smallBox}
    />
  );
};

const LongMinimizedBoxes = props => {
  const { minimizedNotesPopup, candidates, toggleNotePopup, closeNotePopup } = props;
  return minimizedNotesPopup.map(note => (
    <div key={note.Id} className={styles.longBoxes}>
      <MinimizedNote
        candidates={candidates}
        noteId={note.Id}
        note={note}
        toggleNotePopup={toggleNotePopup}
        closeNotePopup={closeNotePopup}
      />
    </div>
  ));
};

const SmallMinimizedBoxes = props => {
  const { candidates, minimizedNotesPopup, toggleNotePopup, closeNotePopup } = props;
  return minimizedNotesPopup.map(note => (
    <div key={note.Id} className={styles.smallBoxes}>
      <MinimizedNote
        candidates={candidates}
        noteId={note.Id}
        note={note}
        smallBox
        toggleNotePopup={toggleNotePopup}
        closeNotePopup={closeNotePopup}
      />
    </div>
  ));
};

const NotesTabMinimized = props => {
  const { candidatesNotesList, toggleNotePopup, closeNotePopup } = props;
  const [minimizedNotesPopup, setMinimizedNotesPopup] = React.useState([]);

  React.useEffect(() => {
    setMinimizedNotesPopup(getMinimizedNotesPopupLength(candidatesNotesList));
  }, [candidatesNotesList]);

  const getMinimizedNotesPopupLength = candidateNotesObj => {
    const candidateNotesArray = Object.entries(candidateNotesObj).map(([Id, data]) => {
      return { ...data, Id };
    });
    return candidateNotesArray.filter(note => note.minimized);
  };

  if (minimizedNotesPopup.length < 2)
    return (
      <LongMinimizedBoxes
        minimizedNotesPopup={minimizedNotesPopup}
        toggleNotePopup={toggleNotePopup}
        closeNotePopup={closeNotePopup}
      />
    );
  return (
    <SmallMinimizedBoxes
      minimizedNotesPopup={minimizedNotesPopup}
      toggleNotePopup={toggleNotePopup}
      closeNotePopup={closeNotePopup}
    />
  );
};

export default NotesTabMinimized;

/* eslint-disable react/destructuring-assignment */
import React from 'react';
import _ from 'lodash';
import { Form, Divider, Checkbox, Tooltip, Dropdown, Input, Menu } from 'antd';
import SecurityClearance from './SecurityClearance';
import ExperienceSelection from './ExperienceSelection';
import WarningPopupModal from '../WarningPopupModal/WarningPopupModal';
import styles from './AdvancedJobDetailsSelection.module.scss';

const FormItem = Form.Item;

const checkboxTooltipText = `Click the box if you want to include this as a ‘must have’ skill.`;

const noStatsText = 'No data available based on your criteria. You can edit information and check back later.';

export function ItemSelection({
  insightsList,
  insightName,
  selectedInsights,
  isMustHave,
  handleClick,
  handleCheckbox,
}) {
  return insightsList.map(item => {
    const elem = selectedInsights.find(el => el[insightName]?.toLowerCase() === item[insightName]?.toLowerCase());
    const profiles = item?.count ? `(${item?.count} Profiles)` : '';
    return (
      <div className={styles.itemWrapper}>
        {isMustHave && (
          <Tooltip key={`checkbox-${item[insightName]}`} title={checkboxTooltipText}>
            <Checkbox
              checked={elem?.checked}
              onChange={e => handleCheckbox(item, e.target.checked)}
              className={elem ? styles.activeCheckbox : styles.inactiveCheckbox}
            ></Checkbox>
          </Tooltip>
        )}
        <Tooltip key={item[insightName]} title={`${item[insightName]} ${profiles}`}>
          <div
            className={`${styles.item} ${elem ? styles.active : styles.inActive}`}
            role="presentation"
            onClick={() => handleClick(item)}
          >
            <span className={styles.text}>{item[insightName]}</span>
            <span className={styles.text}>{item?.percentage ? `${item?.percentage}%` : ''}</span>
          </div>
        </Tooltip>
      </div>
    );
  });
}

export default class AdvancedJobDetailsSelection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      insightsList: props.insightsList || [],
      addMoreList: props.addMoreList || [],
      warningMessage: '',
    };
  }

  componentDidMount() {
    const { activeInsightsList, insightName, form } = this.props;
    form.setFieldsValue({
      [insightName]: activeInsightsList,
    });
  }

  componentDidUpdate(prevProps) {
    const { insightsList, addMoreList, activeInsightsList, insightName, form } = this.props;
    if (!_.isEqual(prevProps.insightsList, insightsList)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ insightsList });
    }
    if (!_.isEqual(prevProps.addMoreList, addMoreList)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ addMoreList });
    }
    if (!_.isEqual(prevProps.activeInsightsList, activeInsightsList)) {
      form.setFieldsValue({
        [insightName]: activeInsightsList,
      });
    }
  }

  handleClick = item => {
    const { insightName, form, showMinMaxExperience, getJDSuggestions } = this.props;
    const activeInsightsList = form.getFieldValue(insightName);
    if (showMinMaxExperience) {
      const newList = [];
      newList.push(item);
      const expArray = item.Range.split('-');
      const minExp = expArray[0] === '*' ? 0 : Math.round(expArray[0]);
      const maxExp = expArray[1] === '*' ? 50 : Math.round(expArray[1]);
      form.setFieldsValue({
        MinExperience: minExp,
        MaxExperience: maxExp,
        [insightName]: newList,
      });
    } else {
      const newList = _.cloneDeep(activeInsightsList);
      const idx = newList.findIndex(el => el[insightName]?.toLowerCase() === item[insightName]?.toLowerCase());
      if (idx >= 0) {
        newList.splice(idx, 1);
      } else {
        newList.push(item);
      }
      form.setFieldsValue({
        [insightName]: newList,
      });
    }
    if (getJDSuggestions) getJDSuggestions();
  };

  handleCheckbox = (item, checked) => {
    const { form, insightName } = this.props;
    const activeInsightsList = form.getFieldValue(insightName);
    const mustHaveList = activeInsightsList.filter(el => el?.checked);
    if (mustHaveList.length === 2 && checked) {
      this.setWarningPopupModalMessage('You can only have two ‘must have’ criteria');
    } else {
      const newList = _.cloneDeep(activeInsightsList);
      const idx = newList.findIndex(el => el[insightName]?.toLowerCase() === item[insightName]?.toLowerCase());
      newList[idx].checked = checked;
      this.setWarningPopupModalMessage('');
      form.setFieldsValue({
        [insightName]: newList,
      });
    }
  };

  handleAddMore = item => {
    const { insightsList, addMoreList } = this.state;
    const { insightName, form, getJDSuggestions } = this.props;
    const searchvalue = item[insightName]?.toLowerCase()?.trim();
    const searchValueIndex = insightsList.findIndex(el => el[insightName]?.toLowerCase() === searchvalue);
    if (searchvalue && searchValueIndex === -1) {
      const newList = _.cloneDeep(insightsList);
      const { getFieldValue } = form;
      const selectedInsights = getFieldValue(insightName) || [];
      const newAddMoreList = addMoreList.filter(ele => ele[insightName] !== item[insightName]);
      newList.push(item);
      selectedInsights.push(item);
      const key = `${insightName}SearchValue`;
      form.setFieldsValue({
        [key]: '',
        [insightName]: selectedInsights,
      });
      this.setState({
        insightsList: newList,
        addMoreList: newAddMoreList,
      });
    } else {
      const key = `${insightName}SearchValue`;
      form.setFieldsValue({
        [key]: '',
      });
    }
    if (getJDSuggestions) getJDSuggestions();
  };

  setWarningPopupModalMessage = warningMessage => {
    this.setState({ warningMessage });
  };

  handleExpChange = (val, isMinExp) => {
    const { form, insightName } = this.props;
    const { getFieldValue } = form;
    const minExp = getFieldValue('MinExperience');
    const maxExp = getFieldValue('MaxExperience');
    if ((val !== minExp && isMinExp) || (val !== maxExp && !isMinExp)) {
      form.setFieldsValue({
        [insightName]: [],
      });
    }
  };

  getAddMoreList = () => {
    const { insightName, form } = this.props;
    const { addMoreList } = this.state;
    const searchValue = form.getFieldValue(`${insightName}SearchValue`) || '';
    const filteredList = addMoreList.filter(ele =>
      ele[insightName]?.toLowerCase()?.includes(searchValue?.toLowerCase())
    );
    return filteredList.length > 0 ? (
      <Menu style={{ maxHeight: '264px', overflowY: 'auto' }} className="client-menu-list">
        {filteredList.map(item => {
          const profiles = item?.count ? `(${item?.count} Profiles)` : '';
          return (
            <Menu.Item key={item[insightName]} value={item[insightName]} onClick={() => this.handleAddMore(item)}>
              <Tooltip title={`${item[insightName]} ${profiles}`}>{item[insightName]}</Tooltip>
            </Menu.Item>
          );
        })}
      </Menu>
    ) : (
      <div />
    );
  };

  validateInsights = (rule, value, callback) => {
    const errorMessage = 'Please select atleast 1 skill';
    const { insightName, form } = this.props;
    const activeInsightsList = form.getFieldValue(insightName);
    if (insightName !== 'SkillName' || activeInsightsList.length > 0) {
      callback();
    } else {
      callback(errorMessage);
    }
  };

  handleNewEntry = e => {
    const { allowNewEntry, insightName, form } = this.props;
    if (allowNewEntry) {
      this.handleAddMore({ [insightName]: e?.target?.value });
    } else {
      const key = `${insightName}SearchValue`;
      form.setFieldsValue({
        [key]: '',
      });
    }
  };

  render() {
    const { insightsList = [], warningMessage } = this.state;
    const {
      title = '',
      subTitle = '',
      isMustHave = false,
      showDivider = false,
      showMinMaxExperience = false,
      id,
      insightName,
      showAddMore = false,
      addMoreText = 'Add more',
      form,
      showSecurityClearance = false,
      minExp = undefined,
      maxExp = undefined,
      securityClearance = undefined,
      activeInsightsList = [],
    } = this.props;
    const { getFieldDecorator, getFieldValue } = form;
    const selectedInsights = getFieldValue(insightName) || [];
    const isDistrubutionStatsFound = this.props.insightsList?.length > 0;

    return (
      <div className={styles.sectionWrapper} id={id}>
        <WarningPopupModal
          warningMessage={warningMessage}
          setWarningPopupModalMessage={this.setWarningPopupModalMessage}
        />
        <FormItem colon={false}>
          {form.getFieldDecorator(insightName, {
            initialValue: activeInsightsList,
            rules: [{ validator: this.validateInsights }],
          })(
            <>
              {title && <div className={styles.title}>{title}</div>}
              <div className={styles.subTitleWrapper}>
                {subTitle && (
                  <div className={styles.subTitle}>
                    {isDistrubutionStatsFound ? subTitle : noStatsText}
                    {isDistrubutionStatsFound && <span>(National Statistics)</span>}
                  </div>
                )}
              </div>
              {isMustHave && selectedInsights.length > 0 && (
                <span className={styles.checkboxAlignment}>
                  <Checkbox checked>
                    Check if this is a “<span className={styles.mustHave}>must have</span>” criteria. You can choose
                    upto 2 skills to boost your search and help Leoforce source the most relevant candidates for you.
                  </Checkbox>
                </span>
              )}
              <div className={styles.container}>
                <ItemSelection
                  insightsList={insightsList}
                  insightName={insightName}
                  selectedInsights={selectedInsights}
                  isMustHave={isMustHave}
                  handleClick={this.handleClick}
                  handleCheckbox={this.handleCheckbox}
                />
              </div>
              {showAddMore && (
                <div className={styles.itemWrapper}>
                  <div className={`${styles.addMore} ${isMustHave ? styles.withCheckbox : styles.withoutCheckbox}`}>
                    <Dropdown overlay={this.getAddMoreList()} trigger={['click']} forceRender>
                      {getFieldDecorator(`${insightName}SearchValue`, {
                        initialValue: '',
                      })(
                        <Input
                          placeholder={`+ ${addMoreText}`}
                          onPressEnter={e => this.handleNewEntry(e)}
                          onBlur={() => {
                            const key = `${insightName}SearchValue`;
                            form.setFieldsValue({
                              [key]: '',
                            });
                          }}
                        />
                      )}
                    </Dropdown>
                  </div>
                </div>
              )}
            </>
          )}
        </FormItem>
        {showMinMaxExperience && (
          <ExperienceSelection form={form} minExp={minExp} maxExp={maxExp} handleExpChange={this.handleExpChange} />
        )}
        {showSecurityClearance && <SecurityClearance form={form} securityClearance={securityClearance} />}
        {showDivider && <Divider />}
      </div>
    );
  }
}

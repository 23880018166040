import React from 'react';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import { Tabs } from 'antd';
import { isCandidateIntelV2Data } from '../../Utils/CandidateIntelUtils';
import CandidateNotesContainer from '../../Containers/CandidateNotes/CandidateNotesContainer';
import Candidate360AtsNotes from '../../Containers/CandidateNotes/Candidate360AtsNotes';
import ResumeViewContainer from '../../Containers/ResumeContainer/ResumeViewContainer';
import styles from './CandidateViewTabs.modules.scss';
import CandidateJobsContainer from '../../Containers/CandidateJobsContainer/CandidateJobsContainer';
import CandidateSegmentsAndCampaignsContainer from '../../Containers/CandidateSegmentsAndCampaignsContainer/CandidateSegmentsAndCampaignsContainer';
import CandiateActivityContainer from '../../Containers/CandidateDrawer/CandiateActivityContainer';
import CandidateWorkHistoryWrapper from '../CandidateWorkHistory/CandidateWorkHistoryWrapper';
import CandidateContactsWrapper from '../CandidateContacts/CandidateContactsWrapper';
import CandidateIntelWrapper from '../CandidateIntel/CandidateIntelWrapper';
import { getInternalSourceWithCandidateId } from '../../Utils/SourceUtils';
import message from '../../Containers/CandidateDrawer/messages';

const { TabPane } = Tabs;

function CandidateViewTabs(props) {
  const {
    candidate,
    jobId,
    keywordsToHighlight,
    mustHavesKeywords,
    isTryNow,
    downloadResume,
    allowResumeDownload,
    version,
    jobGuid,
    currentJobDetails,
    candidateDetailsStatus,
    jobCountryCode,
    toggleBotConfigDrawer,
    onCandidate360TabChange,
    tabName,
    candidateType,
    isCandidateIntelVisible,
    candidateContext,
    appName,
    activeSubTab,
    onSubTabClick,
    isCandidateViewHeaderVisible,
    setCandidateViewHeaderVisibility,
    candidateListStatus,
    isSegmentAndCampaignsTabVisible,
    isCandidateMatchingJobModalVisible,
    setCandidateMatchingJobModalVisiblity,
    creditsRefunded,
    isJobActionsAllowed,
    unlockCandidateResume,
    resumeUnlockStatus,
    featureToggleList,
    connectInfo,
    selectPlan,
    candidateJobsTotalCount,
    fetchCandidateJobsApiStatus,
    notesContainer,
    onNotesChange,
    currentNotes,
    candidateId,
    showCandidateDetailTabs,
    openSipCallWindowsApp,
    isContactTabRedirection,
    activeTab,
    activeSourceName,
    candidateJobMatchingInitialAggregation,
    candidateSegmentsTotalCount,
    fetchBulkCandidateAllNotes,
    candidateIntel,
    isAlertMessage,
    productVariantsById,
    jobsById,
    composeEmailType,
    setComposeEmailType,
    openSegmentAtsView,
    openJobViewInNewTabCallBack,
    onClickAddJobCallBack,
    setCandidateJobMatchingInitialAggregation,
    setIsFindMathingJobClicked,
    isFindMathingJobClicked,
    isCandidatePublishEnabled,
    openInNewTab,
    openCandidateView,
  } = props;

  let candidateJobsTabTitle = <FormattedMessage {...message.candidateJobs} />;

  const [isCandidateUnsubscribed, setIsCandidateUnsubscribed] = React.useState(false);
  const subscription = candidate?.connectInfo?.Contact?.Subscription;

  React.useEffect(() => {
    if (subscription) {
      setIsCandidateUnsubscribed(subscription.Status?.toLowerCase() === 'unsubscribed');
    }
  }, [subscription]);

  const displayedSegmentsTotalCount = candidateSegmentsTotalCount || 0;
  const segmentsTabTitle = (
    <span>
      <FormattedMessage {...message.candidateSegments} /> ({displayedSegmentsTotalCount})
    </span>
  );

  const { CallNotesCount: candidateNotesCount, CandidateNotesCount: callNotesCount } = candidate;
  const notesCount = candidateNotesCount + callNotesCount + null || 0;
  const notesTabTitle = (
    <span>
      <FormattedMessage {...message.candidateNotes} /> ({notesCount})
    </span>
  );
  const isCandidateIntelV2Enabled = _.get(featureToggleList, ['CandidateIntelV2', 'IsEnabled'], false);

  if (fetchCandidateJobsApiStatus !== 'INPROGRESS' && !isCandidateMatchingJobModalVisible) {
    candidateJobsTabTitle =
      candidateJobsTotalCount !== undefined ? (
        <span>
          <FormattedMessage {...message.candidateJobs} /> ({candidateJobsTotalCount})
        </span>
      ) : (
        <FormattedMessage {...message.candidateJobs} />
      );
  }

  let notesComponent = (
    <CandidateNotesContainer
      candidate={candidate}
      jobId={jobId}
      isCandidateViewHeaderVisible={isCandidateViewHeaderVisible}
      setCandidateViewHeaderVisibility={setCandidateViewHeaderVisibility}
      candidateListStatus={candidateListStatus}
      candidateDetailsStatus={candidateDetailsStatus}
      fetchBulkCandidateAllNotes={fetchBulkCandidateAllNotes}
      candidateContext={candidateContext}
      activeTab={activeTab}
      openInNewTab={openInNewTab}
    />
  );

  if (notesContainer) {
    notesComponent = (
      <Candidate360AtsNotes
        onNotesChange={onNotesChange}
        currentNotes={currentNotes}
        aryaCandidateId={candidateId}
        getInternalSourceWithCandidateId={getInternalSourceWithCandidateId}
        candidate={candidate}
        notesContainer={notesContainer}
        isCandidateViewHeaderVisible={isCandidateViewHeaderVisible}
        setCandidateViewHeaderVisibility={setCandidateViewHeaderVisibility}
      />
    );
  }

  const isPaidJobServiceEnabled = _.get(featureToggleList, ['PaidJobService', 'IsEnabled'], false);
  const job = _.get(jobsById, [jobId], {});
  const orderStatusDetails = _.get(job, 'OrderInfo', {});
  const productVariantId = _.get(orderStatusDetails, 'ProductVariantId', '');
  const productVariant = _.get(productVariantsById, [productVariantId], {});
  const productVariantName = _.get(productVariant, 'Name', null);
  const isConnectIconsEnabled = isPaidJobServiceEnabled
    ? !['basic', 'freetrial'].includes(productVariantName?.toLowerCase())
    : true;
  const isEmailReadVisible = featureToggleList.EmailRead.IsEnabled || candidateContext === 'segment';
  const isMessageReadVisible = featureToggleList.MessageRead.IsEnabled || candidateContext === 'segment';
  const isAudioReadVisible = featureToggleList.AudioRead.IsEnabled || candidateContext === 'segment';
  const isBotVisible = featureToggleList.BotRead.IsEnabled && candidateContext === 'job';
  const isCandidateIntelDownloadEnabled = featureToggleList.CandidateIntelDownload.IsEnabled;
  const isContactsTabVisible =
    (isEmailReadVisible || isMessageReadVisible || isAudioReadVisible || isBotVisible) &&
    isConnectIconsEnabled &&
    showCandidateDetailTabs;

  return (
    <div className={styles.candidateTabs} id="candidate-tabs-id">
      <Tabs
        size="small"
        activeKey={tabName}
        onChange={onCandidate360TabChange}
        tabBarStyle={{
          fontWeight: 400,
          fontSize: '14px',
          color: '#1F2730',
          backgroundColor: 'rgba(217, 217, 217, 0.3)',
        }}
        destroyInactiveTabPane
        animated={false}
      >
        <TabPane tab={<FormattedMessage {...message.candidateResume} />} key="profile">
          <ResumeViewContainer
            candidate={candidate}
            keywordsToHighlight={keywordsToHighlight}
            mustHavesKeywords={mustHavesKeywords}
            isTryNow={isTryNow}
            downloadResume={downloadResume}
            allowResumeDownload={allowResumeDownload}
            version={version}
            jobGuid={jobGuid}
            currentJobDetails={currentJobDetails}
            candidateDetailsStatus={candidateDetailsStatus}
            isCandidateViewHeaderVisible={isCandidateViewHeaderVisible}
            setCandidateViewHeaderVisibility={setCandidateViewHeaderVisibility}
            candidateListStatus={candidateListStatus}
            isJobActionsAllowed={isJobActionsAllowed}
            jobId={jobId}
            unlockCandidateResume={unlockCandidateResume}
            resumeUnlockStatus={resumeUnlockStatus}
            featureToggleList={featureToggleList}
            connectInfo={connectInfo}
            selectPlan={selectPlan}
            isCandidatePublishEnabled={isCandidatePublishEnabled}
          />
        </TabPane>
        {isCandidateIntelV2Enabled && candidateType !== 'quick-search' ? (
          <TabPane tab={<FormattedMessage {...message.scoreDetails} />} key="candidate-intel">
            <CandidateIntelWrapper
              candidateIntel={candidateIntel}
              candidate={candidate}
              jobId={jobId}
              isCandidateViewHeaderVisible={isCandidateViewHeaderVisible}
              setCandidateViewHeaderVisibility={setCandidateViewHeaderVisibility}
              isCandidateIntelDownloadEnabled={isCandidateIntelDownloadEnabled}
            />
          </TabPane>
        ) : null}
        <TabPane tab={<FormattedMessage {...message.candidateWorkHistory} />} key="work-history">
          <CandidateWorkHistoryWrapper
            candidate={candidate}
            candidateDetailsStatus={candidateDetailsStatus}
            appName={appName}
            isCandidateViewHeaderVisible={isCandidateViewHeaderVisible}
            setCandidateViewHeaderVisibility={setCandidateViewHeaderVisibility}
            keywordsToHighlight={keywordsToHighlight}
            mustHavesKeywords={mustHavesKeywords}
            activeTab={activeTab}
            activeSourceName={activeSourceName}
            candidateListStatus={candidateListStatus}
          />
        </TabPane>
        {!isCandidatePublishEnabled ? (
          <TabPane tab={candidateJobsTabTitle} key="jobs">
            <CandidateJobsContainer
              candidate={candidate}
              setCandidateViewHeaderVisibility={setCandidateViewHeaderVisibility}
              isCandidateViewHeaderVisible={isCandidateViewHeaderVisible}
              isCandidateMatchingJobModalVisible={isCandidateMatchingJobModalVisible}
              setCandidateMatchingJobModalVisiblity={setCandidateMatchingJobModalVisiblity}
              version={version}
              candidateJobMatchingInitialAggregation={candidateJobMatchingInitialAggregation}
              openJobViewInNewTabCallBack={openJobViewInNewTabCallBack}
              onClickAddJobCallBack={onClickAddJobCallBack}
              setCandidateJobMatchingInitialAggregation={setCandidateJobMatchingInitialAggregation}
              setIsFindMathingJobClicked={setIsFindMathingJobClicked}
              isFindMathingJobClicked={isFindMathingJobClicked}
              featureToggleList={featureToggleList}
            />
          </TabPane>
        ) : null}
        {isSegmentAndCampaignsTabVisible && !isCandidatePublishEnabled ? (
          <TabPane tab={segmentsTabTitle} key="segments">
            <CandidateSegmentsAndCampaignsContainer
              candidate={candidate}
              isCandidateViewHeaderVisible={isCandidateViewHeaderVisible}
              setCandidateViewHeaderVisibility={setCandidateViewHeaderVisibility}
              version={version}
              openSegmentAtsView={openSegmentAtsView}
            />
          </TabPane>
        ) : null}
        <TabPane tab={<FormattedMessage {...message.activityLogs} />} key="activity-logs">
          <CandiateActivityContainer
            candidate={candidate}
            setCandidateViewHeaderVisibility={setCandidateViewHeaderVisibility}
            isCandidateViewHeaderVisible={isCandidateViewHeaderVisible}
            version={version}
          />
        </TabPane>
        {isContactsTabVisible && !isCandidatePublishEnabled ? (
          <TabPane tab={<FormattedMessage {...message.candidateContacts} />} key="contact">
            <CandidateContactsWrapper
              candidate={candidate}
              jobGuid={jobGuid}
              jobId={jobId}
              currentJobDetails={currentJobDetails}
              jobCountryCode={jobCountryCode}
              toggleBotConfigDrawer={toggleBotConfigDrawer}
              candidateContext={candidateContext}
              activeSubTab={activeSubTab}
              onSubTabClick={onSubTabClick}
              isCandidateViewHeaderVisible={isCandidateViewHeaderVisible}
              setCandidateViewHeaderVisibility={setCandidateViewHeaderVisibility}
              version={version}
              creditsRefunded={creditsRefunded}
              openSipCallWindowsApp={openSipCallWindowsApp}
              isContactTabRedirection={isContactTabRedirection}
              isAlertMessage={isAlertMessage}
              featureToggleList={featureToggleList}
              productVariantsById={productVariantsById}
              jobsById={jobsById}
              isConnectIconsEnabled={isConnectIconsEnabled}
              isEmailReadVisible={isEmailReadVisible}
              isAudioReadVisible={isAudioReadVisible}
              isMessageReadVisible={isMessageReadVisible}
              composeEmailType={composeEmailType}
              setComposeEmailType={setComposeEmailType}
              isCandidateUnsubscribed={isCandidateUnsubscribed}
              openCandidateView={openCandidateView}
              openJobViewInNewTabCallBack={openJobViewInNewTabCallBack}
            />
          </TabPane>
        ) : null}
        {!isCandidatePublishEnabled ? (
          <TabPane tab={notesTabTitle} key="notes">
            {notesComponent}
          </TabPane>
        ) : null}
      </Tabs>
    </div>
  );
}

export default CandidateViewTabs;

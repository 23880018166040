import React from 'react';
import { Form, Button, Input } from 'antd';
import { FormattedMessage } from 'react-intl';
import Draggable from 'react-draggable';
import NotesEditorField from '../../CompoundComponents/NotesWrapper/NotesEditorField';
import NotesWrapper from '../../CompoundComponents/NotesWrapper/NotesWrapper';
import styles from './NotesTab.module.scss';
import NotesTabHeader from './NotesTabHeader';
import message from '../ManualSearchV2/ManualSearchMessages';

const NotesTab = props => {
  const [content, setContent] = React.useState('');
  const [noteSubject, setNoteSubject] = React.useState('');
  const [isDescriptionWarningSuppressed, setDescriptionWarningSuppressed] = React.useState(false);
  const [invalidDescriptionErrorMessage, setInvalidDescriptionErrorMessage] = React.useState('');
  const {
    form,
    jobId,
    candidate,
    activeTab,
    openInNewTab,
    vaults,
    candidateContext,
    createCandidateNote,
    notesTabPopUpId,
    toggleNotePopup,
    closeNotePopup,
    visible,
    minimized,
    candidateNoteCreateApiStatus,
    note,
    updateNotePopup,
    fetchBulkCandidateAllNotes,
  } = props;

  const { SubjectLine: subjectLine, Description: description, editNote } = note || {};
  const { Id: candidateId, ConversationId } = candidate;

  React.useEffect(() => {
    if (visible) {
      if (editNote) {
        updateFields({ subject: subjectLine, descriptionText: description });
        updateNotePopup({ notesTabPopUpId, note: { ...note, editNote: false } });
      } else {
        form.setFieldsValue({ NotesSubject: noteSubject });
      }
    } else if (!minimized) {
      setNoteSubject('');
    }
  }, [visible]);

  const updateFields = ({ subject, descriptionText }) => {
    form.setFieldsValue({ NotesSubject: subject });
    setContent(descriptionText);
  };

  const toggleMinimize = () => {
    setNoteSubject(form.getFieldValue('NotesSubject'));
    toggleNotePopup({
      notesTabPopUpId,
      visible: false,
      minimized: true,
      note: { SubjectLine: form.getFieldValue('NotesSubject'), Description: content },
    });
  };

  const handleClose = () => {
    closeNotePopup(notesTabPopUpId);
    setContent('');
  };
  const onEditorContentChange = ({ htmlContent, plainTextContent }) => {
    setContent(htmlContent);
    if (plainTextContent?.trim() || isDescriptionWarningSuppressed) setInvalidDescriptionErrorMessage('');
    else setInvalidDescriptionErrorMessage(<FormattedMessage {...message.pleaseEnterDescription} />);
  };

  const handleButtonClick = e => {
    e.preventDefault();
    onSubmit(form.getFieldsValue());
  };

  const fetchCandidateAllNotes = () => {
    const bulkCandidateNotesFilter = {
      jobId,
      candidateIds: [candidateId],
      from: 0,
      size: 0,
    };
    const bulkCallNotesFilter = ConversationId
      ? {
          candidateIdsByConversationId: { [ConversationId]: candidateId },
          conversationIds: [ConversationId],
          from: 0,
          size: 0,
        }
      : undefined;
    const nonContactedCandidateIds = ConversationId ? [] : [candidateId];
    fetchBulkCandidateAllNotes(bulkCandidateNotesFilter, bulkCallNotesFilter, nonContactedCandidateIds);
  };
  const onSubmit = values => {
    form.validateFields(['NotesSubject'], { force: true });
    if (!values?.NotesEditor?.plainTextContent?.trim()) {
      setInvalidDescriptionErrorMessage('Please enter description');
      return;
    }
    form.validateFields(['NotesSubject', 'NotesEditor'], { force: true }).then(() => {
      onSave(values);
    });
  };

  const clearForm = () => {
    form.resetFields();
    setDescriptionWarningSuppressed(true);
    setContent(undefined);
    setInvalidDescriptionErrorMessage('');
    setTimeout(() => setDescriptionWarningSuppressed(false), 0);
  };

  const onCancelNote = async () => {
    clearForm();
  };

  const lineSeparatorStyle = {
    borderBottom: '1px solid #E8E8E8',
    width: '95%',
    marginLeft: '14px',
  };

  const onSave = async values => {
    const payload = {
      Description: values?.NotesEditor?.htmlContent || content,
      SubjectLine: values?.NotesSubject,
      Context: values?.NotesContext,
    };
    const response = await createCandidateNote({
      jobId,
      candidateId,
      note: payload,
      candidate: {
        ...candidate,
        status:
          candidateContext === 'segment' && (activeTab?.toLowerCase() === 'sourced' || openInNewTab)
            ? 'Shortlisted'
            : undefined,
      },
      vaultName: vaults ? vaults[0] : undefined,
      candidateContext,
    });
    if (response.isSuccess) {
      clearForm();
      handleClose();
      fetchCandidateAllNotes();
    }
  };

  const validateSubject = (rule, value, callback) => {
    if (value && value.trim().length > 1000) {
      callback('Subject cannot exceed 1000 characters');
    } else {
      callback();
    }
  };

  const isSaveButtonDisabled = form.getFieldValue('NotesEditor')?.plainTextContent === '';

  return (
    <Draggable
      cancel={`.${styles.popupEditorSubject}`}
      bounds={{ left: -(window.innerWidth - 300), bottom: 300, top: -(window.innerHeight - 300), right: 300 }}
    >
      {visible ? (
        <div className={styles.notesTabWrapper}>
          <NotesTabHeader candidate={candidate} toggleMinimize={toggleMinimize} handleClose={handleClose} />
          <div className={styles.content}>
            <div className={styles.candidateNote}>
              <Form>
                <NotesWrapper
                  editorBody={content}
                  onEditorContentChange={onEditorContentChange}
                  onSubmit={onSubmit}
                  form={form}
                  onCancel={onCancelNote}
                  invalidDescriptionErrorMessage={invalidDescriptionErrorMessage}
                >
                  <div className={styles.candidateHeader}>
                    <div className={styles.noteSubject}>
                      <Form.Item>
                        {form.getFieldDecorator('NotesSubject', {
                          rules: [{ validator: validateSubject }],
                        })(
                          <Input
                            type="text"
                            placeholder="Subject"
                            onChange={e => setNoteSubject(e.target.value)}
                            className={styles.popupEditorSubject}
                          />
                        )}
                      </Form.Item>
                    </div>
                    <div style={lineSeparatorStyle}></div>
                  </div>
                  <div className={styles.noteEditor}>
                    <NotesEditorField editorClassName="popupEditor" placeholder="Start typing" />
                  </div>
                  <Button
                    className={styles.saveNoteButton}
                    shape="round"
                    onClick={handleButtonClick}
                    disabled={isSaveButtonDisabled}
                    loading={candidateNoteCreateApiStatus === 'INPROGRESS'}
                  >
                    Save Note
                  </Button>
                </NotesWrapper>
              </Form>
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </Draggable>
  );
};

export default Form.create()(NotesTab);
export { NotesTab as NotesTabWithForm };

import { Button, Checkbox, Modal } from 'antd';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';
import message from '../Connect/BotConfig/ChatbotMessages';
import styles from './BotConfigureModal.module.scss';
import ChatbotIcon from '../../Icons/ChatbotIcon';
import ChatbotWhiteIcon from '../../Icons/ChatbotWhiteIcon';

function BotConfigureModal(props) {
  const { isVisible, isShowAgainChecked, onSubmit, doNotShowAgain, onCancel } = props;
  const chatbotProsNumber = 3;
  const [doNotShow, setDoNotShow] = React.useState(isShowAgainChecked);

  const onShowAgainChange = () => {
    setDoNotShow(!doNotShow);
    doNotShowAgain(!doNotShow);
  };

  const onBotOnClick = () => {
    onSubmit();
  };

  const footerButtons = (
    <div className={styles.actionButtons}>
      <Checkbox checked={doNotShow} onChange={onShowAgainChange}>
        <FormattedMessage {...message.doNotShowMessage} />
      </Checkbox>
      <Button shape="round" key="dontShow" type="primary" className={styles.okButton} onClick={onBotOnClick}>
        <ChatbotWhiteIcon />
        <FormattedMessage {...message.aryabotTurnOn} />
      </Button>
    </div>
  );

  return (
    <Modal
      visible={isVisible}
      width="655px"
      className={styles.botConfigModal}
      footer={[footerButtons]}
      onCancel={onCancel}
      style={{
        '--bot-bg-image2': `url(${process.env.PUBLIC_URL}/static/Images/bot-enable-bg.png)`,
      }}
    >
      <div className={styles.botConfigure}>
        <div>
          <div className={styles.botEfficiency}>
            <FormattedMessage {...message.chatBotEfficiency} />
          </div>
          <div className={styles.turnOnBot}>
            <FormattedMessage {...message.chatbotTurnOn} />
          </div>
          <div>
            {Array.from({ length: chatbotProsNumber }).map((_, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={index} className={styles.botPros}>
                <div className={styles.botProsTitle}>
                  <FormattedMessage {...message[`chatbotProsTitle_${index}`]} />
                  &#58;
                </div>
                <div>
                  <FormattedMessage {...message[`chatbotProsDescription_${index}`]} />
                  &#46;
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.botImage}>
          <ChatbotIcon />
        </div>
      </div>
    </Modal>
  );
}

BotConfigureModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  isShowAgainChecked: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  doNotShowAgain: PropTypes.func.isRequired,
};

export default BotConfigureModal;

import React from 'react';
import _, { debounce } from 'lodash';
import { Form, Button } from 'antd';
import classNames from 'classnames';
import NotesEditorField from '../../CompoundComponents/NotesWrapper/NotesEditorField';
import NotesSaveButton from '../../CompoundComponents/NotesWrapper/NotesSaveButton';
import NotesSubjectField from '../../CompoundComponents/NotesWrapper/NotesSubjectField';
import NotesWrapper from '../../CompoundComponents/NotesWrapper/NotesWrapper';
import NotesCard from '../NotesCard/NotesCard';
import { getEventNameByCandidateStatus } from '../../Analytics/Candidate/CandidateEventUtils';
import eventTypes from '../../Analytics/EventTypes';
import styles from './Candidate360Note.module.scss';
import CandidateViewIconV2 from '../../Icons/CandidateViewIconV2';
import NotesCancelButton from '../../CompoundComponents/NotesWrapper/NotesCancelButton';

function Candidate360Note(props) {
  const {
    getAllCandidateNotes,
    deleteCandidateNote,
    updateCandidateNote,
    createCandidateNote,
    form,
    jobId,
    candidate,
    notesData,
    candidateNoteCreateApiStatus,
    candidateNoteFetchApiStatus,
    candidateNoteDeleteApiStatuses,
    fetchTagsForCandidate,
    candidateSuggestedTags,
    candidateSuggestedTagsCount,
    addTagForCandidateNote,
    tagCloseApiStatus,
    deleteTagFromCandidateNote,
    setNotification,
    setCandidateViewHeaderVisibility,
    isCandidateViewHeaderVisible,
    callNotes,
    candidateDetailsStatus,
    candidateListStatus,
    fetchBulkCandidateAllNotes,
    vaults,
    candidateContext,
    activeTab,
    openInNewTab,
    setCandidateNoteTab,
    updateNotePopup,
  } = props;
  const [isSave, setSave] = React.useState(true);
  const [content, setContent] = React.useState('');
  const [editNote, setEditNote] = React.useState({});
  const [allNotes, setAllNotes] = React.useState([]);
  const [invalidDescriptionErrorMessage, setInvalidDescriptionErrorMessage] = React.useState('');
  const [isDescriptionWarningSuppressed, setDescriptionWarningSuppressed] = React.useState(false);
  const createNoteFormRef = React.useRef(null);
  const ref = React.useRef(null);

  const { Id: candidateId, ConversationId } = candidate ?? {};
  const { CandidateNotesCount: candidateNotesCount = 0 } = candidate;

  React.useEffect(() => {
    const onScroll = () => {
      if (ref.current.scrollTop === 0) setCandidateViewHeaderVisibility(true);
      else setCandidateViewHeaderVisibility(false);
    };
    ref.current.addEventListener('scroll', onScroll);
    getAllCandidateNotes({ jobId, candidateId: candidate?.Id });
    return () => {
      ref.current.removeEventListener('scroll', onScroll);
    };
  }, []);
  React.useEffect(() => {
    if (notesData) {
      const tempNotes = _.sortBy(notesData, note => -1 * new Date(note.CreatedDate));
      setAllNotes(tempNotes);
    }
    if (callNotes) {
      callNotes.map(note => setAllNotes(array => [...array, note]));
    }
  }, [callNotes]);

  const handleScroll = reference => {
    reference.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };
  const clearForm = () => {
    setDescriptionWarningSuppressed(true);
    setSave(true);
    form.resetFields();
    setEditNote({});
    setInvalidDescriptionErrorMessage('');
    setContent(undefined);
    setTimeout(() => setDescriptionWarningSuppressed(false), 0);
  };
  const onEditorContentChange = ({ htmlContent, plainTextContent }) => {
    setContent(htmlContent);
    if (plainTextContent?.trim() || isDescriptionWarningSuppressed) setInvalidDescriptionErrorMessage('');
    else setInvalidDescriptionErrorMessage('Please enter description');
  };

  const fetchCandidateAllNotes = () => {
    const bulkCandidateNotesFilter = {
      jobId,
      candidateIds: [candidateId],
      from: 0,
      size: 0,
    };
    const bulkCallNotesFilter = ConversationId
      ? {
          candidateIdsByConversationId: { [ConversationId]: candidateId },
          conversationIds: [ConversationId],
          from: 0,
          size: 0,
        }
      : undefined;
    const nonContactedCandidateIds = ConversationId ? [] : [candidateId];
    fetchBulkCandidateAllNotes(bulkCandidateNotesFilter, bulkCallNotesFilter, nonContactedCandidateIds);
  };

  const onSave = async values => {
    const payload = {
      Description: values?.NotesEditor?.htmlContent,
      SubjectLine: values?.NotesSubject,
      Context: values?.NotesContext,
    };
    const response = await createCandidateNote({
      jobId,
      candidateId: candidate?.Id,
      note: payload,
      candidate: {
        ...candidate,
        status:
          candidateContext === 'segment' && (activeTab?.toLowerCase() === 'sourced' || openInNewTab)
            ? 'Shortlisted'
            : undefined,
      },
      vaultName: vaults ? vaults[0] : undefined,
      candidateContext,
    });
    if (response.isSuccess) {
      clearForm();
      fetchCandidateAllNotes();
    }
  };

  const onUpdate = async values => {
    const payload = {
      Description: values?.NotesEditor?.htmlContent,
      SubjectLine: values?.NotesSubject,
      Context: values?.NotesContext,
    };
    const response = await updateCandidateNote({
      jobId,
      candidateId: candidate?.Id,
      noteId: editNote.Id,
      note: payload,
      vaultName: vaults ? vaults[0] : undefined,
    });
    if (response.isSuccess) {
      clearForm();
    }
  };
  const onSubmit = values => {
    form.validateFields(['NotesSubject'], { force: true });
    if (!values?.NotesEditor?.plainTextContent?.trim()) {
      setInvalidDescriptionErrorMessage('Please enter description');
      return;
    }
    form.validateFields(['NotesSubject', 'NotesEditor'], { force: true }).then(() => {
      if (isSave) {
        onSave(values);
      } else {
        onUpdate(values);
      }
    });
  };
  const onDelete = noteId => {
    deleteCandidateNote({
      jobId,
      noteId,
      candidateNotesCount,
      candidateId: candidate?.Id,
      vaultName: vaults ? vaults[0] : undefined,
    });
  };
  const onEdit = note => {
    setSave(false);
    const cloneAllNotes = allNotes.filter(noteCard => noteCard?.Id !== note?.Id);
    setAllNotes(cloneAllNotes);
    setEditNote(note);
    form.setFieldsValue({ NotesSubject: note.SubjectLine, NotesContext: note.Context });
    setContent(note.Description);
    handleScroll(createNoteFormRef);
  };
  const debounceFetchSuggestedTags = React.useCallback(
    debounce(nextValue => fetchTagsForCandidate(nextValue), 600),
    []
  );
  const onTagChange = value => {
    debounceFetchSuggestedTags({
      searchTerm: value,
      from: 0,
      size: 20,
      type: 'notes',
    });
  };
  const onLoadMore = payload => {
    fetchTagsForCandidate(payload);
  };
  const onTagAdd = ({ noteId, tag }) => {
    addTagForCandidateNote({ candidateId: candidate.Id, noteId, tag });
  };
  const onTagClose = ({ tag, noteId }) => {
    deleteTagFromCandidateNote({ tag, noteId, candidateId: candidate.Id });
  };
  const onCancelNote = async () => {
    const tempNotes = _.sortBy(notesData, note => -1 * new Date(note.CreatedDate));
    setAllNotes(tempNotes);
    clearForm();
  };
  const onAddNote = () => {
    setCandidateNoteTab({
      candidate,
      jobId,
      candidateContext,
      vaults,
    });
    const subject = form.getFieldValue('NotesSubject');
    if (subject || content) {
      updateNotePopup({
        notesTabPopUpId: `${jobId}_${candidate.Id}`,
        note: { SubjectLine: subject, Description: content, editNote: true },
      });
    }
  };
  return (
    <div
      className={classNames(styles.candidateNoteWrapper, { [styles.extendView]: !isCandidateViewHeaderVisible })}
      ref={ref}
    >
      <div>
        <div role="presentation" onClick={onAddNote} className={styles.notePopup}>
          <CandidateViewIconV2 />
        </div>
        <div className={styles.candidateNote} ref={createNoteFormRef}>
          <Form>
            <NotesWrapper
              editorBody={content}
              onEditorContentChange={onEditorContentChange}
              onSubmit={onSubmit}
              form={form}
              addButtonApiStatus={candidateNoteCreateApiStatus}
              invalidDescriptionErrorMessage={invalidDescriptionErrorMessage}
              onCancel={onCancelNote}
              candidateListStatus={candidateListStatus}
              candidateDetailsStatus={candidateDetailsStatus}
            >
              <div className={styles.candidateHeader}>
                <div className={styles.noteSubject}>
                  <NotesSubjectField />
                </div>
              </div>
              <div className={styles.noteEditor}>
                <NotesEditorField />
              </div>
              <div className={styles.noteSave}>
                <NotesCancelButton />
                <NotesSaveButton
                  buttonName="Add"
                  skEventName={getEventNameByCandidateStatus(
                    eventTypes.candidate.candidate360Profile.addCandidateNotes,
                    candidate
                  )}
                />
              </div>
            </NotesWrapper>
          </Form>
        </div>
        <div className={styles.noteView}>
          <NotesCard
            allNotes={_.sortBy(allNotes, note => -1 * new Date(note.CreatedDate))}
            getAllJobNotes={getAllCandidateNotes}
            onDelete={onDelete}
            onEdit={onEdit}
            noteFetchApiStatus={candidateNoteFetchApiStatus}
            noteDeleteApiStatuses={candidateNoteDeleteApiStatuses}
            onTagChange={onTagChange}
            onLoadMore={onLoadMore}
            onTagAdd={onTagAdd}
            onTagClose={onTagClose}
            suggestedTags={candidateSuggestedTags}
            totalSuggestedTagsCount={candidateSuggestedTagsCount}
            tagCloseApiStatus={tagCloseApiStatus}
            setNotification={setNotification}
          />
        </div>
      </div>
    </div>
  );
}
export default Form.create()(Candidate360Note);
export { Candidate360Note as Candidate360NoteWithForm };

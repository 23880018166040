import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';
import { Tabs, Icon, Skeleton } from 'antd';
import qs from 'query-string';
import InfiniteScroll from 'react-infinite-scroller';
import MessageWindowContainer from '../../Containers/MessageWindow/MessageWindow';
import { getCandidateStatus } from '../../Utils/SourceUtils';
import CallWindowContainer from '../../Containers/CallWindow/CallWindow';
import ChatWindowContainer from '../../Containers/ChatWindow/ChatWindow';
import MailWindowContainer from '../../Containers/MailWindow/MailWindow';
import styles from './CandidateProfileContactsTab.module.scss';
import message from '../../Containers/CandidateDrawer/messages';

const { TabPane } = Tabs;

function CandidateProfileContactsTab(props) {
  const {
    connectInfo,
    candidate,
    jobId,
    currentJobDetails,
    jobGuid,
    featureToggleList,
    jobCountryCode,
    postCallNotes,
    toggleBotConfigDrawer,
    candidateContext,
    activeSubTab,
    onSubTabClick,
    version,
    mailRef,
    allMailRef,
    smsRef,
    allSmsRef,
    callRef,
    isCandidateViewHeaderVisible,
    isComposeEmailModalVisible,
    setEmailComposeModalVisibility,
    isComposeMessageModalVisible,
    setMessageComposeModalVisibility,
    currentSelectedEmail,
    setCurrentSelectedEmail,
    phoneNumber,
    setPhoneNumber,
    isMailCommunicationAllowed,
    isMessageCommunicationAllowed,
    openSipCallWindowsApp,
    isContactTabRedirection,
    setCandidateViewHeaderVisibility,
    isConnectIconsEnabled,
    isAudioReadVisible,
    isMessageReadVisible,
    isEmailReadVisible,
    composeEmailType,
    setComposeEmailType,
    contactsTabRef,
    unsubscribeCandidateButton,
    isUnsubscribeCandidateEnabled,
    isComposeWorkflowModalVisible,
    setWorkflowComposeModalVisibility,
    workflowSelectedEmails,
    setWorkflowSelectedEmails,
    fetchEmailTemplates,
    fetchMails,
    allContextualMailCount,
    contextualMailCount,
    contextualMailsConversationApiStatus,
    allContextualMailsConversationApiStatus,
    setMessageTab,
    sms,
    allSms,
    fetchLatestMessages,
    candidate: { Id: userId },
    openCandidateView,
    openJobViewInNewTabCallBack,
  } = props;
  const connectStatuses = connectInfo.ConnectStatuses || {};
  const connectStatus = candidate ? _.get(connectStatuses, candidate.PersonId, {}) : {};
  const availableProviders = _.get(connectStatus, 'Contact.AvailableProviders', null);
  const candidateStatus = getCandidateStatus(_.get(candidate, ['Sources'], []));
  const mailCount = mailRef.current?.state.allEmails?.length || 0;
  const allMailCount = allMailRef.current?.state.allContextualEmails?.length || 0;
  const textCount = sms
    ? Object.values(sms).filter(phone => phone.Messages && Array.isArray(phone.Messages) && phone.Messages.length > 0)
        .length
    : 0;
  const allTextCount = allSms
    ? Object.values(allSms).filter(
        phone => phone.Messages && Array.isArray(phone.Messages) && phone.Messages.length > 0
      ).length
    : 0;
  const contextualTextCount = textCount;
  const allContextualTextCount = allTextCount;
  const phoneCount = callRef.current?.state?.callHistory?.Order?.length || 0;
  const isPhoneCommunicationAllowed = callRef.current?.state?.isCommunicationAllowed;
  const contextualPhoneCount = isPhoneCommunicationAllowed ? phoneCount : 0;
  const { redirectTo } = qs.parse(window.location.search);
  const isMessageTabRedirection = redirectTo?.trim().toLowerCase() === 'text';
  const [currentPage, setCurrentPage] = useState(0);
  const personId = candidate.PersonId || null;
  const conversationId = candidate.ConversationId || null;

  React.useEffect(() => {
    if (isMessageTabRedirection) {
      onSubTabClick('message');
    }
  }, []);

  const isWorkflowWindowEnabled = featureToggleList.CandidateLevelWorkflow?.IsEnabled || null;

  const contextualEmail = (
    <span>
      <FormattedMessage {...message.contextualEmailTab} /> ({contextualMailCount})
    </span>
  );

  const allContextualEmail = (
    <span>
      <FormattedMessage {...message.allContextualEmailTab} /> ({allContextualMailCount})
    </span>
  );

  const jobTextTab = (
    <span>
      <FormattedMessage {...message.jobMessageTab} /> ({contextualTextCount})
    </span>
  );

  const segmentTextTab = (
    <span>
      <FormattedMessage {...message.segmentsTab} /> ({contextualTextCount})
    </span>
  );

  const allSMSTab = (
    <span>
      <FormattedMessage {...message.allSMSTab} /> ({allContextualTextCount})
    </span>
  );

  const allSegmentsTab = (
    <span>
      <FormattedMessage {...message.allsegmentsTab} /> ({allContextualTextCount})
    </span>
  );

  let textTab;
  let allTextTab;

  if (candidateContext === 'job') {
    textTab = jobTextTab;
    allTextTab = allSMSTab;
  } else if (candidateContext === 'segment') {
    textTab = segmentTextTab;
    allTextTab = allSegmentsTab;
  }

  const {
    AllConversations: { IsEnabled: isAllConversationsEnabled },
  } = featureToggleList;

  React.useEffect(() => {
    if (personId && isConnectIconsEnabled && isMessageReadVisible && isAllConversationsEnabled)
      fetchLatestMessages(null, personId, userId, 'Success', 'SMS');
  }, []);

  React.useEffect(() => {
    if (isAllConversationsEnabled) fetchMails(null, personId, null, null, 0, 10);
  }, []);

  React.useEffect(() => {
    if (activeSubTab && (activeSubTab === 'workflow' || activeSubTab === 'email')) {
      const excludeTags = [];
      if (activeSubTab === 'workflow') excludeTags.push('Drip');
      else excludeTags.push('workflow');
      fetchEmailTemplates(0, 100, true, 'en-US', excludeTags);
    }
  }, [activeSubTab]);

  return (
    <div className={styles.contactTabsAndButton}>
      <div className={styles.contactTabs}>
        <Tabs
          tabPosition="left"
          className={styles.candidateProfileContactTabs}
          size="small"
          activeKey={activeSubTab}
          onChange={onSubTabClick}
        >
          {isConnectIconsEnabled && isEmailReadVisible ? (
            <TabPane tab={<FormattedMessage {...message.emailLabel} />} key="email">
              <div>
                <Tabs className={styles.emailTabs}>
                  <TabPane tab={contextualEmail} key="contextualEmails">
                    <div className={styles.infiniteScrollDiv}>
                      <InfiniteScroll
                        useWindow={false}
                        initialLoad={false}
                        loadMore={page => {
                          fetchMails(conversationId, null, null, null, page * 10, 10);
                          setCurrentPage(page);
                        }}
                        loader={
                          <div key="loader" className={styles.loader}>
                            <Icon className={styles.loadingIcon} type="loading" spin />
                          </div>
                        }
                        threshold={150}
                        hasMore={(currentPage + 1) * 10 < contextualMailCount}
                      >
                        <MailWindowContainer
                          availableProviders={availableProviders}
                          conversationId={conversationId}
                          personId={null}
                          candidate={candidate}
                          jobId={jobId}
                          connectInfo={connectStatus}
                          candidateStatus={candidateStatus}
                          featureToggleList={featureToggleList}
                          timeLineClassName="mail-window-display-drip-template"
                          currentJobDetails={currentJobDetails}
                          candidateContext={candidateContext}
                          version={version}
                          ref={mailRef}
                          isCandidateViewHeaderVisible={isCandidateViewHeaderVisible}
                          jobGuid={jobGuid}
                          onSubTabClick={onSubTabClick}
                          isComposeEmailModalVisible={isComposeEmailModalVisible}
                          setEmailComposeModalVisibility={setEmailComposeModalVisibility}
                          currentSelectedEmail={currentSelectedEmail}
                          setCurrentSelectedEmail={setCurrentSelectedEmail}
                          isContactTabRedirection={isContactTabRedirection}
                          composeEmailType={composeEmailType}
                          setComposeEmailType={setComposeEmailType}
                          contactsTabRef={contactsTabRef}
                          setWorkflowComposeModalVisibility={setWorkflowComposeModalVisibility}
                          emailApiStatus={contextualMailsConversationApiStatus}
                          openCandidateView={openCandidateView}
                          openJobViewInNewTabCallBack={openJobViewInNewTabCallBack}
                        />
                      </InfiniteScroll>
                    </div>
                  </TabPane>
                  {isAllConversationsEnabled && (
                    <TabPane tab={allContextualEmail} key="allContextualEmails">
                      <div className={styles.infiniteScrollDiv}>
                        <InfiniteScroll
                          useWindow={false}
                          initialLoad={false}
                          loadMore={page => {
                            fetchMails(null, personId, null, null, page * 10, 10);
                            setCurrentPage(page);
                          }}
                          loader={
                            <div key="loader" className={styles.loader}>
                              <Icon className={styles.loadingIcon} type="loading" spin />
                            </div>
                          }
                          threshold={150}
                          hasMore={(currentPage + 1) * 10 < allContextualMailCount}
                        >
                          <MailWindowContainer
                            availableProviders={availableProviders}
                            conversationId={null}
                            personId={personId}
                            candidate={candidate}
                            jobId={jobId}
                            connectInfo={connectStatus}
                            candidateStatus={candidateStatus}
                            featureToggleList={featureToggleList}
                            timeLineClassName="mail-window-display-drip-template"
                            currentJobDetails={currentJobDetails}
                            candidateContext={candidateContext}
                            version={version}
                            ref={allMailRef}
                            isCandidateViewHeaderVisible={isCandidateViewHeaderVisible}
                            jobGuid={jobGuid}
                            onSubTabClick={onSubTabClick}
                            isComposeEmailModalVisible={isComposeEmailModalVisible}
                            setEmailComposeModalVisibility={setEmailComposeModalVisibility}
                            currentSelectedEmail={currentSelectedEmail}
                            setCurrentSelectedEmail={setCurrentSelectedEmail}
                            isContactTabRedirection={isContactTabRedirection}
                            composeEmailType={composeEmailType}
                            setComposeEmailType={setComposeEmailType}
                            contactsTabRef={contactsTabRef}
                            setWorkflowComposeModalVisibility={setWorkflowComposeModalVisibility}
                            emailApiStatus={allContextualMailsConversationApiStatus}
                            openCandidateView={openCandidateView}
                            openJobViewInNewTabCallBack={openJobViewInNewTabCallBack}
                          />
                        </InfiniteScroll>
                      </div>
                    </TabPane>
                  )}
                </Tabs>
              </div>
            </TabPane>
          ) : null}
          {isConnectIconsEnabled && isMessageReadVisible ? (
            <TabPane tab={<FormattedMessage {...message.messageLabel} />} key="message">
              <Tabs className={styles.smsTabs} destroyInactiveTabPane>
                <TabPane tab={textTab} key="contextualText">
                  <MessageWindowContainer
                    conversationId={candidate.ConversationId}
                    personId={null}
                    availableProviders={availableProviders}
                    candidate={candidate}
                    jobId={jobId}
                    candidateStatus={candidateStatus}
                    currentJobDetails={currentJobDetails}
                    candidateContext={candidateContext}
                    version={version}
                    ref={smsRef}
                    isCandidateViewHeaderVisible={isCandidateViewHeaderVisible}
                    isComposeMessageModalVisible={isComposeMessageModalVisible}
                    setMessageComposeModalVisibility={setMessageComposeModalVisibility}
                    phoneNumber={phoneNumber}
                    setPhoneNumber={setPhoneNumber}
                    setMessageTab={setMessageTab}
                  />
                </TabPane>
                {isAllConversationsEnabled && (
                  <TabPane tab={allTextTab} key="all contextualText">
                    <MessageWindowContainer
                      conversationId={null}
                      personId={candidate.PersonId}
                      availableProviders={availableProviders}
                      candidate={candidate}
                      jobId={jobId}
                      candidateStatus={candidateStatus}
                      currentJobDetails={currentJobDetails}
                      candidateContext={candidateContext}
                      version={version}
                      ref={allSmsRef}
                      isCandidateViewHeaderVisible={isCandidateViewHeaderVisible}
                      isComposeMessageModalVisible={isComposeMessageModalVisible}
                      setMessageComposeModalVisibility={setMessageComposeModalVisibility}
                      phoneNumber={phoneNumber}
                      setPhoneNumber={setPhoneNumber}
                      setMessageTab={setMessageTab}
                      openCandidateView={openCandidateView}
                      openJobViewInNewTabCallBack={openJobViewInNewTabCallBack}
                    />
                  </TabPane>
                )}
              </Tabs>
            </TabPane>
          ) : null}
          {isConnectIconsEnabled && isAudioReadVisible ? (
            <TabPane tab={<FormattedMessage {...message.phoneLabel} />} key="call">
              <Tabs className={styles.phoneTabs}>
                <TabPane
                  tab={<FormattedMessage {...message.jobsCallsLabel} values={{ contextualPhoneCount }} />}
                  key="contextualPhone"
                  forceRender
                >
                  <CallWindowContainer
                    availableProviders={availableProviders}
                    conversationId={candidate.ConversationId}
                    jobId={jobId}
                    jobGuid={jobGuid}
                    candidate={candidate}
                    postCallNotes={postCallNotes}
                    candidateId={candidate?.Id}
                    candidateStatus={candidateStatus}
                    featureToggleList={featureToggleList}
                    jobCountryCode={jobCountryCode}
                    currentJobDetails={currentJobDetails}
                    ref={callRef}
                    openSipCallWindowsApp={openSipCallWindowsApp}
                    unsubscribeCandidateButton={unsubscribeCandidateButton}
                    isUnsubscribeCandidateEnabled={isUnsubscribeCandidateEnabled}
                  />
                </TabPane>
              </Tabs>
            </TabPane>
          ) : null}
          {candidateContext === 'job' && featureToggleList.BotRead.IsEnabled && isConnectIconsEnabled ? (
            <TabPane tab="LeoforceBot" key="bot">
              <ChatWindowContainer
                conversationId={candidate.ConversationId}
                personId={candidate.PersonId}
                showBotConfigDrawer={toggleBotConfigDrawer}
                featureToggleList={featureToggleList}
                candidate={candidate}
                emptyChatStyle={styles.emptyChat}
                setCandidateViewHeaderVisibility={setCandidateViewHeaderVisibility}
                isCandidate360View
              />
            </TabPane>
          ) : null}
          {isWorkflowWindowEnabled && (
            <TabPane tab="Workflow" key="workflow">
              <MailWindowContainer
                workflowWindowFlag
                availableProviders={availableProviders}
                candidate={candidate}
                jobId={jobId}
                featureToggleList={featureToggleList}
                currentJobDetails={currentJobDetails}
                candidateContext={candidateContext}
                version={version}
                jobGuid={jobGuid}
                currentSelectedEmail={currentSelectedEmail}
                setCurrentSelectedEmail={setCurrentSelectedEmail}
                isComposeWorkflowModalVisible={isComposeWorkflowModalVisible}
                setWorkflowComposeModalVisibility={setWorkflowComposeModalVisibility}
                workflowSelectedEmails={workflowSelectedEmails}
                setWorkflowSelectedEmails={setWorkflowSelectedEmails}
                openCandidateView={openCandidateView}
                openJobViewInNewTabCallBack={openJobViewInNewTabCallBack}
              />
            </TabPane>
          )}
        </Tabs>
      </div>
    </div>
  );
}

export default CandidateProfileContactsTab;

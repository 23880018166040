import { defineMessages } from 'react-intl';

const messages = defineMessages({
  editProfile: {
    id: 'SettingsMenu.UserSettings.editProfile',
    defaultMessage: 'Edit Profile',
  },
  templates: {
    id: 'SettingsMenu.UserSettings.templates',
    defaultMessage: 'Templates',
  },
  socialAccountIntegrations: {
    id: 'SettingsMenu.UserSettings.socialAccountIntegrations',
    defaultMessage: 'Social Account Integrations',
  },
  aryaConfigurations: {
    id: 'SettingsMenu.AdminSettings.aryaConfigurations',
    values: {
      AppName: 'Arya',
    },
    defaultMessage: 'Leoforce Configurations',
  },
  emailLimit: {
    id: 'SettingsMenu.AdminSettings.emailLimit',
    defaultMessage: 'Email Limit',
  },
  users: {
    id: 'SettingsMenu.AdminSettings.users',
    defaultMessage: 'Users',
  },
  clients: {
    id: 'SettingsMenu.AdminSettings.clients',
    defaultMessage: 'Clients',
  },
  reportingPortal: {
    id: 'SettingsMenu.AdminSettings.reportingPortal',
    defaultMessage: 'Reporting Portal',
  },
  logout: {
    id: 'Logout.logout',
    defaultMessage: 'Logout',
  },
  applicationSettings: {
    id: 'SettingsMenu.UserSettings.applicationSettings',
  },
  adminSettings: {
    id: 'SettingsMenu.UserSettings.adminSettings',
  },
  viewOrderHistory: {
    id: 'SettingsMenu.UserSettings.viewOrderHistory',
  },
  addClient: {
    id: 'SettingsMenu.UserSettings.addClient',
  },
  notificationSettings: {
    id: 'SettingsMenu.UserSettings.notificationSettings',
  },
});

export default messages;

import axios from 'axios';
import { apiV1Client, apiV3Client } from './BaseRepository';

function fetchManualSearchCriteria({ jobId, isDraft, isActivated }) {
  return apiV1Client.post('candidates/searchCriteria/_search', {
    JobId: jobId,
    IsDraft: isDraft,
    IsActivated: isActivated,
  });
}

function fetchManualSearchCriteriaIdentifiersWithCancellation() {
  let cancelRequest;
  const { CancelToken } = axios;
  return ({ jobId, searchTerm, from, size }) => {
    if (cancelRequest) {
      cancelRequest('Operation canceled due to new request.');
    }
    return apiV1Client
      .post(
        'candidates/searchCriteria/_autocomplete',
        { JobId: jobId, SearchTerm: searchTerm, From: from, Size: size },
        {
          cancelToken: new CancelToken(c => {
            cancelRequest = c;
          }),
        }
      )
      .catch(error => {
        if (axios.isCancel(error)) throw new Error('Request Cancelled');
        throw error;
      });
  };
}

function fetchManualSearchCriteriaById({ id }) {
  return apiV1Client.get(`candidates/searchCriteria/${id}`);
}

function fetchManualSearchCriteriaAgentById({ id }) {
  return apiV3Client.get(`ScoutingAgent/job/ScoutingAgent/SearchCriteria/Agent/${id}`);
}

function fetchBooleanSearchStrings({ jobId, size }) {
  return apiV3Client.get(`jobs/${jobId}/intel/searchStrings?size=${size}`);
}

function fetchManualSearchCriteriaByRecordId({ id }) {
  return apiV1Client.get(`candidates/searchCriteria/records/${id}`);
}

function fetchAryaGeneratedIntel({ jobId }) {
  return apiV3Client.get(`jobs/${jobId}/intel/candidates/searchSuggestions`);
}

function saveManualSearchCriteria({ criteria }) {
  return apiV1Client.post('candidates/searchCriteria', criteria);
}

function updateManualSearchCriteria({ id, criteria }) {
  return apiV1Client.post(`candidates/searchCriteria/${id}/_insert`, { ...criteria, Id: id });
}

function fetchSkillIntel({ skills, titles }) {
  return apiV1Client.post('skills/intel', { Skills: skills, Titles: titles });
}

function fetchManualSearchCandidatesWithCancellation() {
  let cancelRequest;
  const { CancelToken } = axios;
  return ({ jobId, criteria, from, size, abortIfDuplicated, cancelPrev }) => {
    if (cancelRequest && (abortIfDuplicated || cancelPrev)) {
      cancelRequest('Operation canceled due to new request.');
    }
    if (cancelPrev) return null;
    return apiV3Client
      .post(
        `jobs/${jobId}/candidates/_manualSearch`,
        { Criteria: criteria, From: from, Size: size },
        abortIfDuplicated
          ? {
              cancelToken: new CancelToken(c => {
                cancelRequest = c;
              }),
            }
          : {}
      )
      .catch(error => {
        if (axios.isCancel(error)) throw new Error('Request Cancelled');
        throw error;
      });
  };
}

function applyManualSearchCriteria({ activationDetails, criteria }) {
  const { Sources, IncludeRejectedCandidates, JobId } = activationDetails;
  return apiV3Client.post(`jobs/${JobId}/_sourcingstatus`, {
    Sources,
    IncludeRejectedCandidates,
    SearchCriteria: criteria,
  });
}

export const fetchManualSearchCandidates = fetchManualSearchCandidatesWithCancellation();
const fetchManualSearchCriteriaIdentifiers = fetchManualSearchCriteriaIdentifiersWithCancellation();

function saveSmartAgentCriteria(payload) {
  return apiV1Client.post(`candidates/searchCriteria/_smartagent`, payload);
}

function fetchBulkSmartAgentStats(payload) {
  const queryParams = payload.jobIds.join(',');
  const url = `jobs/_smartAgent/stats?JobIds=${queryParams}`;
  return apiV3Client.get(url);
}

function replenishSmartAgentCandidates(payload) {
  return apiV1Client.post(`job/${payload.jobId}/agent/${payload.agentId}/_replenish`, payload.sourcesPayload);
}
export function logManualSearchSourceCount({ jobId, payload }) {
  return apiV1Client.post(`candidates/searchCriteria/_manualsearchcandidatecount`, payload);
}

export {
  applyManualSearchCriteria,
  fetchAryaGeneratedIntel,
  fetchBooleanSearchStrings,
  fetchBulkSmartAgentStats,
  fetchManualSearchCriteria,
  fetchManualSearchCriteriaAgentById,
  fetchManualSearchCriteriaById,
  fetchManualSearchCriteriaByRecordId,
  fetchManualSearchCriteriaIdentifiers,
  fetchSkillIntel,
  saveManualSearchCriteria,
  saveSmartAgentCriteria,
  updateManualSearchCriteria,
  replenishSmartAgentCandidates,
};
